import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/src/styles/main.sass';
import ca from 'vuetify/src/locale/ca';
import es from 'vuetify/src/locale/es';
import en from 'vuetify/src/locale/en';

Vue.use(Vuetify, {
    iconfont: 'mdi',
    lang: {
        locales: { ca, es },
        current: 'es'
    }
});


export const theme1 = {
    name: "Adeit",
    dark: {
        primary: "#21CFF3",
        accent: "#FF4081",
        secondary: "#21dc79",
        primary_buttons: "#21CFF3",
        secondary_buttons: '#21dc79',
        success: "#86af3f",
        info: "#f34fc6",
        warning: "#FB8C00",
        error: "#FF5252"
    },
    light: {
        primary: '#1976D2',
        secondary: '#b6b3b3',
        primary_buttons: "#1976D2",
        secondary_buttons: '#cbc0a0',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
    }
};

export const theme2 = {
    name: "UV",
    dark: {
        primary: "#1c31b7",
        accent: "#294b05",
        secondary: "#689F38",
        primary_buttons: "#5c7591",
        secondary_buttons: '#cbc0a0',
        success: "#4CAF50",
        info: "#6156d8",
        warning: "#1565C0",
        error: "#FF7043"
    },
    light: {
        primary: "#002852",
        secondary: '#5f5858',
        primary_buttons: "#5c7591",
        secondary_buttons: '#cbc0a0',
        accent: '#82B1FF',
        error: '#e75d66',
        info: '#2196F3',
        success: '#23baa8',
        warning: '#ffae00',
    }
};

export const theme3 = {
    name: "SFPIE",
    dark: {
        primary: "#294b05",
        accent: "#376b01",
        secondary: "#689F38",
        primary_buttons: "#5c7591",
        secondary_buttons: '#cbc0a0',
        success: "#4CAF50",
        info: "#6156d8",
        warning: "#1565C0",
        error: "#FF7043"
    },
    light: {
        primary: "#294f02",
        secondary: '#5f5858',
        primary_buttons: "#439488",
        secondary_buttons: '#706547',
        accent: '#6ba49d',
        error: '#e75d66',
        info: '#2196F3',
        success: '#23baa8',
        warning: '#ffae00',
    }
};

export default new Vuetify({
    lang: {
        locales: { ca, es, en },
        current: 'es'
    },
    theme: {
        dark: false
    },
    icons: {
        iconfont: 'mdi'
    }
});
