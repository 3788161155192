
import AppNavigation from '@/components/AppNavigation.vue';
import { mapActions, mapGetters } from 'vuex';
import AppEventBus from './AppEventBus';
import ReconnectingEventSource from 'reconnecting-eventsource';
import { theme1, theme2, theme3 } from './plugins/vuetify';

// let sseClient;
let sseSource: {
    readyState: number;
    onmessage: (event: any) => Promise<void>;
    onerror: (err: any) => void;
    close: () => void;
};

export default {
    name: 'App',
    components: {
        AppNavigation,
    },
    async created() {
        AppEventBus.$on('login', async () => {
            await this.loadInitialData();

            this.connectSSE();
        });

        AppEventBus.$on('logout', () => {
            this.logout();
            this.disconnectSSE();
            this.$router.push('/login');
        });

        // @ts-ignore
        if (this.localStorageLoggedIn) {
            // @ts-ignore
            await this.setAppUser({
                appUser: {
                    app_user: JSON.parse(localStorage.getItem('app_user')!),
                },
            });

            await this.loadInitialData();
        }
    },
    async mounted() {
        this.connectSSE();
    },
    beforeDestroy() {
        this.disconnectSSE();
    },
    computed: {
        ...mapGetters({
            currentTenant: 'tenant/getCurrentTenant',
            tenants: 'tenant/getTenants',
        }),
    },
    methods: {
        ...mapActions('authentication', ['setAppUser']),
        ...mapActions('master', ['getEnumerates', 'setEnumerates']),
        ...mapActions('app', ['getConfig']),
        ...mapActions('config', ['get', 'set', 'getAcademicYearsPerms']),
        ...mapActions('tenant', ['actionGetTenants', 'actionGetCurrentTenant']),
        async handleConfigUpdated(event) {
            const data = JSON.parse(event.data);
            // new api calls since are appUser dependant
            await this.$store.dispatch('authentication/refresh');
            await this.$store.dispatch('config/getAcademicYearsPerms');

            await this.set(data.config);
            await this.setEnumerates(data.enumerates);
        },
        connectSSE() {
            if (sseSource?.readyState == 1) {
                // 1 -> Open
                return;
            }

            sseSource = new ReconnectingEventSource('/api/config/events', {
                max_retry_time: 1000 * 60,
            });

            const that = this;
            sseSource.onmessage = async function (event) {
                await that.handleConfigUpdated(event);
            };

            sseSource.onerror = (err) => {
                console.error('Eventsource failed: ', err);
            };
        },
        disconnectSSE() {
            sseSource.close();
        },
        async loadInitialData() {
            await Promise.all([
                this.getConfig(),
                this.getEnumerates(),
                this.get(),
                this.getAcademicYearsPerms(),
                this.actionGetTenants(),
                this.actionGetCurrentTenant(),
            ]);

            switch (this.currentTenant.id) {
                case 1:
                    await this.setThemForTenant(theme1);
                    break;
                case 2:
                    await this.setThemForTenant(theme2);
                    break;
                case 3:
                    await this.setThemForTenant(theme3);
                    break;
            }
        },
        async setThemForTenant(theme) {
            const name = theme.name;
            const dark = theme.dark;
            const light = theme.light;
            // set themes
            Object.keys(dark).forEach((i) => {
                this.$vuetify.theme.themes.dark[i] = dark[i];
            });
            Object.keys(light).forEach((i) => {
                this.$vuetify.theme.themes.light[i] = light[i];
            });
            // also save theme name to disable selection
            this.$vuetify.theme.themes.name = name;
        },
    },
};
