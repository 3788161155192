// DATA
export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';

// GENERIC CRUD
export const LOAD_DATA = 'LOAD_DATA';
export const LOAD_ACADEMIC_YEARS_DATA = 'LOAD_ACADEMIC_YEARS_DATA';
export const CLEAR_DATA = 'CLEAR_DATA';
export const CLEAR_ACADEMIC_YEARS_DATA = 'CLEAR_ACADEMIC_YEARS_DATA';
export const LOAD_ERROR = 'LOAD_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

// MASTER
export const LOAD_ENUMERATES_DATA = 'LOAD_ENUMERATES_DATA';
export const CREATE_TEACHER = 'CREATE_TEACHER';
export const UPDATE_TEACHER = 'UPDATE_TEACHER';
export const CLEAR_NEW_TEACHER = 'CLEAR_NEW_TEACHER';
export const CLEAR_NEW_FEE = 'CLEAR_NEW_FEE';

// APP
export const LOAD_CONFIG = 'LOAD_CONFIG';

export const PAGINATION_LOADED = 'PAGINATION_LOADED';

// TENANTS
export const LOAD_TENANTS_DATA = 'LOAD_TENANTS_DATA';
export const LOAD_CURRENT_TENANT = 'LOAD_CURRENT_TENANT';
export const CLEAR_CURRENT_TENANT = 'CLEAR_CURRENT_TENANT';
export const CLEAR_TENANTS = 'CLEAR_TENANTS';
export const LOAD_TENANT_ERROR = 'LOAD_TENANT_ERROR';
