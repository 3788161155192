/*
 *  Copyright 2017-2018 Enrique Araque Vilches
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */

import {MutationTree} from "vuex";
import {ITenant, TenantState} from "@/vuex/modules/tenant/types";
import * as mutation_types from '../../mutation_types';
import Vue from 'vue';

export const mutations: MutationTree<TenantState> = {
    [mutation_types.LOAD_TENANTS_DATA](state: TenantState , payload: ITenant[]) {
        state.error = undefined;
        Vue.set(state.data, 'tenants', payload);
    },
    [mutation_types.LOAD_CURRENT_TENANT](state : TenantState, payload: ITenant) {
        state.error = undefined;
        Vue.set(state.data, 'current_tenant', payload);
    },
    [mutation_types.CLEAR_CURRENT_TENANT](state : TenantState) {
        state.error = undefined;
        Vue.set(state.data, 'current_tenant', undefined);
    },
    [mutation_types.CLEAR_TENANTS](state : TenantState) {
        state.error = undefined;
        Vue.set(state.data, 'tenants', undefined);
    },
    [mutation_types.LOAD_TENANT_ERROR](state : TenantState, error) {
        state.error = error;
        state.data = {
            tenants: [],
            current_tenant: undefined
        };
    },
};
