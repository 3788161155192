<template>
    <v-currency-field
        :auto-decimal-mode="false"
        :value="value"
        ref="currency-field"
        v-bind="$attrs"
        v-on="$listeners"
        clearable
    />
</template>

<script>
import Vue from 'vue';
import componentWrapper from "@/components/mixins/componentWrapper";

export default Vue.extend({
    name: 'SimpleCurrencyField',
    mixins: [componentWrapper],
    mounted() {
        this.$refs["currency-field"].setValue(this.value);
    }
});
</script>
