var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.openTeachingGuide && !_vm.openTeachers)?_c('div',{staticClass:"fill-height fluid"},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('Detalle plan de estudios')))]),_c('v-spacer'),(
                                _vm.master.master_parent_id == null &&
                                _vm.master.master_kind.id == _vm.MASTER_KIND.DEFAULT
                            )?_c('v-btn',{staticClass:"mr-8",attrs:{"color":"primary_buttons","disabled":_vm.isCurrentMasterDisabled},on:{"click":function($event){return _vm.configureOptionalSubjects()}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-settings")]),_vm._v(" "+_vm._s(_vm.$t('Configurar Optativas'))+" ")],1):_vm._e(),(_vm.master.master_parent_id == null)?_c('v-btn',{attrs:{"color":"success","dark":"","disabled":_vm.isCurrentMasterDisabled},on:{"click":function($event){return _vm.newSubject()}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("add_circle")]),_vm._v(" "+_vm._s(_vm.$t('Añadir nueva asignatura'))+" ")],1):_c('v-btn',{attrs:{"color":"success","dark":"","disabled":_vm.isCurrentMasterDisabled},on:{"click":function($event){return _vm.newSubjectChild()}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("add_circle")]),_vm._v(" "+_vm._s(_vm.$t( 'Actualiza asignaturas del título vinculado' ))+" ")],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('Buscar'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.subjects == null || _vm.subjects.length == 0)?[_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('El título no tiene ninguna asignatura.'))+" ")])]:_c('v-data-table',{ref:"datatable",staticClass:"page__table",attrs:{"headers":_vm.checkedHeaders,"items":_vm.subjects,"search":_vm.search,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('draggable',{ref:"orderedList",attrs:{"tag":"tbody","list":props.items,"move":_vm.onMove},on:{"end":_vm.onOrderChanged}},_vm._l((props.items),function(item,index){return _c('tr',{key:index,style:(_vm.getBackgroundColorByAcademicYear(
                                            item.academic_year_offset
                                        ))},[(
                                            _vm.master.master_parent_id == null
                                        )?_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(
                                                    _vm.master.master_parent_id ==
                                                    null
                                                )?{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"primary_buttons","disabled":_vm.isCurrentMasterDisabled},on:{"click":function($event){return _vm.editSubject(item)}}},on),[_vm._v(" edit ")])]}}:null],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Modificar asignatura')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(
                                                    _vm.master.master_parent_id ==
                                                    null
                                                )?{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"red darken-2","disabled":_vm.isCurrentMasterDisabled},on:{"click":function($event){return _vm.deleteSubjectDialog(
                                                            item
                                                        )}}},on),[_vm._v(" delete ")])]}}:null],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Eliminar asignatura')))])])],1):_vm._e(),_c('td',[_vm._v(" "+_vm._s(item.order)+" "),(
                                                _vm.master.master_parent_id ==
                                                null
                                            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"page__grab-icon",attrs:{"small":""}},on),[_vm._v(" mdi-arrow-expand ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t( 'Pincha y arrastra la fila para ordenar' )))])]):_vm._e()],1),(
                                            _vm.master.academic_years_duration >
                                            1
                                        )?_c('td',[_vm._v(" "+_vm._s(item.academic_year_offset)+" ")]):_vm._e(),_c('td',[(item.is_optional)?_c('v-chip',{staticClass:"mr-4",attrs:{"x-small":"","color":"blue","text-color":"white"}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("mdi-star")]),_vm._v(" "+_vm._s(_vm.$t('Optativa'))+" ")],1):_vm._e(),(
                                                item.has_disabled_teachers
                                            )?_c('v-chip',{staticClass:"mr-4",attrs:{"x-small":"","color":"orange","text-color":"white"}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("mdi-star")]),_vm._v(" "+_vm._s(_vm.$t('Profesores inactivos'))+" ")],1):_vm._e(),_c('strong',[_vm._v(_vm._s(item.name))])],1),_c('td',[(item.is_tfm)?_c('v-icon',[_vm._v(" done ")]):_vm._e()],1),_c('td',[(item.is_practical)?_c('v-icon',[_vm._v(" done ")]):_vm._e()],1),_c('td',[_c('strong',[_vm._v(_vm._s(_vm.$t( `${item.teaching_type.name.toLocaleString()}` ))+" ")])]),_c('td',{style:(_vm.getStyleOptional(item))},[_c('strong',[_vm._v(" "+_vm._s(item.ects.toLocaleString())+" ")])]),_c('td',[_c('v-chip',{staticClass:"ma-2",attrs:{"outlined":"","color":item.in_range_hours
                                                    ? 'success'
                                                    : 'error',"text-color":item.in_range_hours
                                                    ? 'success'
                                                    : 'error'}},[_vm._v(" "+_vm._s(item.hours_assigned.toLocaleString())+" "),_c('v-avatar',{attrs:{"right":""}},[(
                                                        item.in_range_hours
                                                    )?_c('v-icon',[_vm._v(" check_circle ")]):_c('v-icon',[_vm._v(" close ")])],1)],1)],1),_c('td',[_c('v-btn',{attrs:{"color":item.teaching_guide_complete
                                                    ? 'green'
                                                    : 'red',"small":"","outlined":""},on:{"click":function($event){return _vm.editTeachingGuide(item)}}},[(_vm.isContinuousLearning)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Datos Asignatura'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('Guía docente'))+" ")])]),_c('v-badge',{attrs:{"bordered":"","color":"success","value":item.teachers.length != 0,"content":item.teachers.length,"overlap":""}},[_c('v-btn',{attrs:{"color":item.in_range_hours
                                                        ? 'green'
                                                        : 'red',"small":"","outlined":""},on:{"click":function($event){return _vm.editTeachers(item)}}},[_vm._v(" "+_vm._s(_vm.$t('Profesores'))+" ")])],1)],1)])}),0)]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('La búsqueda de '))+" \""+_vm._s(_vm.search)+"\" "+_vm._s(_vm.$t(' no produjo ningún resultado'))+". ")])]},proxy:true}],null,false,1929097876)},[_c('template',{slot:"body.append"},_vm._l((_vm.totals),function(value,key){return _c('tr',{key:key},[_c('th',{attrs:{"colspan":"5"}}),(Object.keys(_vm.totals).length > 1)?_c('th',[_vm._v(" "+_vm._s(_vm.$t('Total Año ') + key)+" ")]):_c('th',[_vm._v(_vm._s(_vm.$t('Total')))]),_c('th',[_vm._v(_vm._s(value.ects))]),_c('th',{attrs:{"colspan":_vm.headers.length}})])}),0)],2)],2)],1)],1)],1):_vm._e(),(_vm.openTeachingGuide)?_c('subject-teaching-guide',{attrs:{"edit-step":_vm.editStep,"subject-id":_vm.currentSubject.id},on:{"exit":function($event){_vm.currentSubject.id = 0;
            _vm.openTeachingGuide = 0;}}}):_vm._e(),(_vm.openTeachers)?_c('subject-teachers-list',{attrs:{"edit-step":_vm.editStep,"subject":_vm.currentSubject},on:{"exit":function($event){_vm.currentSubject.id = 0;
            _vm.openTeachers = 0;}}}):_vm._e(),_c('v-dialog',{attrs:{"persistent":true,"width":"800"},model:{value:(_vm.openSubjectUI),callback:function ($$v) {_vm.openSubjectUI=$$v},expression:"openSubjectUI"}},[_c('subject-edit',{attrs:{"edit-step":_vm.editStep,"master-has-tfm":_vm.masterHasTfm,"subject":_vm.currentSubject,"is-new":_vm.isNewSubject},on:{"input":function($event){_vm.currentSubject = {}},"created":_vm.successCreated},model:{value:(_vm.openSubjectUI),callback:function ($$v) {_vm.openSubjectUI=$$v},expression:"openSubjectUI"}})],1),_c('v-dialog',{attrs:{"persistent":true,"width":"800"},model:{value:(_vm.openConfigureOptionalSubjectUI),callback:function ($$v) {_vm.openConfigureOptionalSubjectUI=$$v},expression:"openConfigureOptionalSubjectUI"}},[_c('configure-optional-subject',{on:{"created":function($event){_vm.openConfigureOptionalSubjectUI = false},"exit":function($event){_vm.openConfigureOptionalSubjectUI = false;
                _vm.editMaster = null;}},model:{value:(_vm.openConfigureOptionalSubjectUI),callback:function ($$v) {_vm.openConfigureOptionalSubjectUI=$$v},expression:"openConfigureOptionalSubjectUI"}})],1),_c('delete-dialog',{attrs:{"text":_vm.$t(
                'Se eliminará la asignatura completa, con toda la información de profesores y plan de estudios.'
            )},on:{"delete":_vm.deleteCurrentSubject,"cancel":_vm.cancelDeleteSubject},model:{value:(_vm.deleteSubjectUI),callback:function ($$v) {_vm.deleteSubjectUI=$$v},expression:"deleteSubjectUI"}}),_c('v-dialog',{attrs:{"persistent":true,"width":"800"},model:{value:(_vm.openSubjectChildUI),callback:function ($$v) {_vm.openSubjectChildUI=$$v},expression:"openSubjectChildUI"}},[_c('subject-child-list',{attrs:{"show":_vm.openSubjectChildUI,"edit-step":_vm.editStep,"current-subjects":_vm.master.subjects},on:{"cancel":function($event){_vm.openSubjectChildUI = false},"exit":function($event){_vm.openSubjectChildUI = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }