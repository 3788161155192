import { render, staticRenderFns } from "./SubjectTeachingGuide.vue?vue&type=template&id=1a63d157&scoped=true&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform&"
import script from "./SubjectTeachingGuide.vue?vue&type=script&lang=ts&"
export * from "./SubjectTeachingGuide.vue?vue&type=script&lang=ts&"
import style0 from "./SubjectTeachingGuide.vue?vue&type=style&index=0&id=1a63d157&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a63d157",
  null
  
)

export default component.exports