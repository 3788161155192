
import Vue from 'vue';
// @ts-ignore
import * as api from '@/api/index';

const INSERT = 'insert';
const UPDATE = 'update';

export default Vue.extend({
    name: 'SyncLog',
    data() {
        return {
            operation: {
              INSERT: 'insert',
              UPDATE: 'update'
            },
            search: '',
            headers: [],
            masters: [],
            loading: false,
            errorMessage: null,
            records: [],
            totalRecords: 0,
            options: {},
            syncSummary: null,
            newRecords: 0,
            updatedRecords: 0,
            currentEntity: '',
            currentOperation: INSERT,
            fieldBlackList: ['id'], // do not take care of this fields when comparing records,
            overlay: false,
            showPersistDialog: false,
            persistSuccess: false
        };
    },
    computed: {
      showError() {
        return this.errorMessage != null;
      }
    },
    async mounted() {
      await this.refresh();
    },
    methods: {
      togglePersistDialog() {
        this.showPersistDialog = !this.showPersistDialog;
      },
        async refresh() {
          await this.getSummary();
          if (this.syncSummary != null && this.syncSummary.synced == false) {
            this.loadFirstTab();
          }
        },
        loadFirstTab() {
          const firstTab = this.$refs['tab_0'];
          if (firstTab != null && firstTab.length > 0) {
            firstTab[0].$el.click();
          }
        },
        setCurrentEntity(entity: string, value: { insert: number; update: number }) {
          Vue.set(this, "currentEntity", entity);
          Vue.set(this, "newRecords", value.insert);
          Vue.set(this, "updatedRecords", value.update);

          this.$refs.btn_new.$el.click();
        },
        async getData(table: string, operation: string) {
            try {
                if (!table || !operation) {
                  return
                }

                this.loading = true;

                this.currentOperation = operation;

                const options = {
                  search: this.search,
                  limit: this.options.itemsPerPage,
                  page: Number(this.options.page) - 1
                }

                const response = await api.sync.getTableSyncLog(table, operation, options);

                this.totalRecords = response.data.total;
                this.records = response.data.data;

                if (this.records.length > 0) {
                  const xtraFields = Object.keys(this.records[0].new_record);
                  this.headers = []

                  for(let field of xtraFields) {
                    if (!this.fieldBlackList.includes(field)) {
                      this.headers.push({
                        text: this.$i18n.t(`${field}`),
                        align: 'center',
                        sortable: true,
                        value: `${field}`
                      });
                    }
                  }
                }
                this.loading = false;
            } catch (err) {
                this.loading = false;
                this.errorMessage = err.message;
                throw err;
            }
        },
        async getSummary() {
          try {
            this.loading = true;

            const res = await api.sync.getSyncSummary();

            if (res.data.length > 0) {
              Vue.set(this, "syncSummary", res.data[0]);
            } else{
              Vue.set(this, "syncSummary", null);
            }

            console.log(`sync_log_summary: ${this.syncSummary}`)
            this.loading = false;
          } catch (err) {
            this.syncSummary = null;

            this.loading = false;
            this.errorMessage = err.message;
            throw err;
          }
        },
      async copyFromSigue() {
        try {
          this.overlay = true

          await api.sync.copyFromSigue();

          await this.refresh();

          this.overlay = false;
        } catch (err) {
          this.overlay = false;
          this.errorMessage = err.message;
          throw err;
        }
      },
      async persistsChangesToProduction() {
        try {
          this.overlay = true

          await api.sync.persistsChangesToProduction();

          await this.refresh();

          this.persistSuccess = true;

          setTimeout(() => {
            this.persistSuccess = false;
          }, 3000);

          this.overlay = false;
        } catch (err) {
          this.persistSuccess = false;
          this.overlay = false;
          this.errorMessage = err.message;
          throw err;
        }
      }
    },
    watch: {
      options: {
        handler () {
          this.getData(this.currentEntity, this.currentOperation);
        },
        deep: true,
      },
      search: {
        handler () {
          this.getData(this.currentEntity, this.currentOperation);
        },
        deep: true,
      }
    },
});
