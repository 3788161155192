
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
import {form} from '@/components/mixins';
import * as helpers from '@/helpers/index';
import {validateDNI} from "../../../helpers";

export default Vue.extend({
    name: 'TeacherEditUv',
    mixins: [form],
    props: {
        isNew: {
            type: Boolean,
            default: false
        },
        teacher: {
            type: Object,
            default: () => null
        },
        reload: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            errors: {
                name: [],
                surname: [],
                nif: [],
                phone_number: [],
                email: [],
                birth_date: [],
                department: [],
                professional_category: [],
                teacher_knowledge_area: []
            },
            rules: {
                name: [this.ruleRequired],
                surname: [this.ruleRequired],
                birth_date: [], //,[this.ruleRequired],
                nif: [this.ruleRequired,
                  (value: string) => {
                    return validateDNI(value) || 'El nif no es válido';
                  }
                ],
                phone_number: [this.ruleRequired],
                email: [
                    this.ruleRequired,
                    (value: string) => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || 'El e-mail no es válido';
                    }
                ],
                department: [this.ruleRequired],
                professional_category: [], // [this.ruleRequired],
                teacher_knowledge_area: []
            },
            localState: {
                id: null,
                name: null,
                surname: null,
                birth_date: null,
                nif: null,
                teacher_type_code: 'uv',
                phone_number: null,
                email: null,
                department: null,
                professional_category: null,
                teacher_knowledge_area: null
            },
            menuDate: false
        };
    },
    computed: {
        ...mapGetters({
            formError: 'master/getError',
            departments: 'master/getDepartments',
            professional_categories: 'master/getProfessionalCategories',
            teacher_knowledge_areas: 'master/getTeacherKnowledgeAreas',
            master: 'master/getData',
            appConfig: 'app/getConfig'
        }),
        professional_categories_pdis() {
            if (!this.professional_categories) return [];

            return this.professional_categories
                .filter(pf => pf.relation_uni === 'P.D.I.')
                .sort((a, b) => a.description.localeCompare(b.description));
        },
        teacher_knowledge_areas_filtered_by_department() {
            if (!this.teacher_knowledge_areas || !this.localState.department)
                return [];

            return this.teacher_knowledge_areas
                .filter(
                    tka => tka.id_department === this.localState.department.id
                )
                .sort((a, b) => a.description.localeCompare(b.description));
        },
        computedDateFormatted() {
            return helpers.formatDate(this.localState.birth_date);
        },
        globalState() {
            if (this.teacher != null && this.teacher.id > 0) {
                return this.teacher;
            }

            return null;
        }
    },
    methods: {
        ...mapActions('master', {
            create: 'createTeacher',
            update: 'updateTeacher',
            clearError: 'clearError'
        }),
        clear() {
            Vue.set(this.localState, 'name', null);
            Vue.set(this.localState, 'department', null);
            Vue.set(this.localState, 'teacher_knowledge_area', null);
            Vue.set(this.localState, 'professional_category', null);
        },
        parseLocalStateAfter(): void {
            this.localState.teacher_type_code = 'uv';
        },
        saveAndExit() {
            if (this.$refs.form.validate()) {
                this.createOrUpdate();
                this.exit();
            }
        }
    },
    watch: {
        teacher(newValue, oldValue) {
            if (!this.isNew) {
                this.parseGlobalState();
            }
        }
    }
});
