import {Module} from 'vuex';
import {RootState} from '../../types';
import {actions} from './actions';
import {getters} from './getters';
import {mutations} from './mutations';
import {ConfigState} from './types';
import {IConfig} from "@/interfaces/config/interfaces/models/IConfig";
import {IAcademicYearsPerms} from "@/interfaces/config/interfaces/models/IAcademicYearsPerms";

export const state: ConfigState = {
    data: {} as IConfig,
    academicYearsPerms: [] as IAcademicYearsPerms,
    error: undefined
};

const namespaced: boolean = true;

export const config: Module<ConfigState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
};
