import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import MasterView from '@/views/Master.vue';
import MasterListView from '@/views/MasterList.vue';
import Management from '@/components/master/Master.vue';
import BasicData from '../components/master/BasicData.vue';
import BackOfficeView from '../views/BackOffice.vue';
import ZipFiles from '../components/back-office/ZipFiles.vue';
import MasterImport from '../components/master/MasterImport.vue';
import ResetPassword from '../components/ResetPassword.vue';
import ForgottenPassword from '../components/ForgottenPassword.vue';
import store from '@/vuex/store';
import {ITenant} from "@/vuex/modules/tenant/types";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/reset-password/:newPasswordToken',
            name: 'ResetPassword',
            component: ResetPassword,
        },
        {
            path: '/forgotten-password',
            name: 'ForgottenPassword',
            component: ForgottenPassword,
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            props: true,
        },
        {
            path: '/master/:masterId',
            name: 'Master',
            component: MasterView,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/master-list',
            name: 'MasterList',
            component: MasterListView,
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: 'management',
                    name: 'management',
                    component: Management,
                },
                {
                    path: 'basic_data',
                    name: 'basic_data',
                    component: BasicData,
                },
            ],
        },
        {
            path: '/master-import/:masterKind',
            name: 'MasterImport',
            component: MasterImport,
            props: true,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/back-office',
            name: 'BackOffice',
            component: BackOfficeView,
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: '',
                    component: ZipFiles,
                },
                {
                    path: 'zip-files',
                    name: 'zipFiles',
                    component: ZipFiles,
                },
            ],
        },
        {
            path: '/about',
            name: 'about',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
                import(/* webpackChunkName: "about" */ '../views/About.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '*',
            redirect: '/login',
        },
    ],
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const authRequired = to.matched.some((record) => record.meta.requiresAuth);
    const loggedIn = localStorage.getItem('app_user');
    const currentTenant: ITenant = store.getters['tenant/getCurrentTenant'];

    if ( to.name !== 'Login' &&
        ((authRequired && !loggedIn) || currentTenant == null)) {
        console.log(` ***** currentTenant ${store.state}`)
        console.log(` ***** currentTenant ${JSON.stringify(store.state.tenant)}`)
        console.log(` ***** currentTenant ${store.state.tenant.data.current_tenant}`)
        return next('/login');
    }

    next();
});

export default router;
