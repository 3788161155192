/*
 *  Copyright 2017-2018 Enrique Araque Vilches
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
import {GetterTree} from 'vuex';
import {IConfig} from '../../../interfaces/config/interfaces';
import {RootState} from '../../types';
import {ConfigState} from './types';
import {IAcademicYearsPerms} from "@/interfaces/config/interfaces/models/IAcademicYearsPerms";

export const getters: GetterTree<ConfigState, RootState> = {
    get: (state: ConfigState): IConfig => state.data,
    getAcademicYearsPerms: (state: ConfigState): IAcademicYearsPerms[] => state.academicYearsPerms,
    getError: (state: ConfigState): string => state.error
};
