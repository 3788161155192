
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
// @ts-ignore
import {authentication} from '@/services';

export default Vue.extend({
    computed: {
        ...mapGetters({
            loginError: 'authentication/getLoginError'
        }),
        passwordsMatch() {
          return this.password === this.re_password;
        }
    },
    data: function() {
        return {
            showLoginError: false,
            valid: false,
            e1: true,
            password: '',
            re_password: '',
            passwordRules: [
              (v: string) => !!v || this.$i18n.t('Contraseña requerida'),
              (v: string) => (v && v.length >= 6) || this.$i18n.t('Debe tener al menos 6 caracteres'),
              (v: string) => /(?=.*[A-Z])/.test(v) || 'Debe contener una mayúscula',
              (v: string) => /(?=.*\d)/.test(v) || 'Debe contener un número',
              (v: string) => /([!@$%*])/.test(v) || 'Debe contener un carácter especial [!@#$%]'
            ],
            re_passwordRules: [
              (v: any) => !!v || this.$i18n.t('Contraseña requerida'),
              (v: string) => {
                if (!this.passwordsMatch) {
                  return this.$i18n.t('Las contraseñas no coinciden');
                }
                return true;
              }
            ],
            passwordSettedOk: false
        };
    },
    mounted() {
        this.email = '';
        this.password = '';
        this.re_password = '';

        // Always clear old app_user
        // authentication.logout();
    },
    methods: {
        ...mapActions('authentication', ['resetPassword']),
        submit() {
          const newPasswordToken = this.$route.params.newPasswordToken;

          if (newPasswordToken == null) {
              this.showLoginError = true;
          }

          // @ts-ignore
          if (this.$refs.form.validate()) {
              // @ts-ignore
              this.resetPassword({
                  password: this.password,
                  newPasswordToken: this.$route.params.newPasswordToken
              })
                .then((loginResponse: any) => {
                   this.$router.push({name: "Login", params: {fromResetPassword: true}})
                  })
                .catch(e => {
                    this.showLoginError = true;
                    Vue.set(this, 'passwordSettedOk', false);
                });
          }
        },
        clear() {
            // @ts-ignore
            this.$refs.form.reset();
        },
    }
});
