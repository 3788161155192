
import 'devextreme/dist/css/dx.light.css';
import {
  DxColumn,
  DxColumnChooser,
  DxColumnFixing,
  DxDataGrid,
  DxExport,
  DxFilterRow,
  DxHeaderFilter,
  DxItem,
  DxPager,
  DxPaging,
  DxScrolling,
  DxSearchPanel,
  DxSelection,
  DxSorting,
  DxStateStoring,
  DxToolbar,
} from 'devextreme-vue/data-grid';
import DxButton from 'devextreme-vue/button';
import {createStore} from 'devextreme-aspnet-data-nojquery';
import Vue from 'vue';
import * as api from '@/api/index';
import Query from 'devextreme/data/query';

const mastersData = createStore({
    key: 'id',
    loadUrl: `${process.env.VUE_APP_API_URL}admin/masters`,
});

export default Vue.extend({
    name: 'Masters',
    components: {
        DxDataGrid,
        DxExport,
        DxColumn,
        DxColumnChooser,
        DxColumnFixing,
        DxSorting,
        DxFilterRow,
        DxHeaderFilter,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxPaging,
        DxButton,
        DxStateStoring,
        DxSelection,
        DxToolbar,
        DxItem,
    },
    data() {
        return {
            mastersData,
            pageSizes: [10, 25, 50, 100],
            pageSize: 100,
            pageIndex: 0,
            filterStatusValues: ['Activo'],
            generatePdfAllDialog: false,
            selectedRowsData: [],
        };
    },
    computed: {
        selectedRowKeys() {
            return this.getFilteredKeys();
        },
        grid(): DxDataGrid {
            return this.$refs['grid-masters']?.instance;
        },
    },
    methods: {
        async onSelectionChanged({ selectedRowsData }) {
            this.selectedRowsData = selectedRowsData;
        },
        toggleGeneratePdfAllDialog() {
            this.generatePdfAllDialog = !this.generatePdfAllDialog;
        },
        getMinStudents(rowData) {
            if (rowData.fee == null) {
                return '';
            }
            return rowData.fee?.map((f) => f.min_students).join(', ');
        },
        getTeacherFullName(rowData) {
            if (rowData.teacher == null) {
                return '';
            }
            return `${rowData?.teacher.name || ''} ${
                rowData?.teacher.surname || ''
            }`;
        },
        getKnowledgeFieldName(rowData) {
            if (rowData.teacher == null) {
                return '';
            }

            return rowData?.master_knowledge_field[0].knowledge_field.name;
        },
        translate(cellInfo) {
            if (cellInfo.value == null) {
                return '';
            }
            return this.$i18n.t(cellInfo.value);
        },
        refreshData() {
            mastersData.load();
        },
        gotoMaster(event: { data: { id: any } }) {
            const routeData = this.$router.resolve({
                name: 'Master',
                params: { masterId: `${event.data.id}` },
            });
            window.open(routeData.href, '_blank');
        },
        getFilteredKeys(){

            const grid = this.grid;
            const filterExpr = grid.getCombinedFilter(true);

            const rows = Query(this.selectedRowsData)
                .filter(filterExpr)
                .select('id')
                .toArray()

            return rows;
        },
        async generatePdfAll() {
            try {
                // @ts-ignore
                if (this.selectedRowKeys.length == 0) {
                }

                // @ts-ignore
                await api.master.generateAll(
                    this.selectedRowKeys
                );
            } catch (e) {
                throw e;
            }
        },
    },
});
