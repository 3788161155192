
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
import {ITeachingType} from '../../../interfaces/master/interfaces/models/ITeachingType';
import {ISubject} from '../../../interfaces/subject/interfaces/models/ISubject';
import {form} from '@/components/mixins';
import * as helpers from '@/helpers/index';
import {MASTER_KIND} from "../../../helpers";

export default Vue.extend({
    name: 'SubjectEdit',
    mixins: [form],
    props: {
        isNew: {
            type: Boolean,
            default: false
        },
        subject: {
            type: Object,
            default: () => {}
        },
        masterHasTfm: {
            type: Boolean,
            default: false
        },
        editStep: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            MASTER_KIND: MASTER_KIND,
            errors: {
                name: [],
                teaching_type: [],
                ects: [],
                is_tfm: [],
                is_practical: [],
                is_optional: []
            },
            rules: {
                name: [
                    (v: string) => !!v || this.$i18n.t('El nombre es requerido')
                ],
                teaching_type: [
                    (v: ITeachingType) =>
                        !!v || this.$i18n.t('El tipo de docencia es requerido')
                ],
                order: [
                (v: string) =>
                    !!v || this.$i18n.t('Requerido')
                ],
                ects: [
                    (v: string) =>
                        !!v || this.$i18n.t('El número de ECTS es requerido'),
                    (v: string) => {
                        const number = parseFloat(
                            v.toString().replace(',', '.')
                        );

                        if (isNaN(number)) {
                            return false;
                        }

                        if (
                            this.localState.is_tfm == null ||
                            !this.localState.is_tfm
                        ) {
                            if (this.isContinuousLearning) {
                              return (true);
                            }
                            return (
                                (number >= this.constants.SUBJECT_MIN_ECTS ||
                                    (this.master.master_type != null
                                        && number > this.master.master_type.min_ects )) ||
                                this.$i18n.t('El número mímino de ECTS es ') +
                                    this.constants.SUBJECT_MIN_ECTS
                            );
                        } else {
                            return (
                                number >= this.constants.SUBJECT_TFM_MIN_ECTS ||
                                this.$i18n.t(
                                    'El número mímino de ECTS para el TFM es '
                                ) + this.constants.SUBJECT_TFM_MIN_ECTS
                            );
                        }
                    },
                    (v: string) => {
                        if (!helpers.isHalfDecimals(v)) {
                            return this.$i18n.t('Sólo se admite medio ECTS');
                        }
                        return true;
                    }
                ],
                is_tfm: [
                    (v: boolean) => !!v || this.$i18n.t('El campo es requerido')
                ],
                is_practical: [
                    (v: boolean) => !!v || this.$i18n.t('El campo es requerido')
                ],
                is_optional: [],
            },
            localState: {
                name: null,
                teaching_type: null,
                ects: null,
                is_tfm: null,
                is_practical: null,
                is_optional: null,
                academic_year_offset: 1
            }
        };
    },
    computed: {
        ...mapGetters({
            formError: 'master/getError',
            teachingTypes: 'master/getTeachingTypes',
            master: 'master/getData',
            appConfig: 'app/getConfig',
            constants: 'master/getConstants'
        }),
        isContinuousLearning() {
         return this.currentMaster.master_kind_id === MASTER_KIND.CONTINUOUS_LEARNING
        },
        optionalSubjectsConfigured() {
          return this.master.optional_subjects_ects >0 && this.master.min_optional_subjects > 0;
        },
        master_academic_years() {
            return Array.from(
                Array(this.master.academic_years_duration).keys()
            ).map(a => a + 1);
        },
        globalState() {
            if (this.subject.id > 0) {
                return this.$store.getters['master/getSubject'](
                    this.subject.id
                );
            }

            return null;
        }
    },
    methods: {
        ...mapActions('master', [
            'createSubject',
            'updateSubject',
            'clearError'
        ]),
        setOptionalSubject() {
          Vue.set(this.localState, "ects", this.master.optional_subjects_ects);
        },
        forceNumber(evt: any) {
            if (!helpers.isKeyNumber(evt)) {
                evt.preventDefault();
            }
        },
        clearLocalState() {
            this.localState = {
                name: null,
                teaching_type: null,
                ects: null,
                is_tfm: null,
                is_practical: null,
                is_optional: null,
                academic_year_offset: null,
                order: this.master.subjects.length + 1,
            };

            this.clearError();

            // @ts-ignore
            this.$refs.form.resetValidation();
        },
        parseGlobalState() {
            if (this.globalState == null) {
                this.clearLocalState();
            } else {
                // @ts-ignore
                _.merge(this.localState, _.cloneDeep(this.globalState));
            }
        },
        parseLocalState(): ISubject {
            this.localState.teaching_type_code = this.localState.teaching_type.code;

            return this.localState;
        },
        exit() {
            this.clearLocalState();
            this.$emit('input', false);
        },
        async save() {
            if (this.isNew) {
                this.create();
            } else {
                this.update();
            }
        },
        async update() {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                this.parseLocalState();

                // @ts-ignore
                await this.updateSubject({
                    subject: this.localState,
                    master: this.master
                });
                this.clearModified();
                this.$emit('updated', false);
            }
        },
        async create() {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                await this.createSubject({
                    subject: this.localState,
                    master: this.master
                });
                this.$emit('created', this.localState);
                this.clearLocalState();
            }
        }
    }
});
