
import Vue from 'vue';

export default Vue.extend({
    name: 'DeleteDialog',
    props: {
        text: {
            type: String,
            default: null
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            defaultText: this.$i18n.t(
                'El item será borrado permanentemente. Quieres continuar?'
            )
        };
    }
});
