
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
import {form} from '@/components/mixins';
import * as helpers from '@/helpers/index';
import NumericField from "../../common/NumericField.vue";

export default Vue.extend({
    name: 'ConfigureOptionalSubject',
    mixins: [form],
    components: {
      NumericField
    },
    data() {
        return {
            errors: {
                min_optional_subjects: [],
                optional_subjects_ects: []
            },
            rules: {
                min_optional_subjects: [
                    (v: string) => !!v || this.$i18n.t('El nombre es requerido')
                ],
                optional_subjects_ects: [
                  (v: string) =>
                      !!v || this.$i18n.t('El número de ECTS es requerido'),
                  (v: string) => {
                    if (v == null) {
                      return true;
                    }

                    const number = parseFloat(
                        v.toString().replace(',', '.')
                    );

                    if (isNaN(number)) {
                      return false;
                    }

                    return (
                        number >= this.constants.SUBJECT_MIN_ECTS ||
                        this.$i18n.t('El número mímino de ECTS es ') +
                        this.constants.SUBJECT_MIN_ECTS
                    );
                  },
                  (v: string) => {
                    if (!helpers.isHalfDecimals(v)) {
                      return this.$i18n.t('Sólo se admite medio ECTS');
                    }
                    return true;
                  }
                ],
            },
            localState: {
                id: null,
                min_optional_subjects: null,
                optional_subjects_ects: null
            }
        };
    },
    computed: {
        ...mapGetters({
            formError: 'master/getError',
            globalState: 'master/getData',
            appConfig: 'app/getConfig',
            constants: 'master/getConstants'
        }),
        getOptionalMessage() {
          return this.$i18n.t('Para poder asociar al título asignaturas optativas, se requiere autorización. Ponte en contacto en ') + `${process.env.VUE_APP_SUPPORT_EMAIL}`;
        }
    },
    methods: {
      ...mapActions('master', ['update', 'clearError']),
        forceNumber(evt: any) {
            if (!helpers.isKeyNumber(evt)) {
                evt.preventDefault();
            }
        },
        clearLocalState() {
            this.localState = {
              id: null,
              min_optional_subjects: null,
              optional_subjects_ects: null
            };

            this.clearError();

            // @ts-ignore
            this.$refs.form.resetValidation();
        },
        exit() {
          this.$emit('exit');
        },
        saveAndExit() {
          if (this.$refs.form.validate()) {
            this.createOrUpdate();
            this.exit();
          }
        }
    },
});
