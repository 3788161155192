/*
 *  Copyright 2017-2018 Enrique Araque Vilches
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 *   Unless required by applicable law or agreed to in writing, software
 *   distributed under the License is distributed on an "AS IS" BASIS,
 *   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *   See the License for the specific language governing permissions and
 *   limitations under the License.
 */
export default {
    error: {
        msg_error: "S'ha produït un error",
        msg_description: 'Contacta amb el suport o obri una incidència'
    },
    'Menú Inicio': 'Home Menu',
    'Iniciar sesión': 'Log in',
    'Cerrar sesión': 'Log out',
    'Manual de usuario': 'User manual',
    'Títulos Propios UV': 'UV-specific degrees',
    Menu: 'Menu',
    Borrar: 'Delete',
    Confirmar: 'Confirm',
    "Estàs segur/a d'eliminar la referència al títol vinculat(pare)?": 'Are you sure to remove the reference to the linked degree(parent)?',
    'Estás seguro/a?': 'Are you sure?',
    Cancel: 'Cancel',
    Aceptar: 'OK',
    'El item será borrado permanentemente. Quieres continuar':
        'The item will be deleted permanently. Do you want to continue?',
    'Nueva tasa': 'New fee',
    'Modificar tasa': 'Change fee',
    'Ha ocurrido un error en el servidor': 'A server error occurred',
    'Grupo o público al que va dirigida la tasa':
        'Group that will be charged the fee',
    'Importe de la matrícula': 'Enrolment fee',
    'Mínimo de estudiantes': 'Minimum number of students',
    Guardar: 'Save',
    'No puede ser mayor que el número máximo de estudiantes del título':
        'The number cannot exceed the maximum number of students in the degree',
    'Tasas del título': 'Fees of the degree',
    'Guarda los cambios para poder modificar las tasas del título':
        'Save changes to modify the fees of the degree',
    'Eliminar tasa': 'Remove fee',
    'Se eliminará la tasa completa, lo que afectará al presupuesto.':
        'The full fee will be removed, which will affect the budget',
    Público: 'Public',
    'Mín. Estudiantes': 'Min. number of students',
    'Matrícula ': 'Enrolment ',
    'Total ': 'Total',
    'Elimina el fichero': 'Delete the file',
    'Descarga el fichero': 'Download the file',
    'Haz clik para adjuntar un nuevo fichero': 'Click to attach a new file',
    Salir: 'Quit',
    'Cancelar cambios': 'Cancel changes',
    'Escribe para buscar una organización': 'Type in to find an organisation',
    'El dato es requerido': 'The data is required',
    'Se ha producido un error consultando el servidor':
        'An error occurred while querying the server',
    nuevo: 'new',
    'Escribe para buscar por nombre, apellidos o NIF':
        'Type in to search by name, surnames or NIF',
    'Eliminar ': 'Delete',
    'Crea títulos propios': 'Create UV-specific degrees',
    'Crear Título': 'Create degree',
    'Crear Título Vinculado': 'Create associated degree',
    'Gestiona Títulos Propios': 'Manage UV-specific degrees',
    'Revisar títulos propios presentados':
        'Review / Modify UV-specific degrees submitted',
    'Inicia sesión': 'Log in',
    'Error al iniciar sesión': 'Error logging in',
    'Nombre de usuario UV o Correo electrónico UV':
        'UV username or UV e-mail address',
    'Puedes introducir tu nombre de usuario uv o el correo electrónico':
        'You can enter your UV username or e-mail address',
    Contraseña: 'Password',
    'Contraseña requerida': 'Password required',
    'E-mail requerido': 'E-mail address required',
    'Informe del órgano responsable (departament, instituto, ...)':
        'Report of the body responsible (department, institute, etc.)',
    'Curriculum de profesorado externo': 'CV of external teaching staff',
    'En un único fichero zip si son varios':
        'In a single zip file, if there are several',
    'Otra documentación relevante': 'Other relevant documents',
    'Observaciones adicionales': 'Other remarks',
    '¡El fichero no puede exceder de 10MB!': 'The file must not exceed 10MB',
    'Órgano proponente del curso': 'Body proposing the course',
    'Tipo de órgano': 'Type of body',
    Órgano: 'Body',
    Calendario: 'Schedule',
    Edición: 'Edition',
    'Máximo de estudiantes': 'Maximum number of students',
    'Inicio / Fin de las clases': 'Start/end of lessons',
    'Campo autocalculado': 'Autocalculated field',
    'Fecha Fin Preinscripción': 'Pre-enrolment end date',
    'Mímino 15 días antes del inicio del curso':
        'At least 15 days before the start of the course',
    'Entidades colaboradoras': 'Collaborating entities',
    'Localización - Horarios': 'Location - Schedules',
    'Recuerda que debes crear el plan de estudios con el mismo idioma elegido para el título propio.':
        'Remember to create the curriculum in the same language chosen for the UV-specific degree',
    'Modalidad de impartición': 'Mode of delivery',
    Idioma: 'Language',
    'Lugar de impartición': 'Place of teaching',
    'Días de impartición y horarios': 'Days and times of lessons',
    'El tipo de órgano es requerido': 'Type of body is required',
    'El órgano es requerido': 'Body is required',
    'La fecha es requerida': 'Date is required',
    'No puede ser menor que el número mínimo de estudiantes':
        'The number cannot be less than the minimum number of students',
    'El presupuesto del título vinculado se gestiona desde el título "padre"':
        'The budget of the associated degree is managed from the "parent"',
    'Total Prespuesto': 'Total budget',
    'Total Ingresos': 'Total revenue',
    'Total Gastos': 'Total expenditure',
    Superavit: 'Surplus',
    Déficit: 'Deficit',
    'Desglose Ingresos': 'Revenue breakdown',
    'Número mínimo de estudiantes': 'Minimum number of students',
    Patrocinios: 'Sponsorships',
    'Ingresos por hijos': 'Income from children',
    'Salarios Dirección ': "Directors' salaries",
    Salario: 'Salary',
    'Desglose de gastos': 'Expenditure breakdown',
    'Canon universitario': 'University fee',
    'Porcentaje Gestión Universitaria': 'Percentage for university management',
    'Puedes especificar uno distinto del general del':
        'You may specify one different from the general',
    'Gestión universitaria': 'University management',
    'Fondo de becas': 'Scholarship fund',
    Profesorado: 'Teaching staff',
    'Dirección (hast ': 'Director (until ',
    'Material docente': 'Class materials',
    'Viajes y dietas': 'Travel and subsistence allowance',
    Otros: 'Others',
    Seguros: 'Insurance',
    'Publicidad y promoción': 'Advertising and promotion',
    'Actividades complementarias': 'Complementary activities',
    'Medios e instalaciones': 'Means and facilities',
    Título: 'Degree',
    'Máximo ${this.constants.OTHER_AMOUNT_MAX_PERCENT}% del total de ingresos':
        'Maximum ${this.constants.OTHER_AMOUNT_MAX_PERCENT}% of total revenue',
    'La suma de salarios excede el máximo permitido':
        'The sum of salaries exceeds the maximum allowed',
    Asociar: 'Associate',
    'al título propio': 'to the UV-specific degree',
    'Añadir Director o Directora al título': 'Add degree director',
    'Escribe nombre, apellidos o NIF para elegir un Director UV':
        'Type in name, surnames or NIF to choose a UV director',
    'Escribe nombre, apellidos o NIF para elegir un Director o Directora Externo/a':
        'Type in name, surnames or NIF to choose an external director',
    'Director/Directora UV': 'UV director',
    'Director/Directora Externo': 'External director',
    'Curso académico para el título': 'Academic year for the degree',
    'Duración en cursos académicos del título':
        'Duration of the degree in academic years',
    'No se recomienda superar los 60 ECTS por curso académico':
        'Exceeding 60 ECTS per academic year is not recommended',
    'Título vinculado': 'Associated degree',
    'Nombre para el título propio': 'Name for the UV-specific degree',
    'Profesor/a UV solicitante del título propio (UV)':
        'UV lecturer proposing the UV-specific degree',
    'Directores o Directoras Universitat de València':
        'Directors from the Universitat de València',
    'Añadir director o directora': 'Add director',
    'Directores o Directoras Externos/as (si los hubiera)': 'External directors (if any)',
    'Añadir director o directora externo/a': 'Add external director',
    'Se borrará al profesor de la asignatura':
        'The lecturer of the subject will be deleted',
    'El año académico es requerido': 'Academic year is required',
    'La duración es requerida': 'Duration is required',
    'El título es requerido': 'Degree is required',
    'El director responsable de la uv es requerido':
        'UV director in charge is required',
    '${this.masterState.master_state.name':
        '${this.masterState.master_state.name',
    'Detalle resumen profesorado uv': 'Detailed summary of UV teaching staff',
    'Detalle resumen profesorado externo':
        'Detailed summary of external teaching staff',
    Dirección: 'Director',
    'Datos básicos': 'Basic data',
    'Otros datos': 'Other details',
    'Plan de estudios': 'Curriculum',
    Claustro: 'Senate',
    Presupuesto: 'Budget',
    Anexos: 'Annexes',
    Presentar: 'Submit',
    'El título propio no existe': 'The UV-specific degree does not exist',
    'Ocurrió un error ': 'An error occurred',
    'Listado de títulos presentados': 'List of degrees submitted',
    Buscar: 'Search',
    'Se produjo un error realizando la operación.':
        'An error occurred during the operation',
    'Presenta el título para su revisión por los técnicos':
        'Submit the degree for review by specialists',
    'Al ser aprobado, no se permiten modificaciones': 'Upon approval, no modifications are allowed',
    'Marcar el título como inactivo.': 'Mark degree as inactive,',
    'Marcar como Revisado': 'Mark as reviewed',
    'Deja el título como "correcto" y no permite su posterior edición por parte del director':
        'The degree is marked as "correct" and further editing by the director is not allowed',
    'Pedir subsanación': 'Request amendment',
    'Pide al director que realice modificaciones sobre el título.':
        'Ask the director to make changes to the degree',
    'Alta en Sigue': 'Register in SIGUE',
    Memoria: 'Report',
    'La búsqueda de ': 'The search for ',
    ' no produjo ningún resultado': 'did not yield any results',
    'Alta de título en SIGUE': 'Register degree in SIGUE',
    'Vas a traspasar el título al sistema SIGUE. Una vez traspasado el título no se podrá modificar. Quieres continuar?':
        'You are going to transfer the degree to the SIGUE system. Once the degree has been transferred, it cannot be modified. Are you sure?',
    Si: 'Yes',
    Cancelar: 'Cancel',
    'Presentar Título': 'Submit degree',
    'Archivar Título': 'Archive degree',
    'Aprobar ítulo': 'Approve degree',
    'Al presentarlo, el título será revisado por los técnicos y dejará de ser editable para el director. Quieres continuar?':
        'On submission, the degree will be reviewed by the specialists and it will no longer be editable for the director. Are you sure?',
    'Marcar título como revisado': 'Mark the degree as reviewed',
    'Marcar título como borrador.': 'Mark the degree as draft.',
    'Marcar como Borrador': 'Mark as draft',
    'Al marcar un título como revisado, se dara como correcta la información asociada pudiendo presentar el título.  Quieres continuar?':
        'By marking a degree as reviewed, any associated information will be considered correct and the degree can then be submitted.  Are you sure?',
    'Pedir al director subsanar errores': 'Ask the director to amend errors',
    'Al pedir subsanación, el título pasará de nuevo a estado borrador y el director deberá volver a presentarlo. Quieres continuar?':
        'By requesting an amendment, the degree will be reverted to draft status and the director will have to resubmit it. Are you sure?',
    'Al marcar un título como borrador, este volverá a ser editable por el usuario y deberá pasar por el flujo normal de aprobación. Quieres continuar?':
        'By mark a degree as draft, it will return to its original state, and it has to pass the normal approval flow. Are you sure?',
    'Título padre': 'Parent degree',
    Director: 'Director',
    Centro: 'Faculty or school',
    ECTS: 'ECTS',
    Tipo: 'Type',
    'F. Fin Preinscripción': 'Pre-enrolment deadline',
    Estado: 'Status',
    'En Sigue': 'In SIGUE',
    'Relevancia académica': 'Academic relevance',
    'Objetivos del curso': 'Aims of the course',
    'Requisitos de acceso y perfil de ingreso': 'Access requirements and entry profile',
    'Salidas profesionales y/o perfil de egreso': 'Career opportunities and/or graduation profile',
    'Metodología general': 'Overall Methodology',
    'Ámbitos de conocimiento': 'Areas of knowledge',
    'Información para publicidad': 'Information for advertising',
    'Información para publicidad: esta información aparecerá en la web': 'Information for advertising, this information will appear at the web',
    'Rama de conocimiento': 'Branch of knowledge',
    'Escribe aquí el interés y la relevancia académica científica y profesional del curso':
        'Write here the interest and academic, scientific and professional relevance of the course',
    'Escribe aquí los objetivos del curso': 'Write here the aims of the course',
    'Escribe aquí el perfil de estudiantes y requisitos de acceso':
        'Write here the student profile and entry requirements',
    'Escribe aquí las salidas profesionales del curso':
        'Write here the career opportunities of the course',
    'Escribe aquí la metodología general del curso':
        'Write here the overall methodology of the course',
    'No se puede presentar el título.  Algún paso no ha sido completado correctamente.':
        'The degree cannot be submitted.  Some step has not been completed correctly',
    'Presentar el título': 'Submit the degree',
    'El título está ': 'The degree is ',
    'Detalle - Asignaturas': 'Detail - Subjects',
    'Resumen de horas impartidas y ECTS': 'Summary of hours taught and ECTS',
    'Actualiza la lista de asignaturas del título vinculado':
        'Update the list of subjects of the associated degree',
    'Actualizar asignaturas': 'Update subjects',
    Nombre: 'Name',
    'Tipo docencia': 'Type of teaching',
    Prácticas: 'Internships',
    'Crear asignatura': 'Create subject',
    'Modificar asignatura': 'Modify subject',
    'Nombre de la asignatura': 'Name of the subject',
    'Año del título en el que se imparte la asignatura':
        'Year of the degree in which the subject is taught',
    'Tipo de docencia': 'Type of teaching',
    'Es trabajo fín de master': "Master's thesis",
    'El master ya tiene una asignatura fin de master.':
        "The master's degree already has a master's final subject",
    'Es asignatura de pŕacticas (curriculares)': '(Curricular) internships',
    'El nombre es requerido': 'Name is required',
    'El tipo de docencia es requerido': 'Type of teaching is required',
    'El número de ECTS es requerido': 'Number of ECTS is required',
    'El número mímino de ECTS es ': 'The minimum number of ECTS is',
    'El número mímino de ECTS para el TFM es':
        "The minimum number of ECTS for the master's thesis is ",
    'Sólo se admite medio ECTS': 'Only half an ECTS is allowed',
    'El campo es requerido': 'The field is required',
    'Detalle plan de estudios': 'Detailed curriculum',
    'Añadir nueva asignatura': 'Add new subject',
    'Actualiza asignaturas del título vinculado':
        'Update subjects of the associated degree',
    'Eliminar asignatura': 'Delete subject',
    'Eliminar título': 'Delete degree',
    'Guía docente': 'Course guide',
    Profesores: 'Teaching staff',
    'Total Año ': 'Total year',
    Total: 'Total',
    'Se eliminará la asignatura completa, con toda la información de profesores y plan de estudios.':
        'The full subject, including teaching staff and curriculum information, will be deleted',
    Año: 'Year',
    'Horas min.': 'Min. Hours',
    'Horas max': 'Max. Hours',
    'Horas asignadas': 'Hours assigned',
    'Detalle profesorado asignatura: ':
        'Details of teaching staff of the subject: ',
    'Volver a la lista de asignaturas': 'Back to the list of subjects',
    'Asociar profesor': 'Link lecturer',
    'Hs. Míminas': 'Min. Hours',
    'Hs. Máximas': 'Max. Hours',
    'Total Asignadas:': 'Total assigned:',
    'Modificar datos': 'Modify data',
    'Eliminar asociación con profesor': 'Remove link with lecturer',
    Profesor: 'Lecturer',
    Origen: 'Origin',
    Responsable: 'Person in charge',
    'Docencia teórica': 'Theory teaching',
    Horas: 'Hours',
    '€/hora': '€/hour',
    'Docencia práctica': 'Practical teaching',
    'Tutorías obligatorias': 'Compulsory tutorials',
    'Guía docente de la Asignatura:': 'Course guide for the subject',
    'Resumen de la asignatura': 'Summary of the subject',
    'Resultado del aprendizaje de la asignatura':
        'Learning outcome for the subject',
    'Metodología del aprendizaje': 'Learning methodology',
    'Sistemas de evaluación de la asignatura':
        'Systems of assessment of the subject',
    'Bibliografía de la asignatura': 'Bibliography of the course',
    'Descriptores del temario de la asignatura':
        'Descriptors of the course syllabus',
    'Inicio de la docencia': 'Start of teaching',
    'Finalización de la docencia': 'End of teaching',
    'Escribe aquí el resumen de la asignatura':
        'Write here a summary of the subject',
    'Escribe aquí el resultado del aprendizaje':
        'Write here the learning outcome',
    'Escribe aquí la metodología de aprendizaje de la asignatura':
        'Write here the learning methodology of the subject',
    'Escribe aquí los sistemas de evaluación de la asignatura':
        'Write here the systems of assessment of the subject',
    'Escribe aquí la biblografía de la asignatura':
        'Write here the biblography of the course',
    'Escribe aquí los descriptores del temario de la asignatura':
        'Write here the descriptors of the course syllabus',
    'El temario no puede estar vacío.': 'The syllabus cannot be left empty',
    'El título es un ': 'The degree is a ',
    'El título no tiene asignado ningún tipo de titulación':
        'The degree has not been assigned any type of qualification',
    ' Año ': 'Year',
    'Nuevo Profesor': 'New lecturer',
    'Modificar profesor': 'Change lecturer',
    Apellidos: 'Surnames',
    NIF: 'NIF',
    'NIF sin guiones, sólo números y letra':
        'NIF without the dash, only numbers and letter',
    'E-mail': 'E-mail',
    'Fecha de nacimiento': 'Date of birth',
    Teléfono: 'Phone number',
    'Es un profesor de otra universidad distinta a la UV':
        'Lecturer from a university other than the UV',
    'Es un profesor que trabaja en una empresa':
        'Lecturer working in a company',
    'Es persona física': 'Is a natural person',
    'Nuevo Profesor UV': 'New UV lecturer',
    'Modificar profesor UV': 'Change UV lecturer',
    Departamento: 'Department',
    'Categoría profesional': 'Professional category',
    'Área de conocimiento': 'Area of knowledge',
    'Asociar Profesor a asignatura': 'Link lecturer to subject',
    'Modificar Relación Profesor - Asignatura': 'Modify link lecturer-subject',
    'Nuevo Profesor Externo': 'New external lecturer',
    'Modificar datos profesor externo': 'Change details of external lecturer',
    'Nuevo P.D.I/P.A.S UV': 'New UV PDI/PAS',
    'Modificar datos P.D.I/P.A.S UV': 'Change details of UV PDI/PAS',
    'Elige un profesor UV': 'Choose a UV lecturer',
    'Elige un profesor externo a la UV':
        'Choose a lecturer from outside the UV',
    'Es responsable': 'Lecturer in charge',
    'Todas las asignaturas tienen un profesor responsable que es la persona que firmará el acta. Caso de no ser profesor uv, el acta la firmará el director':
        'All subjects have a lecturer in charge who is responsible for signing the exam records. If he/she is not a UV lecturer, the director will sign the records',
    'Número de horas': 'Number of hours',
    '€ / hora': '€/hour',
    'El profesor ya está asociado a la asignatura':
        'The lecturer is already linked to the subject',
    'Sólo se admiten medias horas': 'Only half hours are allowed',
    'Sólo se admiten mitades (0,5)': 'Only halves are allowed (0.5)',
    'Superado el máximo permitido de': 'Exceeding the maximum number of ',
    'Coste Total profesorado': 'Total cost of teaching staff',
    'Horas Docenci': 'Hours of teaching',
    '% horas docencia profesorado ': '% hours of teaching of lecturers',
    'Coste Total': 'Total cost',
    'Crear título propio vinculado': 'Create associated UV-specific degree',
    "Elige el título propio 'padre'": "Choose the 'parent' UV-specific degree",
    "Nombre para el nuevo título propio vinculado ('hijo')":
        "Name for the new associated UV-specific degree ('subsidiary')",
    'El nombre debe ir precedido del tipo de título (Master de Formación Permanente en..., Experto universitario en...). Ejemplos:':
        "The name must be preceded by the type of degree (LongLife Learning Master in..., University Expert in...). Examples:",
    'El nombre debe ir precedido del tipo de título (Microcredencial en..., Certificado Universitario en...). Ejemplos:':
        "The name must be preceded by the type of degree (Microcredential in..., University Expert in...). Examples:",
    'Diploma de Especialización': 'Diploma of specialisation',
    'Derecho de la economía digital': 'Law in the digital economy',
    'Certificado Universitario ': 'Continuous Learning Certificate',
    'Documentos digitales y archivos': 'Digital documents and archives',
    'Experto Universitario': 'University Expert',
    'en "Startup Management"': 'in"Startup Management"',
    'No puede ser mayor que el número máximo de estudiantes':
        'The number cannot exceed the maximum number of students',
    'Crear título propio': 'Create UV-specific degree',
    'Crear título - Formación Contínua': 'Create Degree - Continuous Learning',
    'Crear Título de Formación Contínua': 'Create Degree of Continuous Learning',
    'Curso académico de inicio para el título':
        'Starting academic year for the degree',
    'Master de formación permanente en ': "LongLife Learning Master in ",
    "Microcredential ": "Microcredential",
    'Terapia Manual: Enfoque- Neuro-Ortopédico Clínico':
        'Manual Therapy: Clinical Neuro-Orthopaedic Approach',
    'Fichero vacío': 'Empty file',
    'master unknown': "unknown master's degree",
    'Asignaturas obligatorias': 'Compulsory subjects',
    'Prácticas Externas': 'External internships',
    'Trabajo Final de Máster (TFM)': "Master's Thesis (TFM)",
    Español: 'Spanish',
    Valencià: 'Catalan',
    Draft: 'Draft',
    Reviewed: 'Reviewed',
    Submitted: 'Submitted',
    Approved: 'Approved',
    Archived: 'Archived',
    'Published with errors': 'Published with errors',
    Amendment: 'Amendment',
    Amended: 'Amended',
    "Master": 'Lifelong Learning Master',
    'University Expert': 'University Expert',
    'Diploma of Specialisation': "Diploma of Specialisation",
    'University Certificate': 'Continuous Learning Certificate',
    'Faculty or school': 'Faculty or school',
    Department: 'Departament',
    'Research Institute': "Research Institute",
    'Arts and Humanities': 'Arts and Humanities',
    Sciences: 'Sciences',
    'Health Sciences': 'Health Sciences',
    'Social and Legal Sciences': 'Social and Legal Sciences',
    'Engineering and Architecture': 'Engineering and Architecture',
    'Business Management': 'Business Management',
    'Social and Legal': 'Social and Legal',
    Humanities: 'Humanities',
    'Education Sciences': 'Education Sciences',
    Psychology: 'Psychology',
    'Environment, Health and Safety': 'Environment, Health and Safety',
    Health: 'Health',
    'Science and Technology': 'Science and Technology',
    'Physical Activity and Sport': 'Physical Activity and Sport',
    Spanish: 'Spanish',
    English: 'English',
    Online: 'Online',
    'Face-to-face': 'Presencial',
    Blended: 'Blended',
    'Código UV del título': 'UV code for the degree',
    'Otro órgano proponente del curso (OPCIONAL)':
        'Another body proposing the course (OPTIONAL)',
    'Dado que se trata de un profesor UV no funcionario, contemplar en los honorarios del profesor el pago destinado a la seguridad social.':
        "Given that the lecturer is not a UV public employee, include the social security costs in the lecturer's fees",
    'Un curso académico': 'One academic year',
    'Dos cursos académicos': 'Two academic years',
    'Tres cursos académicos': 'Three academic years',
    'Un fichero pdf': 'One pdf file',
    'Curso 2021/2022': 'Year 2021/2022',
    'Acuerdo para realización de títulos propios (profesorado UV)':
        'Agreement for teaching UV-specific degrees (UV teaching staff)',
    'No se puede presentar el título. Algún paso no ha sido completado correctamente.':
        'The degree cannot be submitted. Some step has not been completed correctly',
    ' Modificar / Revisar título': 'Modify / Review degree',
    'Se eliminará el título con todos sus datos. Estás seguro/a?':
        'The degree and all the data will be deleted. Are you sure?',
    'El plazo de presentación ha finalizado.':
        'The submission period has ended',
    'Agrupar padres e hijos': 'Group parent and subsidiary',
    'El tipo de fichero es obligado': 'File type is required',
    'Modelo 83': 'Model 83',
    'Currículums profesores externos': 'External teachers curriculums',
    'Puedes obtener un fichero zip con todos los ficheros del modelo 83 de todos los títulos de un curso académico pinchando en "Generar Fichero"':
        'You can get a zip file with all model 83\'s files of all academic year masters clicking in "Generate File"',
    'Filtrar por curso académico': 'Filter by academic courses',
    'Importar título de otro curso académico':
        'Import degree from another academic year',
    'Curso académico': 'Academic year',
    "Selecciona un título de la lista y pincha en 'Crear título'":
        "Selects one degree from the list below and click 'Create degree'",
    'Título importado con éxito': 'Degree imported successfully',
    'Se creará un nuevo título idéntico al seleccionado para el curso académico actual. Quieres continuar?': "A new degree will be created identical to the one selected for the current academic year. You're sure",
    'Revisión de cambios': 'Change review',
    'Orden': 'Order',
    'Requerido': 'Required',
    'Abajo': 'Down',
    'Arriba': 'Up',
    'Pincha y arrastra la fila para ordenar': 'Click and drag the row to set order',
    'Precio general': 'General price',
    'Escribe una contraseña para tu cuenta': 'Write a password for your new account',
    'Error estableciendo la contraseña': 'Error setting password',
    'Vuelve a introducir la contraseña': 'Write password again',
    "Establecer contraseña": "Set password",
    'La contraseña se ha establecido correctamente. Ya puedes iniciar sesión en la aplicación Solicitudes para gestionar tus títulos.': 'Password successfully setted. You can login in the application to manage your degrees.',
    'Solicitud de cambio de contraseña': 'Password change request',
    'Esta opción sólo es válida para usuarios externos a UV.': 'This option is valid only for external users (no UV).',
    'Error al solicitar cambio de contraseña': 'Error while requesting password change',
    'Correo electrónico de acceso de la aplicación': 'Application access email',
    'Enviar solicitud de cambio de contraseña': 'Send password change request',
    'He olvidado mi contraseña': 'I\'ve forgotten my password',
    'Las contraseñas deben cumplir los siguientes requisitos:': 'Passwords must meet the following requirements:',
    'Debe tener al menos 6 caracteres': 'Must be at least 6 characters',
    'Debe contener una mayúscula': 'Must contain a capital letter',
    'Debe contener un número': 'Must contain a number',
    'Debe contener un carácter especial [!@#$%*]': 'It must contain a special character [! @ # $% *]',
    'Correo electrónico':'Email address',
    'Se ha enviado un correo a tu cuenta con el enlace para establecer tu contraseña': 'An email has sent to you with the link to set password',
    'Sincronización con Sigue': 'Sigue Synchronization',
    'TEACHER_OTHER_UNIV': 'Other Universities Teachers',
    'TEACHER_EXTERNAL': 'External Teachers',
    'CENTER': 'Centers',
    'TEACHER_UV': 'UV Teachers',
    'COMPANY': 'Companies',
    'DEPARTMENT': 'Departaments',
    'PROFESSIONAL_CATEGORY': 'Professional Categories',
    'Nuevos': 'New',
    'Modificados': 'Updated',
    'Refrescar': 'Refresh',
    'Generar ficheros Zip': 'Generate zip files',
    'No existen sincronizaciones.': 'No sincronizations exits',
    'Copiar datos desde Sigue': 'Copy data from Sigue',
    'Esta operación tardará un poco. Ten paciencia...': 'This operation may take a while. Be patient...',
    'Volcar datos a Solicitudes': 'Dump data to Solicitudes',
    'Una vez comprobados los datos que vas a actualizar (nuevos y modificaciones), quieres volcar esta información a la base de datos de Solicitudes. Esta acción no tiene vuelta atrás. Quieres continuar?': 'Once you have verified the data you are going to update (new and modified), you want to add this information to the \'Solicitudes\' database. This action has no turning back. Do you want to continue?',
    'Se copiaron los datos con éxito': 'Data was copied successfully',
    'Sincronización iniciada el ': 'Synchronization started on ',
    'Última sincronización realizada el ': 'Last synchronization made at ',
    'Nueva sincronización (volver a copiar datos desde Sigue)': 'New synchronization (copy data from Sigue again)',
    'Pendiente de volcar a solicitudes.': 'Pending to dump "Solicitudes".',
    'Volver a copiar datos desde Sigue': 'Re-copy data from "Sigue"',
    'Toda la información está sincronizada entre Sigue y Solicitudes.': 'All information is synchronized between Sigue and Solicitudes.',
    'Generar Fichero': 'Generate file',
    'Tipo de Fichero': 'File type',
    'Ejemplo: Médico forense':'Example: Forensic Physician',
    "Universidad": "University",
    "Si no encuentras la universidad que buscas, puedes enviar un correo solicitando su creación a": "If you cannot find the university you are looking for, you can send an email requesting its creation to",
    "Cargo": "Position",
    "Categoría / Cargo": "Category / Position",
    "Cargo en la empresa": "Position in the company",
    "La categoría profesional del director del título no permite presentar este tipo de título.": "Professional category of Mster's director don't allow publish this kind of degree",
    "El título no se podrá presentar ya que la categoría profesional del director del título (": "Degree will not be published as the director's professional category (",
    ") no permite crear títulos de tipo": ") don't allow publish this kind of degrees",
    'Master': 'Permanent Academic Training Degree',
    'No se puede establecer el tipo de título a partir de sus ECTS. Revisa las asignaturas para que a partir de la suma de ECTS pueda establecerse el tipo de título.': 'The degree type cannot be established from its ECTS. Review the subjects so that from the sum of ECTS you can establish degree type.',
    'No se puede publicar el título. ': 'Degree can be published. ',
    'No todos los directores son profesores.': 'Not all directors are degree teachers.',
    "Es modular": "Is modular",
    "Es módulo del título...": "Is module of degree...",
    "Es módulo de ": "Is module of ",
    "Solo se muestran títulos modulares": "Only shown modular degrees",
    "Un título modular necesita al menos dos tarifas. Una \"general\" y otra para el caso de cursarlo por módulos.": "A modular title needs at least two fees. One \"general\" and another for the case of taking it by modules.",
    "Asignaturas optativas a cursar": "Optional subjects to take",
    "ECTS por asignatura Optativa": "ECTS by each optional subject",
    "Para poder asociar al título asignaturas optativas, se requiere autorización. Ponte en contacto en ": "In order to associate elective subjects with the degree, authorization is required. Get in touch at ",
    "Optativa": "Optional",
    "FORMACIÓN CONTÍNUA": "CONTINUOUS LEARNING",
    "Títulos Propios y Formación Contínua UV": "UV-specific Degrees and Continuous Learning",
    "Crear Título Propio": "Create UV-specific Degree",
    "Máster de formación permanente, Diploma de especialización, Experto Universitario": "Longlife Learning  Master, Diploma of specialisation, University Expert",
    "Microcredencial, Certificado": "Microcretencial, Certificate",
    "El título no tiene ninguna asignatura.": "Degree has no subjects.",
    "Configurar asignaturas optativas": "Configure optional subjects",
    "Configurar optativas": "Configure optional subjects",
    "Al menos un codirector debe tener la categoría profesional adecuada para presentar el título.": "At least one co-director must have the proper professional category",
    "Para poder marcar una asignatura como Optativa, primero configura las asignaturas optativas en la pantalla anterior.": "In order to mark a subject as optional, first configure optional subjects on the previous screen.",
    "Declaración responsable de la dirección sobre claustro": "Director's responsible statement on the teaching staff",
    "Puedes descargar la plantilla del documento \"Declaración responsable de la dirección sobre claustro\" ya generada con los datos del título, pinchando en el botón \"Generar Modelo\".": "You can download the template of the document 'Director's responsible statement on the teaching staff' already generated with degree data clicking on 'Generate Model' button.",
    "Una vez generado, deberás firmar el documento con tu firma digital y subirlo a esta plataforma.": "Once generated, you must sign the document with your digital signature and upload it to this platform.",
    "Para firmar un documento con tu firma digital, puedes utilizar varias aplicaciones:": "To sign a document with your digital signature, you can use several applications:",
    "Siguiendo estas instrucciones parar firmar.": "By following these instructions to sign.",
    "Siguiendo los pasos solicitados al arrancar la aplicación.": "Following the steps requested when starting the application.",
    "Convertir a formación contínua": "Convert to continuous learning",
    "Escribe aquí para filtrar": "Enter the search term",
    "Elige uno o varios estados": "Choose one or more statuses",
    "Títulos": "Degrees",
    "DEFAULT": "UV-specific degrees",
    "CONTINOUS_LEARNING": "Continuous Learning",
    "La suma de salarios excede el máximo permitido": "The sum of wages exceeds the maximum allowed",
    "Servicios E-learning Adeit": "Adeit learning services",
    "Profesores inactivos": "Disabled teachers",
    "Inactivo": "Disabled",
    "El profesor o profesora está inactivo o inactiva actualmente. Elige de la lista el mismo profesor o profesora, pero con la información actualizada.": "Teacher is currently disabled. Choose the same teacher from the list but with its updated data",
    "El profesor o profesora está inactivo o inactiva": "Teacher is currently disabled",
    "Elige un profesor o una profesora de la lista": "Choose a teacher from list",
    "Elige el mismo profesor o profesora, pero con la información actualizada": "Choose same teacher but with its updated associated data",
    "Algún director o directora del título no se encuentra activo actualmente. Elimina al director o directora marcado como 'inactivo' y vuélvelo a añadir con sus datos actualizados. En caso de que no exista el director o directora póngase en contacto con el soporte técnico.": "Some degree director is currently disabled. Delete the director marked as \"inactive\" and re-add him or her with their updated data. If there is no director, contact technical support.",
    "Copiar un título propio del curso académico anterior": "Copy an UV-specific degree from previous academic year",
    "Copiar un título de formación contínua del curso académico anterior": "Copy a continuous learning degree from previous academic year",
    "Marcar título como archivado": "Mark degree as archived",
    "Marcar título como aprobado": "Mark degree as approved",
    "Al archivar un título, este quedará como inactivo, no pudiendo realizar modificaciones sobre él. Quieres continuar?": "When archiving a title, it will remain inactive, and you will not be able to make changes to it. Do you want to continue?",
    "Aprobar un título supone que todos los datos has sido revisados y aprobados como definitivos. No se permitirán modificaciones posteriores. Quieres continuar?": "Approving a title means that all the data has been reviewed and approved as final. Subsequent modifications will not be allowed. Do you want to continue?",
    'Profesorado - Horas Curso Académico': 'Teachers - Hours By Academic Year',
    'Detalle de asignaturas(en rojo no suman al estar vinculadas a otro título)': "Subject Detail(subjects in red don´t accumulate since are linked with other degree)",
    "Generar todas las memorias(pdf)": "Generate all degrees reports(pdf)",
    "Nível MECES/EQF": "MECES/EQF Level",
    "Criterios de admisión": "Admission Criteria",
    "Escribe aquí los criterios de admisión": "Write the admission criteria here",
    "Resultados del proceso de formación y aprendizaje": "Results of the training and learning process",
    "Para cumplir con las exigencias del RD-L 2/2023, de 16 de marzo (Disposición adicional quincuagésima segunda), en el caso de los cursos que piden la realización de prácticas en empresas, se deberá incluir el apartado correspondiente al presupuesto en la memoria de solicitudes los gastos correspondientes a la inclusión de los estudiantes en el sistema de la Seguridad Social, siempre que la empresa en cuestión no disponga de una bolsa de trabajo destinada a tal efecto.": "To comply with the requirements of RD-L 2/2023, of March 16\n" +
        "(fifty-second additional provision), in the case of courses\n" +
        "that ask for internships in companies, the\n" +
        "section corresponding to the budget in the request memory\n" +
        "expenses corresponding to the inclusion of students in the system\n" +
        "of Social Security, provided that the company in question does not have\n" +
        "of a job bank intended for this purpose.\n" +
        "\n"
}
