
import Vue from 'vue';
import {ITeacher} from '../../interfaces/teacher/interfaces/models/ITeacher';
// @ts-ignore
import TeacherListItem from './TeacherListItem.vue';

export default Vue.extend({
    name: 'SelectTeacher',
    components: {
        TeacherListItem,
    },
    props: {
        apiFunction: {
            type: Function,
            default: null,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: true,
        },
        value: [Array, Object],
        // dont add a close icon to chip if element match any of the array elements.
        preventDeleteIds: {
            type: Array,
            default: () => [],
        },
        // disable items with certain criteria
        disableItems: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            valid: false,
            errors: {
                item: [],
            },
            rules: {
                item: [
                    (v: ITeacher) => {
                        if (this.required) {
                            if (
                                (Array.isArray(v) && v.length == 0) ||
                                (!Array.isArray(v) && v == null)
                            ) {
                                return this.$i18n.t('El dato es requerido');
                            }

                            return true;
                            N;
                        } else {
                            return true;
                        }
                    },
                ],
            },
            itemsData: [],
            isLoading: false,
            search: null,
            hintText: this.$i18n.t(
                'Escribe para buscar por nombre, apellidos o NIF'
            ),
            awaitingSearch: false,
        };
    },
    computed: {
        items() {
            let items = this.itemsData;

            if (this.value != null) {
                if (this.multiple) {
                    if (this.value.length > 0) {
                        items = items.concat(this.itemsData).concat(this.value);
                    }
                } else {
                    items.push(this.value);
                }
            }

            return items;
        },
    },
    methods: {
        remove(item: ITeacher) {
            const index = this.value.findIndex(
                (el: object) => el.id === item.id
            );
            /*eslint-disable-next-line*/
            if (index >= 0) this.value.splice(index, 1);

            this.$emit('input', this.items);
        },
        isPreventDeleted(id: number): boolean {
            return this.preventDeleteIds.length === 0
                ? false
                : this.preventDeleteIds.includes(id);
        },
        doSearch(val) {
            if (val === 'undefined' || val == null || val == '') {
                return;
            }

            const setError = (err: any) => {
                this.isLoading = false;
                this.errors.item.push(
                    this.$i18n.t(
                        'Se ha producido un error consultando el servidor'
                    )
                );
            };

            // Items have already been requested
            if (this.isLoading) return;

            this.isLoading = true;

            try {
                // Lazily load input items
                this.apiFunction(val)
                    .then((res: any) => {
                        if (this.disableItems) {
                            // disable items with blank email
                            res.data.map(
                                (a) => (a.disabled = this.disableItems(a))
                            );
                        }

                        this.itemsData = res.data;
                        this.isLoading = false;
                    })
                    .catch((err: any) => {
                        setError(err);
                    })
                    .finally(() => (this.isLoading = false));
            } catch (err) {
                setError(err);
            }
        },
    },
    watch: {
        value(newValue, oldValue) {
            // ensures itemsData is cleared whenever select value changes, clearing search results
            this.itemsData = [];
        },
        async search(val: string) {
            // Items have already been loaded
            // if (this.items.length > 0) return;

            if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.doSearch(val);
                    this.awaitingSearch = false;
                }, 1000); // 1 sec delay
            }
            this.awaitingSearch = true;
        },
    },
});
