// @ts-ignore
import axios from 'axios';
import * as HTTP_STATUS from 'http-status-codes';
// @ts-ignore
import AppEventBus from '@/AppEventBus';

function errorResponseHandler(error: any) {
    if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        if (error.response.data) {
            // actualizamos el mensaje con el mensaje real enviado por el servidor
            error.message = error.response.data;
            // estas propiedades no llegan al catch del componente
            error.status = error.response.status;
            error.statusText = error.response.statusText;
        } else {
            error.status = error.response.status;
            error.message = `Error ${error.status}`;
        }

        switch (error.status) {
            case HTTP_STATUS.UNAUTHORIZED:
                AppEventBus.$emit('logout');
                break;
            default:
                break;
        }
    } else if (error.request) {
        // The request was made but no response was received
        // 'error.request' is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
        // console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        // console.log('Error', error.message);
    }
    // console.log(error.config);

    // rechazamos el resultado como una promesa para que pueda ser capturada en los catch
    return Promise.reject(error);
}

// apply interceptor on response
axios.interceptors.response.use(
    (response: any) => response,
    errorResponseHandler
);

axios.defaults.withCredentials = true;

export * from './authentication';
export * from './master';
export * from './teacher';
export * from './sync';
export * from './config';
export * from './enumerates';
export * from "./tenant";

