
import Vue from 'vue';

export default Vue.extend({
    name: 'FileField',
    props: {
        label: {
            type: String,
            default: null
        },
        value: {
            type: String,
            default: null
        },
        showTextField: {
            type: Boolean,
            default: false
        },
        accept: {
            type: String,
            default: 'application/pdf, application/zip'
        },
        rules: {
            type: Array,
            default: () => []
        },
        sendFileCb: {
            type: Function,
            default: () => null
        },
        deleteFileCb: {
            type: Function,
            default: () => null
        },
        getFilePathCb: {
            type: Function,
            default: () => null
        },
        masterFileType: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        checkSigned: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            model: this.value,
            localFile: null
        };
    },
    watch: {
        value(newValue, oldValue) {
            this.model = newValue;
        }
    },
    methods: {
      validate(): boolean {
        return this.$refs.res.validate();
      }
    }
});
