var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[(_vm.isNew)?_c('v-card-title',{staticClass:"grey lighten-4 py-4 title"},[_vm._v(" "+_vm._s(_vm.$t('Crear asignatura'))+" ")]):_c('v-card-title',{staticClass:"grey lighten-4 py-4 title"},[_vm._v(" "+_vm._s(_vm.$t('Modificar asignatura'))+" ")]),_c('v-container',{staticClass:"pa-4",attrs:{"grid-list-sm":""}},[_c('v-alert',{attrs:{"outlined":"","type":"error","value":_vm.formError != null}},[_vm._v(" "+_vm._s(_vm.$t('Ha ocurrido un error en el servidor'))+" ")]),(_vm.master.master_kind_id == _vm.MASTER_KIND.DEFAULT)?_c('v-row',[_c('v-col',{attrs:{"xs":"12"}},[_c('v-checkbox',{attrs:{"disabled":!_vm.optionalSubjectsConfigured,"label":_vm.$t('Es una asignatura optativa'),"persistent-hint":true},on:{"change":function($event){_vm.setModified(); _vm.setOptionalSubject()}},model:{value:(_vm.localState.is_optional),callback:function ($$v) {_vm.$set(_vm.localState, "is_optional", $$v)},expression:"localState.is_optional"}}),(!_vm.optionalSubjectsConfigured)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Para poder marcar una asignatura como Optativa, primero configura las asignaturas optativas en la pantalla anterior.'))+" ")]):_vm._e()],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"xs":"6"}},[_c('v-text-field',{attrs:{"required":"","rules":_vm.rules.name,"error-messages":_vm.errors.name,"prepend-icon":"font_download","label":_vm.$t('Nombre de la asignatura')},on:{"input":_vm.setModified},model:{value:(_vm.localState.name),callback:function ($$v) {_vm.$set(_vm.localState, "name", $$v)},expression:"localState.name"}})],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.master_academic_years.length > 1),expression:"master_academic_years.length > 1"}]},[_c('v-col',{attrs:{"xs":"6"}},[_c('v-select',{attrs:{"label":_vm.$t(
                                'Año del título en el que se imparte la asignatura'
                            ),"items":_vm.master_academic_years,"rules":_vm.rules.academic_year_offset,"prepend-icon":"filter_3"},on:{"input":_vm.setModified},model:{value:(_vm.localState.academic_year_offset),callback:function ($$v) {_vm.$set(_vm.localState, "academic_year_offset", $$v)},expression:"localState.academic_year_offset"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"xs":"6"}},[_c('v-select',{attrs:{"required":"","prepend-icon":"list","label":_vm.$t('Tipo de docencia'),"items":_vm.teachingTypes,"rules":_vm.rules.teaching_type,"error-messages":_vm.errors.teaching_type,"item-text":"name","item-value":"code","return-object":true},on:{"input":_vm.setModified},model:{value:(_vm.localState.teaching_type),callback:function ($$v) {_vm.$set(_vm.localState, "teaching_type", $$v)},expression:"localState.teaching_type"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"xs":"6"}},[_c('v-currency-field',{attrs:{"disabled":_vm.localState.is_optional,"required":"","rules":_vm.rules.ects,"error-messages":_vm.errors.ects,"prepend-icon":"star","label":_vm.$t('ECTS'),"auto-decimal-mode":false,"decimal-length":1},on:{"input":_vm.setModified,"keypress":function($event){return _vm.forceNumber($event)}},model:{value:(_vm.localState.ects),callback:function ($$v) {_vm.$set(_vm.localState, "ects", $$v)},expression:"localState.ects"}})],1)],1),_c('v-flex',{attrs:{"xs12":""}},[(!_vm.localState.is_optional && !_vm.isContinuousLearning)?_c('v-checkbox',{attrs:{"disabled":(_vm.masterHasTfm &&
                            (this.localState.is_tfm == null ||
                                !this.localState.is_tfm) ),"label":_vm.$t('Es trabajo fín de master'),"persistent-hint":true,"hint":_vm.masterHasTfm &&
                        (this.localState.is_tfm == null ||
                            !this.localState.is_tfm)
                            ? _vm.$t(
                                  'El master ya tiene una asignatura fin de master.'
                              )
                            : ''},on:{"change":_vm.setModified},model:{value:(_vm.localState.is_tfm),callback:function ($$v) {_vm.$set(_vm.localState, "is_tfm", $$v)},expression:"localState.is_tfm"}}):_vm._e(),(!_vm.localState.is_optional)?_c('v-checkbox',{attrs:{"label":_vm.$t('Es asignatura de pŕacticas (curriculares)')},on:{"change":_vm.setModified},model:{value:(_vm.localState.is_practical),callback:function ($$v) {_vm.$set(_vm.localState, "is_practical", $$v)},expression:"localState.is_practical"}}):_vm._e()],1)],1),(_vm.isNew)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary_buttons"},on:{"click":function($event){return _vm.exit()}}},[_vm._v(_vm._s(_vm.$t('Cancel')))]),_c('v-btn',{staticClass:"white--text",attrs:{"disabled":!_vm.valid || !_vm.pendingChanges,"text":"","color":"primary_buttons"},on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.$t('Guardar')))])],1):_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary_buttons"},on:{"click":function($event){return _vm.exit()}}},[_vm._v(_vm._s(_vm.$t('Salir')))]),_c('v-btn',{attrs:{"disabled":!_vm.pendingChanges,"text":"","color":"secondary_buttons"},on:{"click":function($event){return _vm.cancelChanges()}}},[_vm._v(" "+_vm._s(_vm.$t('Cancelar cambios'))+" ")]),_c('v-btn',{staticClass:"white--text",attrs:{"disabled":!_vm.valid || !_vm.pendingChanges,"text":"","color":"primary_buttons"},on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.$t('Guardar')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }