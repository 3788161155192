// @ts-ignore
import * as api from '@/api/index';

export const form = {
    data() {
        return {
            api: api,
            valid: false,
            localState: {},
            pendingChanges: false,
            stepAhead: 1
        };
    },
    methods: {
        parseLocalState(): object {
            // call component function
            // @ts-ignore
            if (this.parseLocalStateAfter) {
                // @ts-ignore
                this.parseLocalStateAfter();
            }

            // @ts-ignore
            return this.localState;
        },
        parseGlobalState() {
            // @ts-ignore
            if (this.globalState == null) {
                this.clearLocalState();
                return;
            } else {
                // @ts-ignore
                _.merge(this.localState, _.cloneDeep(this.globalState));
            }

            // @ts-ignore
            if (this.parseGlobalStateAfter) {
                // @ts-ignore
                this.parseGlobalStateAfter();
            }
        },
        clearLocalState() {
            // @ts-ignore
            this.localState = {};
            // @ts-ignore
            this.clearError();

            // if clear function is defined in parent
            // @ts-ignore
            if (this.clear) {
                // @ts-ignore
                this.clear();
            }
            // @ts-ignore
            this.pendingChanges = false;

            // @ts-ignore
            if (this.$refs.form) {
                // @ts-ignore
                this.$refs.form.resetValidation();
            }
        },
        setModified() {
            // @ts-ignore
            this.pendingChanges = true;
        },
        clearModified() {
            // @ts-ignore
            this.pendingChanges = false;
        },
        cancelChanges() {
            this.parseGlobalState();
            this.clearModified();
        },
        cancel() {
            this.clearLocalState();
            // @ts-ignore
            this.$emit('cancel');
        },
        exit() {
            this.clearLocalState();
            // @ts-ignore
            this.$emit('exit');
        },
        createOrUpdate() {
            // @ts-ignore
            if (this.isNew) {
                this.createRecord();
            } else {
                this.updateRecord();
            }
        },
        async updateRecord() {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                this.parseLocalState();

                // @ts-ignore
                await this.update(this.localState);
                this.clearModified();
                // @ts-ignore
                this.$emit('updated', false);
            }
        },
        async createRecord() {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                // @ts-ignore
                this.parseLocalState();
                // @ts-ignore
                await this.create(this.localState);
                // @ts-ignore
                this.$emit('created', this.localState);
                this.clearLocalState();
            }
        },
        save() {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                // @ts-ignore
                this.parseLocalState();
                // @ts-ignore
                this.update(this.localState).then(() => {
                    this.clearModified();
                    // @ts-ignore
                    this.$emit('updated', this.localState);
                });
            }
        },
        // @ts-ignore
        ruleRequired(v: any) {
            // @ts-ignore
            return !!v || this.$i18n.t('El dato es requerido');
        }
    },
    mounted() {
        // @ts-ignore
        this.clearLocalState();
        // @ts-ignore
        this.parseGlobalState();
    },
    created() {
        // @ts-ignore
        this.parseGlobalState();
    },
    watch: {
        // @ts-ignore
        globalState(newValue, oldValue) {
            // @ts-ignore
            this.parseGlobalState();
        }
    }
};
