
import Vue from 'vue';
import {mapGetters} from 'vuex';
import {IAcademicYear} from '../../interfaces/master/interfaces/models/IAcademicYear';
import URI from 'urijs';

export default Vue.extend({
    name: 'ZipFiles',
    computed: {
        ...mapGetters({
            formError: 'master/getError',
            academicYears: 'master/getAcademicYears'
        })
    },
    data() {
        return {
            currentTab: 0,
            academicYear: null,
            rules: {
                academic_year: [
                    (v: IAcademicYear) =>
                        !!v || this.$i18n.t('El año académico es requerido')
                ],
                file_type: [
                    (v: string) =>
                        !!v || this.$i18n.t('El tipo de fichero es obligado')
                ]
            },
            valid: false,
            fileType: null,
            fileTypes: [
                {
                    id: 'file_model_83',
                    name: this.$i18n.t('Modelo 83')
                },
                {
                    id: 'file_curriculum_ext_teachers',
                    name: this.$i18n.t('Currículums profesores externos')
                }
            ]
        };
    },

    methods: {
        getUrlZipFiles() {
            if (this.academicYear == null) {
                return;
            }

            const url = new URI(
                `${process.env.VUE_APP_API_URL}masters/zip-files`
            );

            url.addQuery({ academicYearId: this.academicYear.id });
            url.addQuery({ fileType: this.fileType.id });

            return url.valueOf();
        }
    }
});
