// @ts-ignore
import axios, {AxiosPromise} from 'axios';
// @ts-ignore
import URI from 'urijs';
import {IConfig} from "@/interfaces/config/interfaces/models/IConfig";
import {IAcademicYear} from "@/interfaces/master/interfaces/models/IAcademicYear";

export const config = {
    /**
     * Updates config with complete data
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async update(config: IConfig) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}config`
        );

        return axios.put(url.valueOf(), config) as Promise<any>;
    },
    /**
     * Updates academicYear
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async updateAcademicYear(academicYear: IAcademicYear) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}config/academic_year/${academicYear.id}`
        );

        return axios.put(url.valueOf(), academicYear) as Promise<any>;
    },
    /**
     * Get config
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async get() {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}config`
        );

        return axios.get(url.valueOf()) as Promise<any>;
    },
    /**
     * Activates deadline
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async activate_deadline() {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}config/activate_deadline`
        );

        return axios.put(url.valueOf(), config) as Promise<any>;
    },
    /**
     * Activates deadline
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async deactivate_deadline() {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}config/deactivate_deadline`
        );

        return axios.put(url.valueOf(), config) as Promise<any>;
    },
    /**
     * Gets the academic-year permissions config
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async getAcademicYearsUserCreateMasterPerms(masterId: number) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}config/academic-years-perms`
        );

        return axios.get(url.valueOf()) as Promise<any>;
    },
};
