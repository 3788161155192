
import Vue from 'vue';
import BackOffice from '../components/back-office/BackOffice.vue';
import Component from 'vue-class-component';

@Component({
    components: {
        BackOffice
    }
})
export default class BackOfficeView extends Vue {}
