
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
import {form} from '@/components/mixins';
import NumericField from '../common/NumericField.vue';
import * as helpers from "@/helpers";

export default Vue.extend({
    name: 'FeeForm',
    mixins: [form],
    components: {
        NumericField
    },
    props: {
        isNew: {
            type: Boolean,
            default: false
        },
        fee: {
            type: Object,
            default: () => null
        },
        reload: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
      if (this.isNew) {
        this.localState.target_audience = this.fee.target_audience;
      }
    },
    data() {
        return {
            errors: {
                target_audience: [],
                min_students: [],
                max_students: [],
                enrollment_price: []
            },
            rules: {
                target_audience: [this.ruleRequired],
                min_students: [
                    // @ts-ignore
                    (v: number) => {
                        if (
                            v &&
                            this.master.max_students &&
                            Number(v) > Number(this.master.max_students)
                        ) {
                            return this.$i18n.t(
                                'No puede ser mayor que el número máximo de estudiantes del título'
                            );
                        }

                        return true;
                    }
                ],
                enrollment_price: [this.ruleRequired]
            },
            localState: {
                id: null,
                target_audience: this.fee.target_audience,
                min_students: null,
                enrollment_price: null,
                master_id: null
            }
        };
    },
    computed: {
        ...mapGetters({
            formError: 'master/getError',
            master: 'master/getData'
        }),
        globalState() {
            if (this.fee.id > 0) {
                return this.fee;
            }

            return null;
        },
      disableTargetAudience() {
        return this.localState.target_audience == this.$i18n.t(process.env.VUE_APP_FEE_DEFAULT_TITLE);
      }
    },
    methods: {
        ...mapActions('master', {
            createFee: 'createFee',
            updateFee: 'updateFee',
            clearError: 'clearError'
        }),
        saveAndExit() {
            if (this.$refs.form.validate()) {
                this.createOrUpdate();
                this.exit();
            }
        },
        async save() {
            if (this.isNew) {
                this.create();
            } else {
                this.update();
            }
        },
        async update() {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                this.parseLocalState();

                // @ts-ignore
                await this.updateFee({
                    fee: this.localState,
                    master: this.master
                });
                this.clearModified();
                this.$emit('updated', false);
            }
        },
        async create() {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                await this.createFee({
                    fee: this.localState,
                    master: this.master
                });
                this.$emit('created', this.localState);
                this.clearLocalState();
            }
        },
        forceNumber(evt: any) {
          if (!helpers.isKeyNumber(evt)) {
            evt.preventDefault();
          }
        },
    },
    watch: {
        fee(newValue, oldValue) {
            if (!this.isNew && newValue != null) {
                // with this set, we ensure there form refresh (magically)
                Vue.set(this.localState, "enrollment_price", newValue.enrollment_price);
            }

            if (this.isNew && newValue != null) {
              // the first fee has target_audience field filled
              Vue.set(this.localState, "target_audience", newValue.target_audience);
            }
        }
    }
});
