
import Vue from 'vue';
import * as HTTP_STATUS from 'http-status-codes';
import Management from './Management.vue';
import BasicData from './BasicData.vue';
import OtherData from './OtherData.vue';
import MainGuide from './StudyGuide/MainGuide.vue';
import {mapActions, mapGetters} from 'vuex';
import Budget from './Budget.vue';
import TeachersReview from './TeachersReview.vue';
import Attachments from './Attachments.vue';
import Publish from './Publish.vue';

import StepsEventBus from './StepsEventBus';
import {MASTER_KIND} from "@/helpers";

export default Vue.extend({
    name: 'Master',
    props: {
        masterId: {
            type: String,
            default: null
        }
    },
    components: {
        Management,
        BasicData,
        OtherData,
        MainGuide,
        Budget,
        TeachersReview,
        Attachments,
        Publish
    },
    computed: {
        ...mapGetters({
            formError: 'master/getError',
            masterState: 'master/getData',
            hasPCategoriesRestrictions: 'master/hasPCategoriesRestrictions'
        }),
        currentStateStep() {
            return this.masterState.current_edit_step || 0;
        },
        masterTitleUpper() {
            if (!this.masterState.title) {
                return '';
            }
            const upperTitle: string = this.masterState.title.toUpperCase();

            const masterParentTitle: string =
                this.masterState.master_parent != null
                    ? ' -- vinculado con ' +
                      this.masterState.master_parent.title
                    : '';

            return upperTitle + masterParentTitle;
        },
        firstStepCompleted(): boolean {
            return (
                this.masterState.academic_year != null &&
                this.masterState.title != null &&
                this.masterState.teacher_responsible != null &&
                this.masterState.directors != null &&
                this.masterState.directors.length > 0
            );
        },
        getColorMasterTitle(): string {
          if (this.masterState.master_kind_id == MASTER_KIND.DEFAULT) {
            if (this.masterState.master_parent_id == null) {
              return 'blue-grey';
            } else {
              return 'red lighten-3';
            }
          }

          if (this.masterState.master_kind_id == MASTER_KIND.CONTINUOUS_LEARNING) {
            return 'purple lighten-3'
          }

          return 'blue-grey';

        }
    },
    data() {
        return {
            tabActiveTeacherReview: 0,
            currentStep: null,
            errorMessage: null,
            firstLoadState: true,
            steps: [
                {
                    label: this.$i18n.t('Dirección'),
                    completed: false,
                    active: false
                },
                {
                    label: this.$i18n.t('Datos básicos'),
                    completed: false,
                    active: false
                },
                {
                    label: this.$i18n.t('Otros datos'),
                    completed: false,
                    active: false
                },
                {
                    label: this.$i18n.t('Plan de estudios'),
                    completed: false,
                    active: false
                },
                {
                    label: this.$i18n.t('Claustro'),
                    completed: false,
                    active: false
                },
                {
                    label: this.$i18n.t('Presupuesto'),
                    completed: false,
                    active: false
                },
                {
                    label: this.$i18n.t('Anexos'),
                    completed: false,
                    active: false
                },
                {
                    label: this.$i18n.t('Presentar'),
                    completed: false,
                    active: false
                }
            ],
            MASTER_KIND: MASTER_KIND
        };
    },
    mounted() {
        this.getData();

        this.currentStep = 0;
        StepsEventBus.$on('setCurrentEditStep', this.saveCurrentStep);
    },
    beforeDestroy() {
        StepsEventBus.$off();
    },
    methods: {
        ...mapActions('master', ['get', 'update', 'clearError']),
        getData() {
            // @ts-ignore
            this.get({ masterId: this.masterId }).catch((err: any) => {
                this.setError(err);
            });
        },
        setError(err: any) {
            switch (err.status) {
                case HTTP_STATUS.UNAUTHORIZED:
                    this.logout();
                    this.$router.push('/login');
                    break;
                case HTTP_STATUS.NOT_FOUND:
                    this.errorMessage = this.$i18n.t(
                        'El título propio no existe'
                    );
                    break;
                default:
                    this.errorMessage = this.$i18n.t('Ocurrió un error ');
            }
        },
        isStepComplete(index: number) {
            let complete: boolean = false;

            switch (index) {
                case 0:
                    complete = this.currentStateStep >= index;
                    break;
                case 1:
                    complete = this.firstStepCompleted;
                    break;
                default:
                    complete =
                        this.firstStepCompleted &&
                        this.currentStateStep >= index;
                    break;
            }

            return complete;
        },
        isStepEditable(index: number) {
            let editable = false;

            switch (index) {
                case 0:
                    editable = this.currentStateStep >= index;
                    break;
                case 1:
                    editable = this.firstStepCompleted;
                    break;
                default:
                    editable =
                        this.firstStepCompleted &&
                        this.currentStateStep >= index;
                    break;
            }

            return editable;
        },
        stepperRules(step: number) {
            let allow: boolean = false;

            if (this.masterState.stepsStatus == null) {
              return;
            }

            // allow = this.masterState.stepsStatus[step].valid;

            if (
                this.masterState.current_edit_step <= step ||
                (this.masterState.current_edit_step == step && step != 6)
            ) {
                allow = true;
            } else {
                allow = this.masterState.stepsStatus[step].valid;
            }

            return [() => allow];
        },
        async saveCurrentStep(current_edit_step: number) {
            await this.update({ id: this.masterId, current_edit_step });
        }
    },
    watch: {
        masterId(newValue, oldValue) {
            this.getData();
        },
        masterState(newValue, oldValue) {
            if (newValue && this.firstLoadState) {
                // It's the first time
                this.firstLoadState = false;
            }
        }
    }
});
