var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{staticClass:"justify-center",attrs:{"fluid":""}},[_c('v-alert',{attrs:{"outlined":"","type":"error","value":_vm.formError != null}},[_vm._v(" "+_vm._s(_vm.$t('Ha ocurrido un error en el servidor'))+" ")]),_c('v-alert',{attrs:{"outlined":"","type":"warning","value":_vm.directorsDisabled}},[_vm._v(" "+_vm._s(_vm.$t( `Algún director o directora del título no se encuentra activo actualmente. Elimina al director o directora marcado como 'inactivo' y vuélvelo a añadir con sus datos actualizados. En caso de que no exista el director o directora póngase en contacto con el soporte técnico.` ))+" ")]),_c('save-cancel',{attrs:{"cancel-changes":_vm.cancelChanges,"pending-changes":_vm.pendingChanges,"save":_vm.save,"valid":_vm.valid}}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"6"}},[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"disabled":!_vm.imRoleAdmin,"large":"","required":"","prepend-icon":"calendar_today","label":_vm.$t('Curso académico para el título'),"items":_vm.academicYears,"rules":_vm.rules.academic_year,"error-messages":_vm.errors.academic_year,"item-text":"name","item-value":"id","return-object":true},on:{"input":_vm.setModified},model:{value:(_vm.localState.academic_year),callback:function ($$v) {_vm.$set(_vm.localState, "academic_year", $$v)},expression:"localState.academic_year"}})],1),(
                            _vm.localState.master_kind_id !==
                            _vm.MASTER_KIND.CONTINUOUS_LEARNING
                        )?_c('v-col',[_c('v-select',{attrs:{"required":"","prepend-icon":"filter_3","label":_vm.$t(
                                    'Duración en cursos académicos del título'
                                ),"items":_vm.academicYearsDuration,"rules":_vm.rules.academic_years_duration,"error-messages":_vm.errors.academic_years_duration,"hint":_vm.$t(
                                    'No se recomienda superar los 60 ECTS por curso académico'
                                ),"item-text":"description","item-value":"duration","disabled":_vm.isCurrentMasterDisabled},on:{"input":_vm.setModified},model:{value:(_vm.localState.academic_years_duration),callback:function ($$v) {_vm.$set(_vm.localState, "academic_years_duration", $$v)},expression:"localState.academic_years_duration"}})],1):_vm._e(),(
                            _vm.localState.master_type_code == 'mas' ||
                            _vm.localState.master_type_code == 'dip' ||
                            _vm.localState.master_type_code == 'exp'
                        )?_c('v-col',[(_vm.localState.master_type_code == 'mas')?_c('v-checkbox',{attrs:{"disabled":_vm.localState.master_type_code != 'mas' ||
                                _vm.isCurrentMasterDisabled,"label":_vm.$t('Es modular'),"persistent-hint":true},on:{"change":_vm.setModified},model:{value:(_vm.localState.modular),callback:function ($$v) {_vm.$set(_vm.localState, "modular", $$v)},expression:"localState.modular"}}):_vm._e(),(
                                _vm.localState.master_type_code == 'dip' ||
                                _vm.localState.master_type_code == 'exp'
                            )?_c('v-autocomplete',{attrs:{"required":"","prepend-icon":"calendar_today","label":_vm.$t(`Es módulo del título...`),"persistent-hint":"","hint":_vm.$t('Solo se muestran títulos modulares'),"items":_vm.modularMasters,"rules":_vm.rules.modular_master,"error-messages":_vm.errors.modular_master,"item-text":"title","item-value":"id","return-object":true,"clearable":"","disabled":_vm.isCurrentMasterDisabled},on:{"change":_vm.setModified},model:{value:(_vm.localState.modular_master),callback:function ($$v) {_vm.$set(_vm.localState, "modular_master", $$v)},expression:"localState.modular_master"}}):_vm._e()],1):_vm._e()],1),(_vm.localState.master_parent_id != null)?_c('v-text-field',{attrs:{"readonly":"","disabled":"","large":"","label":_vm.$t('Título vinculado')},model:{value:(_vm.localState.master_parent.title),callback:function ($$v) {_vm.$set(_vm.localState.master_parent, "title", $$v)},expression:"localState.master_parent.title"}}):_vm._e(),_c('v-text-field',{attrs:{"large":"","required":"","rules":_vm.rules.title,"error-messages":_vm.errors.title,"prepend-icon":"notes","label":_vm.$t('Nombre para el título propio'),"placeholder":"Nombre","disabled":_vm.isCurrentMasterDisabled},on:{"input":_vm.setModified},model:{value:(_vm.localState.title),callback:function ($$v) {_vm.$set(_vm.localState, "title", $$v)},expression:"localState.title"}}),(_vm.imRoleTechnic || _vm.imRoleAdmin)?_c('v-text-field',{attrs:{"large":"","required":"","rules":_vm.rules.title_val,"error-messages":_vm.errors.title_val,"prepend-icon":"notes","label":_vm.$t('Nombre en valenciano para el título propio'),"placeholder":_vm.$t('Nombre en valenciano'),"disabled":_vm.isCurrentMasterDisabled},on:{"input":_vm.setModified},model:{value:(_vm.localState.title_val),callback:function ($$v) {_vm.$set(_vm.localState, "title_val", $$v)},expression:"localState.title_val"}}):_vm._e(),_c('select-teacher',{attrs:{"required":"","prepend-icon":"person_outline","label":_vm.$t(
                            'Profesor/a UV solicitante del título propio (UV)'
                        ),"api-function":_vm.api.teacher.getTeachersUV,"disabled":_vm.imRoleTeacher || _vm.isCurrentMasterDisabled},on:{"change":_vm.handleResponsible,"input":_vm.setModified},model:{value:(_vm.localState.teacher_responsible),callback:function ($$v) {_vm.$set(_vm.localState, "teacher_responsible", $$v)},expression:"localState.teacher_responsible"}}),_c('v-card',{staticClass:"mx-auto",attrs:{"tile":""}},[_c('v-list',{attrs:{"three-line":""}},[_c('v-subheader',[_c('v-avatar',[_c('v-icon',[_vm._v("people_outline")])],1),_vm._v(" "+_vm._s(_vm.$t( 'Directores o Directoras Universitat de València' ))+" "),_c('v-btn',{staticClass:"ml-12",attrs:{"color":"success","dark":"","disabled":_vm.isCurrentMasterDisabled},on:{"click":function($event){return _vm.newUVDirector()}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("add_circle")]),_vm._v(" "+_vm._s(_vm.$t('Añadir director o directora'))+" ")],1)],1),_c('draggable',{ref:"directors-uv-list",attrs:{"move":_vm.onUVMove},on:{"end":_vm.onUVOrderChanged}},[_vm._l((_vm.directors_uv),function(director,index){return [_c('v-divider',{key:index,attrs:{"inset":index === 0 ? false : true}}),_c('teacher-list-item',{key:director.id,ref:`director-uv-${director.id}`,refInFor:true,attrs:{"teacherIdsNoDelete":[
                                        _vm.localState.teacher_responsible_id,
                                    ],"delete-icon":true,"draggable":true,"teacher":director},on:{"delete":_vm.deleteUvDirector}})]})],2)],1)],1),_c('v-card',{staticClass:"mt-4 mx-auto",attrs:{"tile":""}},[_c('v-list',{attrs:{"three-line":""}},[_c('v-subheader',[_c('v-avatar',[_c('v-icon',[_vm._v("people")])],1),_vm._v(" "+_vm._s(_vm.$t( 'Directores o Directoras Externos/as (si los hubiera)' ))+" "),_c('v-btn',{staticClass:"ml-12",attrs:{"color":"success","dark":"","disabled":_vm.isCurrentMasterDisabled},on:{"click":function($event){return _vm.newExtDirector()}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("add_circle")]),_vm._v(" "+_vm._s(_vm.$t( 'Añadir director o directora externo/a' ))+" ")],1)],1),_vm._l((_vm.directors_ext),function(director,index){return [_c('v-divider',{key:index,attrs:{"inset":index === 0 ? false : true}}),_c('teacher-list-item',{key:director.id,attrs:{"delete-icon":true,"teacher":director},on:{"delete":_vm.deleteExtDirector}})]})],2)],1),_c('v-row',{attrs:{"justify":"left"}},[_c('v-col',{attrs:{"xs":"3","sm":"3"}},[_c('v-text-field',{attrs:{"disabled":!(_vm.imRoleAdmin || _vm.imRoleTechnic),"small":"","label":_vm.$t('Código UV del título'),"placeholder":_vm.$t('Código UV del título')},on:{"input":_vm.setModified},model:{value:(_vm.localState.code),callback:function ($$v) {_vm.$set(_vm.localState, "code", $$v)},expression:"localState.code"}})],1),_c('v-col',{attrs:{"xs":"6","sm":"6"}},[(
                                (_vm.imRoleTechnic || _vm.imRoleAdmin) &&
                                _vm.localState.master_kind_id ===
                                    _vm.MASTER_KIND.DEFAULT
                            )?_c('v-btn',{staticClass:"ms-4",attrs:{"color":"primary_buttons","disabled":!_vm.valid || _vm.isCurrentMasterDisabled},on:{"click":function($event){return _vm.convert()}}},[_vm._v(" "+_vm._s(_vm.$t('Convertir a formación contínua'))+" ")]):_vm._e()],1),_c('v-col',{attrs:{"xs":"3","sm":"3"}},[(
                                (_vm.imRoleTechnic || _vm.imRoleAdmin) &&
                                _vm.localState.master_parent_id != null
                            )?_c('v-btn',{staticClass:"ms-4",attrs:{"color":"warning","disabled":!_vm.valid || _vm.isCurrentMasterDisabled},on:{"click":_vm.showRemoveParentUI}},[_vm._v(" "+_vm._s(_vm.$t('Desvincular'))+" ")]):_vm._e()],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":true,"max-width":"600"},model:{value:(_vm.openDirectorUI),callback:function ($$v) {_vm.openDirectorUI=$$v},expression:"openDirectorUI"}},[_c('director-master',{attrs:{"director-type":_vm.newDirectorType,"directors":_vm.newDirectors},on:{"saved":_vm.addDirector,"exit":function($event){_vm.openDirectorUI = false}},model:{value:(_vm.openDirectorUI),callback:function ($$v) {_vm.openDirectorUI=$$v},expression:"openDirectorUI"}})],1),_c('confirm-dialog',{attrs:{"text":_vm.$t(
                    'Estás seguro/a de eliminar la referencia al título vinculado(padre)?'
                )},on:{"delete":_vm.unlink,"cancel":_vm.cancelRemoveParent},model:{value:(_vm.removeParentUI),callback:function ($$v) {_vm.removeParentUI=$$v},expression:"removeParentUI"}}),_c('save-cancel',{attrs:{"cancel-changes":_vm.cancelChanges,"pending-changes":_vm.pendingChanges,"save":_vm.save,"valid":_vm.valid}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }