
import Vue from 'vue';

export default Vue.extend({
    name: 'NumericField',
    props: {
        value: {
            type: Number,
            default: null
        },
        allowDecimals: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            model: this.value
        };
    },
    methods: {
        removeCommas(evt: any) {
            let keyCode = evt.keyCode ? evt.keyCode : evt.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 44) {
                // 44 is comma
                evt.preventDefault();
            }
        }
    },
    watch: {
        value(newValue, oldValue) {
            if (newValue === '') {
                // @ts-ignore
                this.model = null;
            } else {
                this.model = newValue;
            }
        }
    }
});
