var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',[_c('v-window',{staticClass:"mb-6",model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-window-item',{attrs:{"value":1}},[_c('teacher-subject-edit',{attrs:{"edit-step":_vm.editStep,"subject":_vm.subject,"teacher":_vm.getTeacherForEdit,"teacherSubject":_vm.teacherSubject,"teachersInSubject":_vm.teachersInSubject,"is-new":_vm.isNew},on:{"input":function($event){return _vm.$emit('input')},"created":function($event){_vm.$emit('created');
                    this.localNewTeacher = null;},"new-teacher":function($event){_vm.step = 2},"edit-teacher":function($event){_vm.editTeacher = _vm.teacher;
                    _vm.step = 3;},"new-teacher-uv":function($event){_vm.step = 4},"edit-teacher-uv":function($event){_vm.editTeacher = _vm.teacher;
                    _vm.step = 5;}},model:{value:(_vm.openTeacherUI),callback:function ($$v) {_vm.openTeacherUI=$$v},expression:"openTeacherUI"}})],1),_c('v-window-item',{attrs:{"value":2}},[_c('teacher-edit',{attrs:{"isNew":true,"teacher":null},on:{"updated":function($event){_vm.step = 1},"created":function($event){_vm.step = 1},"cancel":function($event){_vm.step = 1},"exit":function($event){_vm.step = 1}}})],1),_c('v-window-item',{attrs:{"value":3}},[_c('teacher-edit',{attrs:{"isNew":false,"teacher":_vm.editTeacher},on:{"updated":function($event){_vm.step = 1;
                    _vm.updateTeacher();
                    _vm.clearEditTeacher();},"created":function($event){_vm.step = 1;
                    _vm.clearEditTeacher();},"cancel":function($event){_vm.step = 1;
                    _vm.clearEditTeacher();},"exit":function($event){_vm.step = 1;
                    _vm.clearEditTeacher();}}})],1),_c('v-window-item',{attrs:{"value":4}},[_c('teacher-edit-uv',{attrs:{"isNew":true,"teacher":null},on:{"updated":function($event){_vm.step = 1},"created":function($event){_vm.step = 1},"cancel":function($event){_vm.step = 1},"exit":function($event){_vm.step = 1}}})],1),_c('v-window-item',{attrs:{"value":5}},[_c('teacher-edit-uv',{attrs:{"isNew":false,"teacher":_vm.editTeacher},on:{"updated":function($event){_vm.step = 1;
                    _vm.updateTeacher();
                    _vm.clearEditTeacher();},"created":function($event){_vm.step = 1;
                    _vm.clearEditTeacher();},"cancel":function($event){_vm.step = 1;
                    _vm.clearEditTeacher();},"exit":function($event){_vm.step = 1;
                    _vm.clearEditTeacher();}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }