
import Vue from 'vue';
import MasterNew from './MasterNew.vue';
import MasterChildNew from './MasterChildNew.vue';
import {IMaster} from '../interfaces/master/interfaces/models/IMaster';
import {MASTER_KIND} from '@/helpers';
import {mapGetters} from 'vuex';

export default Vue.extend({
    name: 'HomeMastersUv',
    components: { MasterNew, MasterChildNew },
    computed: {
        ...mapGetters({
            config: 'config/get',
        }),
        allowCreateMasterDefault() {
            return this.createMasterAllowed;
        },
        allowCreateMasterContinuousLearning() {
            return this.createMasterContinuousLearningAllowed;
        },
    },
    data() {
        return {
            showNew: false,
            showNewChild: false,
            showImport: false,
            newMasterKind: 1,
            MASTER_KIND: MASTER_KIND,
        };
    },
    methods: {
        successMasterCreated(master: IMaster) {
            // reads master created from globalState
            const masterId = this.$store.getters['master/getData'].id;
            this.$router.push(`/master/${masterId}`);
        },
    },
});
