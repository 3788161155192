// @ts-ignore
import axios, {AxiosPromise} from 'axios';
// @ts-ignore
import URI from 'urijs';
import {IMaster} from '../interfaces/master/interfaces/models/IMaster';
import {ISubject} from '../interfaces/subject/interfaces/models/ISubject';
import {ITeacherSubject} from '../../../server/src/modules/subject/interfaces';
import {IFee} from '../interfaces/master/interfaces/models/IFee';

export const master = {
    /**
     * Create a master with minimal data
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async create(master: IMaster) {
        const url = new URI(`${process.env.VUE_APP_API_URL}masters/`);

        return axios.post(url.valueOf(), master) as Promise<any>;
    },
    /**
     * Create a master copying data from other master
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async generateAll(masterIds: number[]) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/generate-all-pdf`
        );

        return axios.post(url.valueOf(), {ids: masterIds}) as Promise<any>;
    },
    /**
     * Create a master copying data from other master
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async clone(masterId: number, academicYearId: number) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${masterId}/clone`
        );

        url.addQuery({ academicYearId });

        return axios.post(url.valueOf()) as Promise<any>;
    },
    /**
     * Updates master with complete data
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async update(master: IMaster) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${master.id}`
        );

        return axios.put(url.valueOf(), master) as Promise<any>;
    },
    /**
     * Create a master with minimal data
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async get(masterId: number) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${masterId}`
        );

        return axios.get(url.valueOf()) as Promise<any>;
    },
    /**
     * Deletes a master
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async delete(master: IMaster) {
        const masterId = master.id;

        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${masterId}`
        );

        return axios.delete(url.valueOf()) as Promise<any>;
    },
    /**
     * Gets list of all masters wich user has permissions
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async getAll(query: object) {
        const url = this.getMastersUrl(query);

        return axios.get(url.valueOf()) as Promise<any>;
    },
    getMastersUrl(query: object) {
        query = {...query, groupParentChilds: false}
        const url = new URI(`${process.env.VUE_APP_API_URL}masters`);

        if (query != null) {
            for (const [key, value] of Object.entries(query)) {
                url.addQuery({ [key]: value });
            }
        }

        return url
    },
    /**
     * Get enumerates objects related to master object
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async getEnumerates() {
        const url = new URI(`${process.env.VUE_APP_API_URL}masters/enumerates`);

        return axios.get(url.valueOf()) as Promise<any>;
    },
    /**
     * Get enumerates objects related to master object
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async getProposalOrganizations(params: { type: string; filter: string }) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/proposal_organizations`
        );

        if (params.filter) {
            url.addQuery({ filter: params.filter });
        }

        if (params.type) {
            url.addQuery({ type: params.type });
        }
        return axios.get(url.valueOf()) as Promise<any>;
    },
    /**
     * Create a subject with minimal data
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async createSubject(subject: ISubject, master: IMaster) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${master.id}/subject`
        );

        return axios.post(url.valueOf(), subject) as Promise<any>;
    },
    /**
     * Updates subject with complete data
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async updateSubject(subject: ISubject, master: IMaster) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${master.id}/subject/${subject.id}`
        );

        return axios.put(url.valueOf(), subject) as Promise<any>;
    },
    /**
     * Updates order in each subject in subjects array
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async updateSubjectsOrder(subjects: ISubject[], masterId: number) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${masterId}/subject-orders`
        );

        return axios.put(url.valueOf(), subjects) as Promise<any>;
    },
    /**
     * Create a fee with minimal data
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async createFee(fee: IFee, master: IMaster) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${master.id}/fee`
        );

        return axios.post(url.valueOf(), fee) as Promise<any>;
    },
    /**
     * Updates fee with complete data
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async updateFee(fee: IFee, master: IMaster) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${master.id}/fee/${fee.id}`
        );

        return axios.put(url.valueOf(), fee) as Promise<any>;
    },
    /**
     * Deletes a fee
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async deleteFee(fee: IFee, master: IMaster) {
        const masterId = master.id;
        const feeId = fee.id;

        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${masterId}/fee/${feeId}`
        );

        return axios.delete(url.valueOf()) as Promise<any>;
    },
    /**
     * Link subjects to child master
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async linkSubjects(subjects: ISubject[], master: IMaster) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${master.id}/link_subjects`
        );

        return axios.put(url.valueOf(), subjects) as Promise<any>;
    },
    /**
     * Unlink subject from child master
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async unlinkSubject(subject: ISubject, master: IMaster) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${master.id}/unlink_subjects/${master.id}`
        );

        return axios.put(url.valueOf()) as Promise<any>;
    },
    /**
     * Create a teacher-subject association
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async createTeacherSubject(
        masterId: number,
        teacherSubject: ITeacherSubject
    ) {
        const subjectId = teacherSubject.subject.id;

        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${masterId}/subject/${subjectId}/teacher`
        );

        return axios.post(url.valueOf(), teacherSubject) as Promise<any>;
    },
    /**
     * Deletes a teacher-subject association
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async deleteTeacherSubject(
        masterId: number,
        teacherSubject: ITeacherSubject
    ) {
        const teacherId = teacherSubject.teacher_id;
        const subjectId = teacherSubject.subject_id;

        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${masterId}/subject/${subjectId}/teacher/${teacherId}`
        );

        return axios.delete(url.valueOf()) as Promise<any>;
    },
    /**
     * Updates a teacher-subject association
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async updateTeacherSubject(
        masterId: number,
        teacherSubject: ITeacherSubject
    ) {
        const teacherId = teacherSubject.teacher_id;
        const subjectId = teacherSubject.subject_id;

        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${masterId}/subject/${subjectId}/teacher/${teacherId}`
        );

        return axios.put(url.valueOf(), teacherSubject) as Promise<any>;
    },
    /**
     * Deletes a subject
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async deleteSubject(subject: ISubject, master: IMaster) {
        const masterId = master.id;
        const subjectId = subject.id;

        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${masterId}/subject/${subjectId}`
        );

        return axios.delete(url.valueOf()) as Promise<any>;
    },
    /**
     * Upload a master file
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async uploadFile(masterId: number, masterFileType: string, file: File) {
        let formData = new FormData(); // eslint-disable-line prefer-const

        formData.append('file', file);

        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${masterId}/upload/${masterFileType}/`
        );

        return axios.post(url.valueOf(), formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }) as Promise<any>;
    },
    /**
     * Deletes a master file
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async deleteFile(masterId: number, masterFileType: string) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${masterId}/file/${masterFileType}`
        );

        return axios.delete(url.valueOf()) as Promise<any>;
    },
    async exportToSigue(masterId: number) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${masterId}/export-to-sigue`
        );

        return axios.post(url.valueOf(), {}, {timeout: 60 * 10 * 1000}) as Promise<any>;
    },
    async getPdf(masterId: number) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${masterId}/to-html`
        );

        return axios.get(url.valueOf()) as Promise<any>;
    },
    /**
     * Publish a master
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async publish(masterId: number) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${masterId}/publish`
        );

        return axios.put(url.valueOf()) as Promise<any>;
    },
    /**
     * Publish a master
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async review(masterId: number) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${masterId}/review`
        );

        return axios.put(url.valueOf()) as Promise<any>;
    },
    /**
     * Publish a master
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async correct(masterId: number) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${masterId}/correct`
        );

        return axios.put(url.valueOf()) as Promise<any>;
    },
    async archive(masterId: number) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${masterId}/archive`
        );

        return axios.put(url.valueOf()) as Promise<any>;
    },
    async draft(masterId: number) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${masterId}/draft`
        );

        return axios.put(url.valueOf()) as Promise<any>;
    },
    async approve(masterId: number) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${masterId}/approve`
        );

        return axios.put(url.valueOf()) as Promise<any>;
    },
    generateModel83(masterId: number) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${masterId}/generate-model-responsible`
        );

        return axios({method: 'post', url: url.valueOf(), responseType: 'blob'}) as Promise<any>;

    },
    /**
     * Updates order in each director in directors-master
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async updateDirectorsOrder(directors: ISubject[], masterId: number) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}masters/${masterId}/directors-order`
        );

        return axios.put(url.valueOf(), directors) as Promise<any>;
    },
};
