
import Vue from 'vue';
import Login from '@/components/Login.vue';
import Component from 'vue-class-component';

@Component({
    components: {
        Login
    },
    props: {
      fromResetPassword: Boolean
    },
})
export default class LoginView extends Vue {}
