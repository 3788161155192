
import 'devextreme/dist/css/dx.light.css';
import {
  DxColumn,
  DxColumnChooser,
  DxColumnFixing,
  DxDataGrid,
  DxExport,
  DxFilterRow,
  DxHeaderFilter,
  DxMasterDetail,
  DxPager,
  DxPaging,
  DxScrolling,
  DxSearchPanel,
  DxSorting,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import DxButton from 'devextreme-vue/button';
import DetailTemplate from './TeachersTotalHoursDetail.vue';
import {createStore} from 'devextreme-aspnet-data-nojquery';
import Vue from 'vue';

const teachersHoursData = createStore({
    key: 'id',
    loadUrl: `${process.env.VUE_APP_API_URL}teachers/all-teachers-hours`,
});

export default Vue.extend({
    name: 'TeachersTotalHours',
    components: {
        DxDataGrid,
        DxExport,
        DxColumn,
        DxColumnChooser,
        DxColumnFixing,
        DxSorting,
        DxFilterRow,
        DxHeaderFilter,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxPaging,
        DxButton,
        DxStateStoring,
        DxMasterDetail,
        DetailTemplate,
    },
    data() {
        return {
            teachersHoursData,
            pageSizes: [10, 25, 50, 100],
            pageSize: 100,
            pageIndex: 0,
            filterStatusValues: [],
        };
    },
    computed: {
        grid(): DxDataGrid {
            return this.$refs['grid-teachers-hours'];
        },
    },
    methods: {
        getTeacherFullName(rowData) {
            if (rowData.teacher == null) {
                return '';
            }
            return `${rowData?.teacher.name || ''} ${
                rowData?.teacher.surname || ''
            }`;
        },
        translate(cellInfo) {
            if (cellInfo.value == null) {
                return '';
            }
            return this.$i18n.t(cellInfo.value);
        },
        refreshData() {
            teachersHoursData.load();
        },
    },
});
