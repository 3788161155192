// @ts-ignore
import axios, {AxiosPromise} from 'axios';
// @ts-ignore
import URI from 'urijs';

export const tenant = {
    /**
     * Get tenants
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async getAll() : Promise<any> {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}tenant/`
        );

        return axios.get(url.valueOf()) as Promise<any>;
    },
    /**
     * Activates deadline
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async getCurrentTenant() : Promise<any> {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}tenant/current_tenant`
        );

        return axios.get(url.valueOf()) as Promise<any>;
    },
};
