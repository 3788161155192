
import Vue from 'vue';
import {mapGetters} from 'vuex';

export default Vue.extend({
    name: 'SummaryGuide',
    computed: {
        ...mapGetters({
            formError: 'master/getError',
            stateItems: 'master/getSummaryGuide',
            master: 'master/getData',
            masterTypes: 'master/getMasterTypes'
        }),
        masterTypeName(): string {
            if (this.master.master_type_code != null) {
                return this.masterTypes
                    .find(mt => mt.code === this.master.master_type_code)
                    .name.toUpperCase();
            }

            return '';
        },
        items() {
            let list: any[] = [];

            this.stateItems.forEach((item: any) => {
                if (item.title.toUpperCase() !== 'TOTAL') {
                    const years = Object.keys(item.years).length;

                    Object.keys(item.years).forEach(year =>
                        list.push({
                            title:
                                years > 1
                                    ? item.title + this.$i18n.t(' Año ') + year
                                    : item.title,
                            ects: item.years[year].ects,
                            hours: item.years[year].hours
                        })
                    );
                }
            });

            return list;
        },
        totals() {
            return this.stateItems.filter((element: any) => {
                return element.title.toUpperCase() === 'TOTAL';
            });
        }
    },
    data() {
        return {
            headers: [
                {
                    text: '',
                    align: 'end',
                    value: 'title',
                    sortable: false
                },
                {
                    text: this.$i18n.t('ECTS'),
                    align: 'center',
                    sortable: true,
                    value: 'ects'
                },
                {
                    text: this.$i18n.t('Horas'),
                    align: 'center',
                    sortable: true,
                    value: 'hours'
                }
            ]
        };
    }
});
