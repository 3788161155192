/*
 *  Copyright 2017-2018 Enrique Araque Vilches
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */

// @ts-ignore
import * as api from '@/api/index';

// @ts-ignore
import * as mutation_types from '@/vuex/mutation_types';
import {ActionTree} from 'vuex';
import {RootState} from '../../types';
// @ts-ignore
import {IMaster} from '../../../interfaces/master/interfaces/models/IMaster';
import {ConfigState} from './types';
import {AxiosResponse} from 'axios';
import {IConfig} from "@/interfaces/config/interfaces/models/IConfig";

export const actions: ActionTree<ConfigState, RootState> = {
    get: async function({ commit }): Promise<any> {
        try {
            commit(mutation_types.CLEAR_DATA);

            const result: AxiosResponse = await api.config.get();

            commit(mutation_types.LOAD_DATA, result.data as IConfig);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    set: async function({ commit }, config): Promise<any> {
        try {
            commit(mutation_types.LOAD_DATA, config as IConfig);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    update: async function({ commit }, config): Promise<any> {
        try {
            const result: AxiosResponse = await api.config.update(config);

            commit(mutation_types.LOAD_DATA, result.data as IConfig);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    updateAcademicYear: async function({ commit }, academicYear): Promise<any> {
        try {
            await api.enumerates.updateAcademicYear(academicYear);
            //commit(mutation_types.LOAD_DATA_ACADEMIC_YEARS, result.data as IConfig);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    activateDeadLine: async function({ commit }): Promise<any> {
        try {
            const result: AxiosResponse = await api.config.activate_deadline();

            commit(mutation_types.LOAD_DATA, result.data as IConfig);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    deActivateDeadLine: async function({ commit }): Promise<any> {
        try {
            const result: AxiosResponse = await api.config.deactivate_deadline();

            commit(mutation_types.LOAD_DATA, result.data as IConfig);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
    clearError: async function({ commit }): Promise<any> {
        commit(mutation_types.CLEAR_ERROR, undefined);
    },
    getAcademicYearsPerms: async function ({ commit }): Promise<any> {
        try {
            commit(mutation_types.CLEAR_ACADEMIC_YEARS_DATA);

            const result: AxiosResponse = await api.config.getAcademicYearsUserCreateMasterPerms();

            commit(mutation_types.LOAD_ACADEMIC_YEARS_DATA, result.data as IMaster);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    },
};
