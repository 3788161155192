import Vue from 'vue';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import i18n from './lang';

// @ts-ignore
import store from '@/vuex/store';
import 'material-design-icons-iconfont';
// @ts-ignore
import {authentication, form} from '@/components/mixins';

import {VTextField} from 'vuetify/lib';

//@ts-ignore
import VueSSE from 'vue-sse';
// @ts-ignore
import VCurrencyField from 'v-currency-field';

Vue.use(VueSSE);

Vue.component('v-text-field', VTextField);

Vue.use(VCurrencyField, {
    locale: 'pt-BR',
    decimalLength: 2,
    autoDecimalMode: true,
    min: null,
    max: null,
    defaultValue: 0
});



Vue.config.productionTip = false;

Vue.mixin(authentication);

Vue.config.errorHandler = function(err, vm, info) {
    // handle error
    // `info` is a Vue-specific error info, e.g. which lifecycle hook
    // the error was found in. Only available in 2.2.0+
    // @ts-ignore
    console.error(err);
};

new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');
