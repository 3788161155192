/*
 *  Copyright 2017-2018 Enrique Araque Vilches
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 *   Unless required by applicable law or agreed to in writing, software
 *   distributed under the License is distributed on an "AS IS" BASIS,
 *   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *   See the License for the specific language governing permissions and
 *   limitations under the License.
 */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import esLocale from './es';
import enLocale from './en';
import vaLocale from './va';

Vue.use(VueI18n);

const messages = {
    es: esLocale,
    'es-ES': esLocale,
    en: enLocale,
    'en-US': enLocale,
    va: vaLocale
};

const i18n = new VueI18n({
    locale: 'va',
    fallbackLocale: 'es',
    messages
});

export default i18n;
