
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
import * as _ from 'lodash';
import {IFee} from '../../interfaces/master/interfaces/models/IFee';
import FeeForm from './FeeForm.vue';
import DeleteDialog from './DeleteDialog.vue';

export default Vue.extend({
    name: 'FeeList',
    components: {
        FeeForm,
        DeleteDialog
    },
    props: {
        enableEdit: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapGetters({
            formError: 'master/getError',
            fees: 'master/getFees',
            master: 'master/getData'
        }),
        totalFee() {
            if (this.fees == null || this.fees.length == 0) {
              return 0;
            }

            return this.fees.reduce((accum: number, current: IFee) => {
                return accum + current.min_students * current.enrollment_price;
            }, 0);
        }
    },
    data() {
        return {
            headers: [
                {
                    text: '',
                    align: 'left',
                    width: '120',
                    sortable: false,
                    value: 'actions'
                },
                {
                    text: this.$i18n.t('Público'),
                    align: 'left',
                    width: '300',
                    sortable: true,
                    value: 'target_audience'
                },
                {
                    text: this.$i18n.t('Mín. Estudiantes'),
                    align: 'center',
                    sortable: true,
                    value: 'min_students'
                },
                {
                    text: this.$i18n.t('Matrícula ') + '€',
                    align: 'center',
                    sortable: true,
                    value: 'enrollment_price'
                },
                {
                    text: this.$i18n.t('Total ') + '€',
                    align: 'center',
                    sortable: true,
                    value: 'total'
                }
            ],
            currentFee: {},
            openFeeUI: false,
            isNewFee: false,
            deleteFeeUI: false
        };
    },
    methods: {
        ...mapActions('master', ['deleteFee', 'clearError', 'clearFee']),
        closeFeeUI() {
          this.openFeeUI = false;
          Vue.set(this, "currentFee", {})
        },
        isDefaultFee(fee: IFee): boolean {
          //TODO: Make a property "default_fee" in server entity
          return fee.target_audience == this.$i18n.t(process.env.VUE_APP_FEE_DEFAULT_TITLE);
        },
        editFee(fee: IFee) {
            Vue.set(this, "currentFee", {})
            Vue.set(this, "currentFee", _.cloneDeep(fee));

            this.openFeeUI = true;
            this.isNewFee = true;
          this.isNewFee = false;

        },
        newFee() {
            Vue.set(this, "currentFee", {})

            if (this.fees == null || this.fees.length == 0) {
              this.currentFee = {
                target_audience: this.$i18n.t(process.env.VUE_APP_FEE_DEFAULT_TITLE)
              };
            }

            this.openFeeUI = true;
            this.isNewFee = true;
        },
        cancelDeleteFee() {
            Vue.set(this, "currentFee", {})

            this.deleteFeeUI = false;
        },
        deleteFeeDialog(fee: IFee) {
            this.currentFee = _.cloneDeep(fee);
            this.deleteFeeUI = true;
        },
        async deleteCurrentFee() {
            try {
                this.deleteFeeUI = false;
                // @ts-ignore
                await this.deleteFee({
                    fee: this.currentFee,
                    master: this.master
                });

                Vue.set(this, "currentFee", {});
                this.$emit('deleted');
            } catch (err) {
                // avoid uncaught promise
            }
        }
    }
});
