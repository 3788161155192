
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
import {ProposalOrganizationType} from '../../../../server/src/modules/master/proposal_organization_type.entity';
import {ICenter} from '../../interfaces/teacher/interfaces/models/ICenter';
import {ISubject} from '../../interfaces/subject/interfaces/models/ISubject';
import * as helpers from '@/helpers/index';
import NumericField from '../common/NumericField.vue';
import {form} from '@/components/mixins';
import SaveCancel from '../common/SaveCancel.vue';
import FeeList from '../common/FeeList.vue';
import {MASTER_KIND, TEACHING_TYPE} from '../../helpers';

export default Vue.extend({
    name: 'BasicData',
    components: {
        FeeList,
        NumericField,
        SaveCancel,
    },
    mixins: [form],
    props: {
        editStep: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            errors: {
                proposal_organization_type: [],
                proposal_organization: [],
                proposal_organization_type_extra: [],
                proposal_organization_extra: [],
                pre_registration_end_date: [],
                teaching_type: [],
                knowledge_branch: [],
                lang: [],
                elearning_services: [],
                nivel_meces_eqf: [],
            },
            rules: {
                proposal_organization_type: [
                    (v: ProposalOrganizationType) =>
                        !!v || this.$i18n.t('El tipo de órgano es requerido'),
                ],
                proposal_organization: [
                    (v: ICenter) =>
                        !!v || this.$i18n.t('El órgano es requerido'),
                ],
                proposal_organization_type_extra: [],
                proposal_organization_extra: [],
                pre_registration_end_date: [
                    (v: string) => !!v || this.$i18n.t('La fecha es requerida'),
                ],
                teaching_type: [],
                knowledge_branch: [],
                lang: [],
                nivel_meces_eqf: [],
                max_students: [
                    // @ts-ignore
                    (v: number) => {
                        if (
                            v &&
                            this.localState.min_students &&
                            Number(v) < Number(this.localState.min_students)
                        ) {
                            return this.$i18n.t(
                                'No puede ser menor que el número mínimo de estudiantes'
                            );
                        }

                        return true;
                    },
                ],
                elearning_services: [],
            },
            dataModel: {
                proposal_organization_type: null,
                proposal_center: null,
                proposal_department: null,
                proposal_organization_type_extra: null,
                proposal_center_extra: null,
                proposal_department_extra: null,
                pre_registration_end_date: null,
                teaching_type: null,
                knowledge_branch: null,
                lang: null,
                max_students: null,
                elearning_services: null,
                nivel_meces_eqf: null,
            },
            proposalOrganization: null,
            proposalOrganizationExtra: null,
            menuDate: false,
            teaching_dates: '',
            showLangAlert: false,
            localState: {
                proposal_organization_type: null,
                proposal_center: null,
                proposal_department: null,
                proposal_organization_type_extra: null,
                proposal_center_extra: null,
                proposal_department_extra: null,
                pre_registration_end_date: null,
                teaching_type: null,
                knowledge_branch: null,
                lang: null,
                edition_number: null,
                max_studtents: null,
                master_kind_id: null,
                elearning_services: null,
                nivel_meces_eqf: null,
                master_type_code: null,
            },
            MASTER_KIND: MASTER_KIND,
            TEACHING_TYPE: TEACHING_TYPE,
        };
    },
    computed: {
        ...mapGetters({
            formError: 'master/getError',
            globalState: 'master/getData',
            proposalOrganizationTypes: 'master/getProposalOrganizationTypes',
            centers: 'master/getCenters',
            research_institutes: 'master/getResearchInstitutes',
            departments: 'master/getDepartments',
            teachingTypes: 'master/getTeachingTypes',
            langs: 'master/getLangs',
            firstAvailableTeachingDate: 'master/getFirstAvailableTeachingDate',
            currentTenant: 'tenant/getCurrentTenant',
            tenants: 'tenant/getTenants',
        }),
      proposalOrganizations(): object[] {
            if (!this.localState.proposal_organization_type) {
                return [];
            }

            switch (this.localState.proposal_organization_type.code) {
                case 'cen':
                    return this.centers;
                case 'ins':
                    return this.research_institutes;
                case 'dep':
                    return this.departments;
                default:
                    return [];
            }
        },
        proposalOrganizationsExtra(): object[] {
            if (!this.localState.proposal_organization_type_extra) {
                return [];
            }

            switch (this.localState.proposal_organization_type_extra.code) {
                case 'cen':
                    return this.centers;
                case 'ins':
                    return this.research_institutes;
                case 'dep':
                    return this.departments;
                default:
                    return [];
            }
        },
        computedDateFormatted() {
            return helpers.formatDate(
                this.localState.pre_registration_end_date
            );
        },
        teachingStartEndDate() {
            const subjects = this.globalState.subjects;

            if (!subjects || subjects.length === 0) {
                return null;
            }

            const dates = subjects.reduce(
                (accum: any, subject: ISubject) => {
                    if (
                        subject.teaching_start_date < accum.teaching_start_date
                    ) {
                        accum.teaching_start_date = subject.teaching_start_date;
                    }

                    if (subject.teaching_end_date > accum.teaching_end_date) {
                        accum.teaching_end_date = subject.teaching_end_date;
                    }

                    return accum;
                },
                {
                    teaching_start_date: subjects[0].teaching_start_date,
                    teaching_end_date: subjects[0].teaching_end_date,
                }
            );

            return (
                helpers.formatDate(dates.teaching_start_date) +
                ' / ' +
                helpers.formatDate(dates.teaching_end_date)
            );
        },
    },
    methods: {
        ...mapActions('master', ['update', 'clearError']),
        forceNumber(evt: any) {
            if (!helpers.isKeyNumber(evt)) {
                evt.preventDefault();
            }
        },
        clearLocalState() {
            this.localState = {
                proposal_organization_type: null,
                proposal_center: null,
                proposal_department: null,
                proposal_organization_type_extra: null,
                proposal_center_extra: null,
                proposal_department_extra: null,
                pre_registration_end_date: null,
                teaching_type: null,
                knowledge_branch: null,
                lang: null,
                max_students: null,
                elearning_services: null,
            };

            this.clearError();

            // @ts-ignore
            this.$refs.form.resetValidation();
        },
        parseGlobalStateAfter() {
            if (this.localState.proposal_center) {
                this.proposalOrganization = this.localState.proposal_center;
            } else {
                if (this.localState.proposal_department) {
                    this.proposalOrganization =
                        this.localState.proposal_department;
                }
            }

            if (this.localState.proposal_center_extra) {
                this.proposalOrganizationExtra =
                    this.localState.proposal_center_extra;
            } else {
                if (this.localState.proposal_department_extra) {
                    this.proposalOrganizationExtra =
                        this.localState.proposal_department_extra;
                }
            }
        },
        parseLocalStateAfter() {
            if (this.localState.proposal_organization_type == null) {
                this.localState.proposal_center = null;
                this.localState.proposal_department = null;
            } else {
                switch (this.localState.proposal_organization_type.code) {
                    case 'cen':
                    case 'ins':
                        this.localState.proposal_center =
                            this.proposalOrganization;
                        this.localState.proposal_department = null;
                        break;
                    case 'dep':
                        this.localState.proposal_center = null;
                        this.localState.proposal_department =
                            this.proposalOrganization;
                        break;
                    default:
                        return [];
                }

                this.localState.proposal_organization_type.name = this.$i18n.t(
                    this.localState.proposal_organization_type.name
                );
            }

            // Can be null
            if (this.localState.proposal_organization_type_extra == null) {
                this.localState.proposal_center_extra = null;
                this.localState.proposal_department_extra = null;
            } else {
                switch (this.localState.proposal_organization_type_extra.code) {
                    case 'cen':
                    case 'ins':
                        this.localState.proposal_center_extra =
                            this.proposalOrganizationExtra;
                        this.localState.proposal_department_extra = null;
                        break;
                    case 'dep':
                        this.localState.proposal_center_extra = null;
                        this.localState.proposal_department_extra =
                            this.proposalOrganizationExtra;
                        break;
                    default:
                        return [];
                }

                this.localState.proposal_organization_type_extra.name =
                    this.$i18n.t(
                        this.localState.proposal_organization_type_extra.name
                    );
            }
        },
    },
    watch: {
        'localState.teaching_type': {
            handler() {
                if (
                    this.localState.teaching_type.code ==
                    TEACHING_TYPE.FACE_TO_FACE
                ) {
                    this.localState.elearning_services = false;
                }
            },
            deep: true,
        },
    },
});
