// @ts-ignore
import axios, {AxiosPromise} from 'axios';
// @ts-ignore
import URI from 'urijs';
import {IAcademicYear} from "@/interfaces/master/interfaces/models/IAcademicYear";

export const enumerates = {
    /**
     * Updates academicYear
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async updateAcademicYear(academicYear: IAcademicYear) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}enumerates/academic_year/${academicYear.id}`
        );

        return axios.put(url.valueOf(), academicYear) as Promise<any>;
    }
};
