
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
import {form} from '@/components/mixins';
import * as helpers from '@/helpers/index';
import {EXTERNAL_TEACHER_TYPE, isEmpty, validateDNI} from '../../../helpers';

import * as api from '@/api/index';

export default Vue.extend({
    name: 'TeacherEdit',
    mixins: [form],
    props: {
        isNew: {
            type: Boolean,
            default: false
        },
        teacher: {
            type: Object,
            default: () => null
        },
        reload: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            api: api,
            EXTERNAL_TEACHER_TYPE: EXTERNAL_TEACHER_TYPE,
            errors: {
                name: [],
                surname: [],
                nif: [],
                phone_number: [],
                email: [],
                birth_date: [],
                new_teacher_position: [],
                new_teacher_organization_name: [],
                university: [],
                professional_categories: []
            },
            rules: {
                name: [this.ruleRequired],
                surname: [this.ruleRequired],
                birth_date: [], //,[this.ruleRequired],
                nif: [this.ruleRequired,
                  (value: string) => {
                    return validateDNI(value) || 'El nif no es válido';
                  }
                ],
                phone_number: [this.ruleRequired],
                email: [
                    this.ruleRequired,
                    (value: string) => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || 'El e-mail no es válido';
                    }
                ],
                organization_: [this.ruleRequired],
                new_teacher_organization_name: [this.ruleRequired],
                university: [this.ruleRequired],
                professional_categories: [this.ruleRequired]
            },
            localState: {
                id: null,
                name: null,
                surname: null,
                birth_date: null,
                nif: null,
                phone_number: null,
                new_teacher_organization_name: null,
                new_teacher_position: null,
                email: null,
                university: null,
                professional_category: null,
                is_freelance: null
            },
            menuDate: false,
            external_teacher_type: null,
            universities: [],
            search: null,
            isLoading: false
        };
    },
    mounted() {
        if (!isEmpty(this.teacher)) {
            this.setExternalTeacherType();
        }
    },
    computed: {
        ...mapGetters({
            formError: 'master/getError',
            teachingTypes: 'master/getTeachingTypes',
            master: 'master/getData',
            appConfig: 'app/getConfig',
            professional_categories: 'master/getProfessionalCategories',
        }),
        professional_categories_pdis() {
          if (!this.professional_categories) return [];

          return this.professional_categories
              .filter(pf => pf.relation_uni === 'P.D.I.')
              .sort((a, b) => a.description.localeCompare(b.description));
        },
        computedDateFormatted() {
            return helpers.formatDate(this.localState.birth_date);
        },
        getLabel() {
            if (
                this.external_teacher_type ===
                EXTERNAL_TEACHER_TYPE.UNIVERSITY
            ) {
                return 'Nombre de la Universidad';
            } else {
                return 'Nombre de la empresa';
            }
        },
        getPosition() {
            switch (this.external_teacher_type) {
              case EXTERNAL_TEACHER_TYPE.FREELANCE:
                return this.$i18n.t('Cargo');
                break;
              case EXTERNAL_TEACHER_TYPE.UNIVERSITY:
                return this.$i18n.t('Categoría / Cargo');
                break;
              case EXTERNAL_TEACHER_TYPE.COMPANY:
                return this.$i18n.t('Cargo en la empresa');
                break;
              default:
                return '';
            }
        },
        globalState() {
            if (this.teacher != null && this.teacher.id > 0) {
                return this.teacher;
            }

            return null;
        },
        getUniversityHint() {
          return this.$i18n.t(`Si no encuentras la universidad que buscas, puedes enviar un correo solicitando su creación a`) + ` ${process.env.VUE_APP_SUPPORT_EMAIL}`;
        }
    },
    methods: {
        ...mapActions('master', {
            create: 'createTeacher',
            update: 'updateTeacher',
            clearError: 'clearError'
        }),
        clear() {
            this.external_teacher_type = null;
            this.localState.external_teacher_type = this.external_teacher_type;
        },
        parseGlobalStateAfter(): void {
          Vue.set(this, "universities", [this.globalState.university]);
        },
        parseLocalStateAfter(): void {
            this.localState.external_teacher_type = this.external_teacher_type;
            this.localState.teacher_type_code = 'ext';
            this.external_teacher_type == EXTERNAL_TEACHER_TYPE.FREELANCE ? this.localState.is_freelance = true : false;
        },
        setExternalTeacherType(): void {

          if (this.teacher == null) {
            return;
          }

          if (
              (this.teacher.is_freelance != null)
              || (this.teacher.company_id == null && this.teacher.univ_id == null)
          ) {
            this.external_teacher_type = EXTERNAL_TEACHER_TYPE.FREELANCE;
            return;
          }

          if (this.teacher.company_id != null) {
              this.external_teacher_type = EXTERNAL_TEACHER_TYPE.COMPANY;
              return;
          }

          if (this.teacher.univ_id != null) {
            this.external_teacher_type = EXTERNAL_TEACHER_TYPE.UNIVERSITY;
            return;
          }

        },
        saveAndExit() {
            if (this.$refs.form.validate()) {
                this.createOrUpdate();
                this.exit();
            }
        }
    },
    watch: {
        teacher(newValue, oldValue) {
            if (!this.isNew) {
                this.parseGlobalState();
                this.setExternalTeacherType();
            }
        },
      async search(val: string) {
        // Items have already been loaded
        // if (this.items.length > 0) return;

        if (val === 'undefined' || val == null || val == '') {
          return;
        }

        const setError = (err: any) => {
          this.isLoading = false;
          this.errors.university.push(
              this.$i18n.t(
                  'Se ha producido un error consultando el servidor'
              )
          );
        };

        // Items have already been requested
        if (this.isLoading) return;

        this.isLoading = true;

        try {
          // Lazily load input items
          this.api.teacher.getUniversities(val)
              .then((res: any) => {
                Vue.set(this, "universities", res.data);
                this.isLoading = false;
              })
              .catch((err: any) => {
                setError(err);
              })
              .finally(() => (this.isLoading = false));
        } catch (err) {
          setError(err);
        }
      }
    }
});
