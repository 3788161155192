// @ts-ignore
import axios, {AxiosPromise} from 'axios';
// @ts-ignore
import URI from 'urijs';

export const authentication = {
    /**
     * Get JWT token from server if authentication is successful
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async login(login: string, password: string) {
        // @ts-ignore
        const url = new URI(`${process.env.VUE_APP_API_URL}login`);

        const payload = { login, password };

        return axios.post(url.valueOf(), payload) as Promise<any>;
    },
    /**
     * Get JWT token refreshed from server
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async refresh() {
        // @ts-ignore
        const url = new URI(`${process.env.VUE_APP_API_URL}refresh`);

        return axios.post(url.valueOf()) as Promise<any>;
    },
    async resetPassword(newPassword: string, newPasswordToken: string) {
        // @ts-ignore
        const url = new URI(`${process.env.VUE_APP_API_URL}reset-password`);

        const payload = { newPassword, newPasswordToken };

        return axios.post(url.valueOf(), payload) as Promise<any>;
    },
    async forgottenPassword(email: string) {
        // @ts-ignore
        const url = new URI(`${process.env.VUE_APP_API_URL}forgotten-password`);

        const payload = { email };

        const a = await axios.post(url.valueOf(), payload) as Promise<any>;

        return a;
    },
};
