
import Vue from 'vue';
import TeacherSubjectEdit from './TeacherSubjectEdit.vue';
import TeacherEdit from './TeacherEdit.vue';
import TeacherEditUv from './TeacherEditUV.vue';
import {mapActions, mapGetters} from 'vuex';
import * as _ from 'lodash';
import {ITeacher} from '../../../interfaces/teacher/interfaces/models/ITeacher';

export default Vue.extend({
    name: 'TeacherSubject',
    components: {
        TeacherSubjectEdit,
        TeacherEdit,
        TeacherEditUv
    },
    props: {
        isNew: {
            type: Boolean,
            default: false
        },
        // for new Objects we need the subject object to send to api.
        subject: {
            type: Object,
            default: () => {}
        },
        teacher: {
            type: Object,
            default: () => {}
        },
        teachersInSubject: {
            type: Array,
            default: () => []
        },
        // id for editing existing teacherSubject record
        teacherSubject: {
            type: Object,
            default: () => {}
        },
        editStep: {
            type: Number,
            default: null
        },
        localNewTeacher: {
            type: Object,
            default: () => {}
        },
        changeInactiveUser: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters({
            newTeacher: 'master/getNewTeacher'
        }),
        getTeacherForEdit() {
            return this.newTeacher ? this.localNewTeacher : this.teacher;
        }
    },
    data() {
      /* eslint-disable */
        return {
            _: _,
            step: 1,
            openTeacherUI: false,
            editTeacher: null
        };
      /* eslint-enable */
    },
    methods: {
        ...mapActions('master', {
            create: 'createTeacher',
            clearNewTeacher: 'clearNewTeacher',
            clearError: 'clearError'
        }),
        clearEditTeacher() {
            this.editTeacher = null;
        },
        updateTeacher(teacher: ITeacher) {
            this.$emit('teacher-updated', teacher);
        }
    },
    watch: {
      /* eslint-disable */
        newTeacher(newValue, oldValue) {
            if (newValue == null) {
                this.localNewTeacher = null;
                return null;
            }

            this.localNewTeacher = newValue;
            this.setModified();
        }
      /* eslint-enable */
    }
});
