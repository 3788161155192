export const ROLES = {
    teacher: 'teacher',
    admin: 'admin',
    technic: 'technic'
};

export const AUTH = {
    refrehTokenMinutes: 10
};

export const COLORS = [
    'red',
    'pink',
    'purple',
    'deep-purple',
    'indigo',
    'blue',
    'light-blue',
    'cyan',
    'teal',
    'green',
    'light-green',
    'lime',
    'yellow',
    'amber',
    'orange',
    'deep-orange',
    'brown',
    'blue-grey'
];

export const EXTERNAL_TEACHER_TYPE = {
    UNIVERSITY: 'university',
    COMPANY: 'company',
    FREELANCE: 'freelance',
};

export const TEACHING_TYPE = {
    ONLINE: 'onl',
    FACE_TO_FACE: 'fac',
    SEMI_FACE_TO_FACE: 'sem'
}

export const MASTER_KIND = {
    DEFAULT: 1,
    CONTINUOUS_LEARNING: 2
}

export const MASTER_FILE_TYPE = {
    RESPONSIBLE_ORG_REPORT: {
        code: 'file_responsible_org',
        name: 'Org. Responsible Report',
        description: ''
    },
    CURRICULUM: {
        code: 'file_curriculum_ext_teachers',
        name: 'Curriculums',
        description: 'Curriculumns of all the teachers of a master'
    },
    OTHER: {
        code: 'file_other_docs',
        name: 'Other',
        description:
            "Other relevant information like organization's recomendations"
    },
    MODEL_83: {
        code: 'file_model_83',
        name: 'Other',
        description:
            "Other relevant information like organization's recomendations"
    }
};

