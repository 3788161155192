/*
 *  Copyright 2017-2018 Enrique Araque Vilches
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
import * as mutation_types from '../../mutation_types';
import {MutationTree} from 'vuex';
import {IEnumerates, MasterState} from './types';
import {IMaster} from '../../../interfaces/master/interfaces/models/IMaster';
import {ITeacher} from '../../../interfaces/teacher/interfaces/models/ITeacher';
import Vue from 'vue';
import {ISubject} from '../../../../../server/src/modules/subject/interfaces';
// @ts-ignore
import * as _ from 'lodash';

export const mutations: MutationTree<MasterState> = {
    [mutation_types.LOAD_DATA](state, payload: IMaster) {
        state.error = undefined;

        payload.directors_uv = payload.directors.filter(
            (teacher: ITeacher) => teacher.teacher_type_code === 'uv'
        );

        payload.directors_ext = payload.directors.filter(
            (teacher: ITeacher) => teacher.teacher_type_code === 'ext'
        );

        // @ts-ignore
        Vue.set(state, 'data', payload);
    },
    [mutation_types.CLEAR_DATA](state, payload: IMaster) {
        state.error = undefined;

        Vue.set(state, 'data', {});
    },
    [mutation_types.LOAD_ENUMERATES_DATA](state, payload: IEnumerates) {
        state.error = undefined;
        state.enumerates = Object.assign({}, payload);
    },
    [mutation_types.CREATE_TEACHER](state, payload: ITeacher) {
        Vue.set(state, 'newTeacher', payload);
        //state.data = ({} as IMaster);
    },
    [mutation_types.UPDATE_TEACHER](state, payload: ITeacher) {
        state.data.subjects.forEach((subject: ISubject, i: number) => {
            subject.teachers.forEach((teacher: ITeacher, t: number) => {
                if (teacher.id === payload.id) {
                    const mergedTeacher = _.assign(
                        state.data.subjects[i].teachers[t],
                        payload
                    );
                    Vue.set(state.data.subjects[i].teachers, t, mergedTeacher);
                }
            });
        });
    },
    [mutation_types.CLEAR_NEW_TEACHER](state) {
        Vue.set(state, 'newTeacher', undefined);
    },
    [mutation_types.CLEAR_NEW_FEE](state) {
        Vue.set(state, 'newFee', undefined);
    },
    [mutation_types.LOAD_ERROR](state, error) {
        Vue.set(state, 'error', error);
        //state.data = ({} as IMaster);
    },
    [mutation_types.CLEAR_ERROR](state) {
        Vue.set(state, 'error', undefined);
    }
};
