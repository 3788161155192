export default {
    data: function() {
        return {
            modelWrapped: this.value
        }
    },
    props: {
        value: [Number, String]
    },
    watch: {
        value: {
            immediate: true,
            handler: function(newValue) {
                this.modelWrapped = newValue ?? 0;
            },
            deep: true
        }
    }
}
