
import Vue from 'vue';
// @ts-ignore
import * as api from '@/api/index';
import { mapActions, mapGetters } from 'vuex';
import { IMaster } from '../../interfaces/master/interfaces/models/IMaster';
import DeleteDialog from '@/components/common/DeleteDialog.vue';
import { IAcademicYear } from '../../interfaces/master/interfaces/models/IAcademicYear';
import { MASTER_KIND } from '../../helpers';

export default Vue.extend({
    name: 'MasterList',
    components: {
        DeleteDialog,
    },
    data() {
        return {
            reviewed: false,
            masterIdToSigue: 0,
            masterIdPublish: 0,
            masterIdReview: 0,
            masterIdCorrect: 0,
            masterIdArchive: 0,
            masterIdApprove: 0,
            masterIdDraft: 0,
            overlay: false,
            sigueDialog: false,
            publishDialog: false,
            reviewDialog: false,
            correctDialog: false,
            archiveDialog: false,
            draftDialog: false,
            approveDialog: false,
            showSigueError: false,
            sigueError: null,
            search: '',
            headers: [
                {
                    text: '',
                    align: 'center',
                    sortable: false,
                    value: 'action',
                },
                {
                    text: this.$i18n.t('Título'),
                    align: 'left',
                    sortable: true,
                    value: 'title',
                },
                {
                    // to allow searching for this columns to show always parent-child relations
                    text: this.$i18n.t('Título padre'),
                    value: 'master_parent_title',
                },
                {
                    text: this.$i18n.t('Director'),
                    value: 'director',
                    sortable: true,
                },
                {
                    text: this.$i18n.t('Centro'),
                    value: 'center',
                    sortable: true,
                },
                {
                    text: this.$i18n.t('ECTS'),
                    value: 'ects',
                    sortable: true,
                },
                {
                    text: this.$i18n.t('Tipo'),
                    value: 'master_type',
                    sortable: true,
                },
                {
                    text: this.$i18n.t('F. Fin Preinscripción'),
                    align: 'left',
                    value: 'pre_registration_end_date',
                    sortable: true,
                },
                {
                    text: this.$i18n.t('Estado'),
                    align: 'left',
                    sortable: true,
                    value: 'master_state.name',
                },
                {
                    text: '',
                    value: 'actions',
                    sortable: true,
                    align: 'center',
                },
                {
                    text: this.$i18n.t('Memoria'),
                    value: 'pdf',
                    sortable: false,
                    align: 'center',
                },
            ],
            masters: [],
            deleteMasterUI: false,
            deleteMasterObject: null,
            loading: false,
            groupParentChilds: false,
            academicYear: null as unknown as IAcademicYear,
            pagination: {},
            totalRecords: 0,
            MASTER_KIND: MASTER_KIND,
            awaitingSearch: false,
            filters: {
                master_state_code: [],
            },
        };
    },
    computed: {
        ...mapGetters({
            masterStates: 'master/getMasterStatesObject',
            masterStatesKeyValue: 'master/getMasterStates',
            academicYears: 'master/getAcademicYears',
            tenant_sigue_connect_disabled: 'tenant/sigue_connect_disabled',
            currentTenant: 'tenant/getCurrentTenant',
        }),
    },
    created() {
      this.handle_sigue_header();
    },
    mounted() {
        this.setAcademicYearFilter();
    },
    methods: {
        ...mapActions('master', [
            'update',
            'exportToSigue',
            'publish',
            'review',
            'correct',
            'delete',
            'archive',
            'draft',
            'approve',
        ]),
        getLocalStorageSearch() {
            Vue.set(
                this,
                'search',
                localStorage.getItem('search_masters') != 'null'
                    ? localStorage.getItem('search_masters')
                    : null
            );
        },
        clearFilters() {
            return {
                master_state_code: [],
            };
        },
        setAcademicYearFilter() {
            if (this.academicYears.length == 0) {
                return;
            }

            Vue.set(
                this,
                'academicYear',
                this.academicYears.sort((a: IAcademicYear, b: IAcademicYear) =>
                    b.name.localeCompare(a.name)
                )[0]
            );

            this.getData();
        },
        getRowClass(item: IMaster) {
            if (item.child_masters && item.child_masters.length > 0) {
                return 'muv-parent';
            }

            if (item.master_kind_id === MASTER_KIND.CONTINUOUS_LEARNING) {
                return 'continuous-learning';
            }
            return '';
        },
        togglePublishDialog() {
            this.publishDialog = !this.publishDialog;
        },
        toggleArchiveDialog() {
            this.archiveDialog = !this.archiveDialog;
        },
        toggleDraftDialog() {
            this.draftDialog = !this.draftDialog;
        },
        toggleApproveDialog() {
            this.approveDialog = !this.approveDialog;
        },
        async publishMaster() {
            this.overlay = !this.overlay;

            await this.publish(this.masterIdPublish)
                .then(() => {
                    this.overlay = !this.overlay;
                    this.getData();
                })
                .catch((e: Error) => {
                    this.overlay = !this.overlay;
                    this.setSigueError(e.message);
                    this.showSigueError = true;
                });
        },
        async draftMaster() {
            this.overlay = !this.overlay;

            await this.draft(this.masterIdDraft)
                .then(() => {
                    this.overlay = !this.overlay;
                    this.getData();
                })
                .catch((e: Error) => {
                    this.overlay = !this.overlay;
                    this.setSigueError(e.message);
                    this.showSigueError = true;
                });
        },
        async archiveMaster() {
            this.overlay = !this.overlay;

            await this.archive(this.masterIdArchive)
                .then(() => {
                    this.overlay = !this.overlay;
                    this.getData();
                })
                .catch((e: Error) => {
                    this.overlay = !this.overlay;
                    this.setSigueError(e.message);
                    this.showSigueError = true;
                });
        },
        async approveMaster() {
            this.overlay = !this.overlay;

            await this.approve(this.masterIdApprove)
                .then(() => {
                    this.overlay = !this.overlay;
                    this.getData();
                })
                .catch((e: Error) => {
                    this.overlay = !this.overlay;
                    this.setSigueError(e.message);
                    this.showSigueError = true;
                });
        },
        toggleReviewDialog() {
            this.reviewDialog = !this.reviewDialog;
        },
        async reviewMaster() {
            this.overlay = !this.overlay;

            await this.review(this.masterIdReview)
                .then(() => {
                    this.overlay = !this.overlay;
                    this.getData();
                })
                .catch((e: Error) => {
                    this.overlay = !this.overlay;
                    this.setSigueError(e.message);
                    this.showSigueError = true;
                });
        },
        toggleCorrectDialog() {
            this.correctDialog = !this.correctDialog;
        },
        async correctMaster() {
            this.overlay = !this.overlay;

            await this.correct(this.masterIdCorrect)
                .then(() => {
                    this.overlay = !this.overlay;
                    this.getData();
                })
                .catch((e: Error) => {
                    this.overlay = !this.overlay;
                    this.setSigueError(e.message);
                    this.showSigueError = true;
                });
        },
        toggleSigueDialog() {
            this.sigueDialog = !this.sigueDialog;
        },
        async toSigue() {
            this.overlay = !this.overlay;

            await this.exportToSigue(this.masterIdToSigue)
                .then(() => {
                    this.overlay = !this.overlay;
                    this.getData();
                })
                .catch((e: Error) => {
                    this.overlay = !this.overlay;
                    this.setSigueError(e.message);
                    this.showSigueError = true;
                });
        },
        setSigueError(message: string | string[]) {
            // @ts-ignore
            if (message.message) {
                // @ts-ignore
                this.sigueError = message.message;
            } else {
                // @ts-ignore
                this.sigueError = message;
            }

            if (Array.isArray(message)) {
                // @ts-ignore
                this.sigueError =
                    '<span>' + message.join('</span><span>') + '</span>';
            }
        },
        parseFilters() {
            let filters = {};

            // @ts-ignore
            if (this.filters && this.filters.master_state_code) {
                // master_state_code
                filters = {
                    // @ts-ignore
                    master_state_code: this.filters.master_state_code.map(
                        (f) => f.code
                    ),
                };
            }

            return JSON.stringify(filters);
        },
        getXlsUrl(): string {
            const query = this._setDataQuery(false, 'xlsx');
            const url = api.master.getMastersUrl(query);

            return url;
        },
        async getData() {
            try {
                this.getLocalStorageSearch();

                if (this.loading) {
                    return;
                }

                this.loading = true;

                if (this.academicYear && this.academicYear.id != null) {
                    const query = this._setDataQuery();

                    const response = await api.master.getAll(query);

                    Vue.set(this, 'totalRecords', response.data.count);
                    Vue.set(this, 'masters', response.data.data);
                }

                this.loading = false;
            } catch (err) {
                this.loading = false;
                throw err;
            }
        },
        _setDataQuery(paginate: boolean = true, output_format: string) {
            const p = paginate
                ? {
                      limit: this.pagination.itemsPerPage,
                      offset:
                          (Number(this.pagination.page) - 1) *
                          this.pagination.itemsPerPage,
                      // sortBy: this.pagination.sortBy,
                      // sortDesc: this.pagination.sortDesc
                  }
                : null;

            const query = {
                pagination: p != null ? JSON.stringify(p) : null,
                search: this.search != null ? this.search : null,
                filter: this.parseFilters(),
                groupParentChilds: this.groupParentChilds,
                academicYearId: this.academicYear?.id,
                output_format: output_format,
            };

            return query;
        },
        goToMaster(id: number) {
            const routeData = this.$router.resolve({
                name: 'Master',
                params: { masterId: `${id}` },
            });
            window.open(routeData.href, '_blank');
        },
        deleteMasterDialog(master: IMaster) {
            this.deleteMasterUI = true;
            this.deleteMasterObject = master;
        },
        async deleteCurrentMaster() {
            try {
                this.deleteMasterUI = false;

                // @ts-ignore
                await this.delete({
                    master: this.deleteMasterObject,
                });

                this.deleteMasterObject = {};

                this.getData();
            } catch (err) {
                // avoid uncaught promise
            }
        },
        cancelDeleteMaster() {
            this.deleteMasterObject = {};

            this.deleteMasterUI = false;
        },
        handleClickTableRow(row) {
            comsole.log('handleRowClick');
            this.goToMaster(row.id);
        },
        handle_sigue_header() {
            if (this.imRoleAdmin || this.imRoleTechnic) {
                // filter sigue header
                this.headers = this.headers.filter(
                    (element, index) => element.value !== 'export_to_sigue'
                );
                if (!this.tenant_sigue_connect_disabled) {
                    const l = this.headers.length - 1;
                    this.headers = [
                        ...this.headers.slice(0, l),
                        {
                            text: this.$i18n.t('En Sigue'),
                            value: 'export_to_sigue',
                            sortable: false,
                            align: 'center',
                        },
                        ...this.headers.slice(l),
                    ];
                }
            }
        },
    },
    watch: {
        tenant_sigue_connect_disabled: {
            handler: function (value, oldvalue) {
                this.handle_sigue_header();
            },
            deep: true,
        },
        groupParentChilds(newValue, oldValue) {
            if (newValue != null && newValue != oldValue) {
                this.getData();
            }
        },
        academicYears(newValue, oldValue) {
            if (newValue != null && oldValue.length == 0) {
                this.getLocalStorageSearch();
                this.setAcademicYearFilter();
            }
        },
        pagination: {
            handler() {
                this.getData();
            },
            deep: true,
        },
        search: {
            handler: function (value, oldValue) {
                localStorage.setItem('search_masters', value);
                if (!this.awaitingSearch) {
                    setTimeout(async () => {
                        this.pagination.page = 1;
                        await this.getData();
                        this.awaitingSearch = false;
                    }, 1300); // 1 sec delay
                }
                this.awaitingSearch = true;
            },
            deep: true,
        },
        filters: {
            handler: function (value, oldValue) {
                localStorage.setItem('filter_masters', JSON.stringify(value));
                if (!this.awaitingSearch) {
                    setTimeout(async () => {
                        this.pagination.page = 1;
                        await this.getData();
                        this.awaitingSearch = false;
                    }, 1300); // 1 sec delay
                }
                this.awaitingSearch = true;
            },
            deep: true,
        },
    },
});
