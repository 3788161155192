
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { MASTER_KIND } from '@/helpers';
import { IAcademicYearsPerms } from '@/interfaces/config/interfaces/models/IAcademicYearsPerms';

export default Vue.extend({
    name: 'Publish',
    computed: {
        ...mapGetters({
            masterState: 'master/getData',
            masterStates: 'master/getMasterStatesObject',
            hasPCategoriesRestrictions: 'master/hasPCategoriesRestrictions',
            directorsAreTeachers: 'master/directorsAreTeachers',
            config: 'config/get',
            academicYearsPerms: 'config/getAcademicYearsPerms',
        }),
        isTermFinished() {
            if (
                !this.academicYearsPerms ||
                this.academicYearsPerms.length === 0
            ) {
                return true;
            }

            const academicYear = this.academicYearsPerms.find(
                (academicYearPerm: IAcademicYearsPerms) => {
                    return (
                        academicYearPerm.academic_year_id ==
                        Number(this.masterState.academic_year_id)
                    );
                }
            );

            if (academicYear == null) {
                return true;
            }

            const period_closed =
                (!academicYear.cl_create_allowed &&
                    this.masterState.master_kind_id ===
                        this.MASTER_KIND.CONTINUOUS_LEARNING) ||
                (!academicYear.od_create_allowed &&
                    this.masterState.master_kind_id ===
                        this.MASTER_KIND.DEFAULT);

            return period_closed && !(this.imRoleTechnic || this.imRoleAdmin);
        },
        doNotPublishReason(): string | null {
            if (!this.directorsAreTeachers) {
                return this.$i18n.t('No todos los directores son profesores.');
            }

            if (this.hasPCategoriesRestrictions) {
                return this.$i18n.t(
                    'Al menos un codirector debe tener la categoría profesional adecuada para presentar el título.'
                );
            }

            if (
                this.masterState.master_type_code == null &&
                this.dontCheckECTS.find((id) => (id = this.masterState.id)) ==
                    null
            ) {
                return this.$i18n.t(
                    'No se puede establecer el tipo de título a partir de sus ECTS. Revisa las asignaturas para que a partir de la suma de ECTS pueda establecerse el tipo de título.'
                );
            }

            if (this.masterState.modular && this.masterState.fees.length <= 1) {
                return this.$i18n.t(
                    'Un título modular necesita al menos dos tarifas. Una "general" y otra para el caso de cursarlo por módulos.'
                );
            }

            return null;
        },
    },
    data() {
        return {
            dontCheckECTS: [
                909, 563, 609, 548, 604, 633, 594, 851, 810, 619, 666, 594,
            ],
            showForm: false,
            showFormChild: false,
            formError: null,
            overlay: false,
            publishDialog: false,
            MASTER_KIND: MASTER_KIND,
        };
    },
    methods: {
        ...mapActions('master', ['publish']),
        async publishMaster() {
            this.overlay = !this.overlay;

            await this.publish(this.masterState.id)
                .then(() => {
                    this.overlay = !this.overlay;
                    // this.getData();
                    this.formError = null;
                })
                .catch((e: Error) => {
                    this.overlay = !this.overlay;
                    this.formError = e.message;
                    this.showSigueError = true;
                });
        },
        togglePublishDialog() {
            this.publishDialog = !this.publishDialog;
        },
    },
});
