
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
import {ISubject} from '../../../interfaces/subject/interfaces/models/ISubject';
import {ITeacher} from '../../../interfaces/teacher/interfaces/models/ITeacher';
import SelectTeacher from '@/components/common/SelectTeacher.vue';
import SelectTeacherPreLoaded from '@/components/common/SelectTeacherPreLoaded.vue';

import * as api from '@/api/index';
import * as helpers from '@/helpers/index';
import {ITeacherSubject} from '../../../interfaces/subject/interfaces';

export default Vue.extend({
    name: 'TeacherSubjectEdit',
    /* eslint-disable */
    components: {
        SelectTeacher,
        SelectTeacherPreLoaded
    },
    /* eslint-enable */
    props: {
        // v-model of dialog
        value: {
            type: Boolean,
            default: false
        },
        isNew: {
            type: Boolean,
            default: false
        },
        // for new Objects we need the subject object to send to api.
        subject: {
            type: Object,
            default: () => {}
        },
        teacher: {
            type: Object,
            default: () => {}
        },
        teachersInSubject: {
            type: Array,
            default: () => []
        },
        // id for editing existing teacherSubject record
        teacherSubject: {
            type: Object,
            default: () => {}
        },
        editStep: {
            type: Number,
            default: null
        },
        changeInactiveUser: {
          type: Boolean,
          default: false
        }
    },
    data() {
        return {
            api: api,
            teacherType: 'uv',
            valid: false,
            errors: {},
            // @ts-ignore
            rules: {
                teacher: [
                    // @ts-ignore
                    (v: ITeacher) => {
                        if (
                            v &&
                            this.teachersInSubject.some(
                                teacher =>
                                    // teacher has been added to subject and is not in this record
                                    teacher.id === v.id &&
                                    teacher.id != this.teacher.id
                            )
                        ) {
                            return this.$i18n.t(
                                'El profesor ya está asociado a la asignatura'
                            );
                        }

                        return true;
                    }
                ],
                price: [
                    (v: number) =>
                        v != null || this.$i18n.t('El dato es requerido'),
                    // @ts-ignore
                    (v: number) => {
                        const number = parseFloat(
                            v.toString().replace(',', '.')
                        );

                        if (isNaN(number)) {
                            return false;
                        }

                        // @ts-ignore
                        return (
                            number <= this.constants.MAX_SUBJECT_PRICE ||
                            this.maxPriceMessage()
                        );
                    },
                    (v: number) => {
                        if (!helpers.isHalfDecimals(v)) {
                            return this.$i18n.t(
                                'Sólo se admiten mitades (0,5)'
                            );
                        }
                        return true;
                    }
                ],
                hours: [
                    (v: string) =>
                        v != null || this.$i18n.t('El dato es requerido'),
                    // @ts-ignore
                    (v: string) => {
                        const number = parseFloat(
                            v.toString().replace(',', '.')
                        );

                        if (isNaN(number)) {
                            return false;
                        }

                        if (
                            !this.subject.is_practical &&
                            !this.subject.is_tfm &&
                            number >= this.constants.MAX_SUBJECTS_HOURS
                        ) {
                            return this.maxHoursMessage();
                        }

                        return true;
                    },
                    (v: string) => {
                        if (!helpers.isHalfDecimals(v)) {
                            return this.$i18n.t('Sólo se admiten medias horas');
                        }

                        return true;
                    }
                ]
            },
            localState: {
                teacher: null,
                subject: null,
                responsible: false,
                theoretical_teaching_hours: 0,
                theoretical_teaching_price: 0,
                practical_teaching_hours: 0,
                practical_teaching_price: 0,
                tutorial_teaching_hours: 0,
                tutorial_teaching_price: 0
            },
            pendingChanges: false
        };
    },
    computed: {
        ...mapGetters({
            formError: 'master/getError',
            master: 'master/getData',
            constants: 'master/getConstants',
            expenseSSCategories: 'master/getExpenseSSCategories'
        }),
        globalState() {
            if (this.teacherSubject != null) {
                return this.$store.getters['master/getTeacherSubject'](
                    this.teacherSubject.teacher_id,
                    this.teacherSubject.subject_id
                );
            }

            return null;
        },
        showExpenseSSAlert() {
            if (this.localState.teacher == null) {
                return false;
            }

            if (this.teacherType != 'uv' ) {
              return false;
            }

            if (
                this.localState.teacher.professional_category == null ||
                this.expenseSSCategories.includes(
                    this.localState.teacher.professional_category
                )
            ) {
                return false;
            }

            return true;
        }
    },
    mounted() {
        this.clearLocalState();
        setTimeout(() => this.parseGlobalState(), 300);
    },
    methods: {
        ...mapActions('master', [
            'createTeacherSubject',
            'updateTeacherSubject',
            'clearError'
        ]),
        disableInactiveUserFunction(item: ITeacherSubject) {
          return item.id = this.localState.id;
        },
        async getSameActiveUsers() {
          return await api.teacher.getAllTeachersWithSamePersonId(this.teacher.source_id_persona);
        },
        maxHoursMessage(): string {
            return (
                this.$i18n.t(`Superado el máximo permitido de `) +
                this.constants.MAX_SUBJECTS_HOURS +
                this.$i18n.t(' horas').toString()
            );
        },
        maxPriceMessage(): string {
            return (
                this.$i18n.t(`Superado el máximo permitido de `) +
                this.constants.MAX_SUBJECT_PRICE +
                ' €/h'
            );
        },
        parseGlobalState() {
            // @ts-ignore
            _.merge(this.localState, _.cloneDeep(this.globalState));

            this.localState.theoretical_teaching_price = Number(
                this.localState.theoretical_teaching_price
            );
            this.localState.tutorial_teaching_price = Number(
                this.localState.tutorial_teaching_price
            );
            this.localState.practical_teaching_price = Number(
                this.localState.practical_teaching_price
            );

            if (this.localState.teacher != null) {
                this.teacherType = this.localState.teacher.teacher_type_code;
            } else {
                this.teacherType = 'uv';
            }
        },
        parseLocalState(): ISubject {
            return this.localState;
        },
        clearLocalState() {
            this.teacherId = null;

            this.localState = {
                teacher:
                    this.teacher && Object.keys(this.teacher).length > 0
                        ? this.teacher
                        : null,
                subject: this.subject,
                responsible: false,
                theoretical_teaching_hours: 0,
                theoretical_teaching_price: 0,
                practical_teaching_hours: 0,
                practical_teaching_price: 0,
                tutorial_teaching_hours: 0,
                tutorial_teaching_price: 0
            };

            this.teacherType = 'uv';

            this.clearError();

            this.clearModified();

            // @ts-ignore
            this.$refs.form.resetValidation();
        },
        exit() {
            this.clearLocalState();
            this.$emit('input', false);
        },
        setModified() {
            this.pendingChanges = true;
        },
        clearModified() {
            this.pendingChanges = false;
        },
        cancelChanges() {
            this.parseGlobalState();
            this.clearModified();
        },
        async save() {
            if (this.isNew) {
                this.create();
            } else {
                this.update();
            }

            // this.exit();
        },
        async update() {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                this.parseLocalState();

                // @ts-ignore
                await this.updateTeacherSubject({
                    masterId: this.master.id,
                    teacherSubject: this.localState
                });

                this.clearModified();
                this.$emit('updated', false);
            }
        },
        async create() {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                this.parseLocalState();

                await this.createTeacherSubject({
                    masterId: this.master.id,
                    teacherSubject: this.localState
                });

                this.$emit('created', this.localState);
                this.clearLocalState();
            }
        },
        forceNumber(evt: any) {
            if (!helpers.isKeyNumber(evt)) {
                evt.preventDefault();
            }
        }
    },
    watch: {
        globalState(newValue, oldValue) {
            this.parseGlobalState();
        },
        subject(newValue, oldValue) {
            this.localState.subject = newValue;
        },
        teacher(newValue, oldValue) {
            if (helpers.isEmpty(newValue)) {
                this.clearLocalState();
                this.localState.teacher = null;
            } else {
                this.localState.teacher = newValue;
                this.teacherType = newValue.teacher_type_code;
                // this.setModified();
            }
        },
        teacherSubject(newValue, oldValue) {
            this.parseGlobalState();
        },
        isNew(newValue, oldValue) {}
    }
});
