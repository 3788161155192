import {LoginResponse} from '../vuex/modules/authentication/types';
// @ts-ignore
import * as api from '@/api';
import {initSession} from "../helpers/session";

export const authentication = {
    login,
    refresh,
    logout,
    getAll
};

async function login(login: string, password: string) {
    try {
        const payload = await api.authentication.login(login, password);
        const loginResponse: LoginResponse = payload && payload.data;

        if (loginResponse.app_user) {
            // store user details (jwt access_token is store in http-only cookie) in local storage
            // to keep user logged in between page refreshes
            localStorage.setItem(
                'app_user',
                JSON.stringify(loginResponse.app_user)
            );

            localStorage.setItem(
                'expires_in',
                JSON.stringify(loginResponse.expires_in)
            );

            initSession();
        } else {
            logout();
        }

        return loginResponse;
    } catch (e) {
        if (e.status === 401 || e.status === 404) {
            // auto logout if 401 / 404 response returned from api/login
            logout();
            // location.reload(true);
        }

        return Promise.reject(e);
    }
}

/**
 * Call to server api to refresh current access token
 */
async function refresh() {
    try {
        const payload = await api.authentication.refresh();
        const refreshResponse: LoginResponse = payload && payload.data;

        if (refreshResponse.app_user) {
            // store user details (jwt access_token is store in http-only cookie) in local storage
            // to keep user logged in between page refreshes
            localStorage.setItem(
                'app_user',
                JSON.stringify(refreshResponse.app_user)
            );

            localStorage.setItem(
                'expires_in',
                JSON.stringify(refreshResponse.expires_in)
            );
        } else {
            logout();
        }
        return refreshResponse;
    } catch (e) {
        if (e.status === 401 || e.status === 404) {
            // auto logout if 401 / 404 response returned from api/login
            logout();
            // location.reload(true);
        }

        return Promise.reject(e);
    }
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('app_user');
    localStorage.removeItem('expires_in');
}

function getAll() {
    // return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}
