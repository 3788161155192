
import Vue from 'vue';
import MasterList from '@/components/master/MasterList.vue';
import Component from 'vue-class-component';

@Component({
    components: {
        MasterList
    }
})
export default class MasterListView extends Vue {}
