
import Vue from 'vue';
import ZipFiles from './ZipFiles.vue';
import SyncLog from './SyncLog.vue';
import TeachersTab from './TeachersTab.vue';
import Masters from './Masters.vue';
import Config from './Config.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
    name: 'BackOffice',
    components: {
        ZipFiles,
        SyncLog,
        Masters,
        TeachersTab,
        Config,
    },
    computed: {
        ...mapGetters({
            tenant_sigue_connect_disabled: 'tenant/sigue_connect_disabled',
            tenant_platform_configure_disabled: 'tenant/sigue_connect_disabled',
            tenant_zip_files_generate_disabled:
                'tenant/zip_files_generate_disabled',
        }),
    },
    data() {
        return {
            currentTab: null,
        };
    },
});
