
import Vue from 'vue';

export default Vue.extend({
    name: 'SaveCancel',
    props: {
        valid: {
            type: Boolean,
            default: false
        },
        pendingChanges: {
            type: Boolean,
            default: false
        },
        cancelChanges: {
            type: Function,
            default: () => {}
        },
        exitButton: {
            type: Boolean,
            default: false
        },
        exit: {
            type: Function,
            default: () => {}
        },
        save: {
            type: Function,
            default: () => {}
        }
    }
});
