
import Vue from 'vue';
// @ts-ignore
import {authentication} from '@/services';
// @ts-ignore
import * as api from '@/api/index';

export default Vue.extend({
    computed: {
    },
    data: function() {
        return {
            showLoginError: false,
            passwordResetted: false,
            valid: false,
            email: '',
            emailRules: [
              (v: any) => !!v || this.$i18n.t('E-mail requerido'),
              (v: any) =>
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                  'E-mail must be valid'
            ],
            changingPasswordError: null
        };
    },
    mounted() {
        this.email = '';
    },
    methods: {
        submit() {

          // @ts-ignore
          if (this.$refs.form.validate()) {
              // @ts-ignore
              api.authentication.forgottenPassword(this.email)
                .then((res: any) => {
                      // Http Status 200 - Ok
                      this.showLoginError = false;
                      this.passwordResetted = true;
                })
                .catch(e => {
                    console.log(e);
                    this.changingPasswordError = this.$i18n.t('El usuario no existe');
                    this.showLoginError = true;
                });
          }
        },
        clear() {
            // @ts-ignore
            this.$refs.form.reset();
        },
    }
});
