/*
 *  Copyright 2017-2018 Enrique Araque Vilches
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
import * as mutation_types from '../../mutation_types';
import {MutationTree} from 'vuex';
import {ConfigState} from './types';
import Vue from 'vue';
import {IConfig} from "@/interfaces/config/interfaces/models/IConfig";
import {IAcademicYearsPerms} from "@/interfaces/config/interfaces/models/IAcademicYearsPerms";

export const mutations: MutationTree<ConfigState> = {
    [mutation_types.LOAD_DATA](state: ConfigState, payload: IConfig) {
        state.error = undefined;

        Vue.set(state, 'data', payload);
    },
    [mutation_types.LOAD_ACADEMIC_YEARS_DATA](state: ConfigState, payload: IAcademicYearsPerms) {
        state.error = undefined;

        Vue.set(state, 'academicYearsPerms', payload);
    },
    [mutation_types.CLEAR_ACADEMIC_YEARS_DATA](state, payload: IConfig) {
        state.error = undefined;

        Vue.set(state, 'academicYearsPerms', {});
    },
    [mutation_types.CLEAR_DATA](state, payload: IConfig) {
        state.error = undefined;

        Vue.set(state, 'data', {});
    },
    [mutation_types.LOAD_ERROR](state, error) {
        Vue.set(state, 'error', error);
    },
    [mutation_types.CLEAR_ERROR](state) {
        Vue.set(state, 'error', undefined);
    }
};
