// @ts-ignore
import * as _ from 'lodash';
import i18n from '../lang';

export const formatDate = (date: string) => {
    if (!date) return null;

    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
};

export const isEmptyNumber = (n: number): boolean => {
    return n == null || (typeof n === 'number' && (n == null || n === 0));
};

export const isEmptyString = (str: string): boolean => {
    return (
        str === null || (typeof str === 'string' && (str == null || str === ''))
    );
};

export const isEmpty = (obj: object): boolean => {
    return (
        (typeof obj === 'object' && Object.keys(obj).length === 0) ||
        obj == null
    );
};

export function getValueNestedProperty(obj: object, dottedProperty: string) {
    return dottedProperty
        .split('.')
        .reduce(
            (r: any, val) => (r && r.hasOwnProperty(val) ? r[val] : undefined),
            obj
        );
}

export function objectToArrayOrderedByProperties(
    obj: object,
    properties: string[]
): object[] {
    const ordered = Object.values(obj).sort((a, b) => {
        for (const prop of properties) {
            if (
                getValueNestedProperty(a, prop) >
                getValueNestedProperty(b, prop)
            ) {
                return 1;
            }
            if (
                getValueNestedProperty(a, prop) <
                getValueNestedProperty(b, prop)
            ) {
                return -1;
            }
            if (
                getValueNestedProperty(a, prop) ===
                getValueNestedProperty(b, prop)
            ) {
                // props are equal. go on with next property
            }
        }
        return 0;
    });

    return ordered;
}

export function isHalfDecimals(v: string): boolean {
    if (v == null) {
        return false;
    }

    v = v.toString().replace('.', '');
    const n = parseFloat(v.replace(',', '.'));
    const decimals: number = Number((n - Math.floor(n)).toFixed(1)) * 10;
    if (decimals === 0 || decimals === 5) {
        return true;
    } else {
        return false;
    }
}

export function isKeyNumber(evt: any) {
    const keyCode = evt.keyCode ? evt.keyCode : evt.which;

    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
        // 46 is dot 44 is comma
        return false;
    }

    return true;
}

/**
 * Converts array to object (associative array) with key as propertyName
 * @param {object[]} sourceArray
 * @param {string} keyField
 * @param {any} valueField : assign this value to key
 * @returns {any}
 */
export function arrayToObject(
    sourceArray: object[],
    keyField: string,
    valueField?: any
) {
    if (sourceArray == null || sourceArray.length === 0) {
        return {};
    }

    // @ts-ignore
    const areStrings = typeof sourceArray[0][keyField] === 'string';

    if (areStrings) {
        sourceArray.sort((a: any, b: any) =>
            _.get(a, keyField)
                .toLowerCase()
                .localeCompare(_.get(b, keyField).toLowerCase())
        );
    } else {
        sourceArray.sort((a: any, b: any) => {
            let result = 0;

            if (_.get(a, keyField) < _.get(b, keyField)) {
                result = -1;
            } else if (_.get(a, keyField) > _.get(b, keyField)) {
                result = 1;
            }

            return result;
        });
    }

    const objects: { [keyField: string]: object } = {};

    let value: object;
    for (const item of sourceArray) {
        value = valueField
            ? (value = getPropertyValue(item, valueField))
            : (value = item);

        objects[getPropertyValue(item, keyField)] = value;
    }

    return objects;
}

function getPropertyValue(obj: any, propertyName: string) {
    propertyName.split('.').forEach((token: string) => {
        if (obj) {
            obj = obj[token];
        }
    });

    return obj;
}

export function translateFieldArray(arr: any[], field: string) {
    const _arr = _.clone(arr);

    return _arr.map(item => {
        item[field] = i18n.t(item[field]);
        return item;
    });
}

export function unformatString(string: string, locale: string | null = null) {
    let _locale = locale;
    if (_locale == null) {
        _locale = 'de';
    }

    const parts = (1234.5).toLocaleString(_locale).match(/(\D+)/g);

    let unformatted = string + ''; // ensures string
    // @ts-ignore
    unformatted = unformatted.split(parts[0]).join('');
    // @ts-ignore
    unformatted = unformatted.split(parts[1]).join('.');

    return parseFloat(unformatted);
}

export const camelize = (text: string, separator = '_') =>
    text
        .split(separator)
        .map(w => w.replace(/./, m => m.toUpperCase()))
        .join('');

export function openPdfFromStream(fileStream: any) {
    const blob = new Blob(["\ufeff", fileStream], {
        type: `application/pdf`
    });
    const objectUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");

    const fname = `model-responsable`; //The name of the downloaded file
    link.href = objectUrl;
    link.setAttribute("download", fname);
    document.body.appendChild(link);
    link.click();

}


export function validateDNI(dni: string) {
    let numero, l, letra;
    const expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

    if (dni == null) {
        return;
    }

    dni = dni.toUpperCase();

    if(expresion_regular_dni.test(dni) === true){
        numero = dni.substr(0,dni.length-1);
        numero = numero.replace('X', 0);
        numero = numero.replace('Y', 1);
        numero = numero.replace('Z', 2);
        l = dni.substr(dni.length-1, 1);
        numero = numero % 23;
        letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
        letra = letra.substring(numero, numero+1);
        if (letra != l) {
            //alert('Dni erroneo, la letra del NIF no se corresponde');
            return false;
        }else{
            //alert('Dni correcto');
            return true;
        }
    } else {
        //alert('Dni erroneo, formato no válido');
        return false;
    }
}

export function NewDegreeProfCategoriesRestriccions() {

}
