import Vue from 'vue';
import Vuex, {StoreOptions} from 'vuex';
import createLogger from 'vuex/dist/logger';
import {RootState} from './types';
import {authentication} from './modules/authentication';
import {master} from './modules/master';
import {app} from './modules/app';
import {config} from "./modules/config";
import {tenant} from "./modules/tenant";

Vue.use(Vuex);

const debug = process.env.DEBUG_MODE;

const store: StoreOptions<RootState> = {
    state: {
        version: '1.0.0'
    },
    strict: false,
    modules: {
        app,
        authentication,
        master,
        config,
        tenant,
    },
    plugins: debug ? [createLogger({})] : []
};

export default new Vuex.Store<RootState>(store);
