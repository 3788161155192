
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
import {form} from '@/components/mixins';
import SaveCancel from '../common/SaveCancel.vue';

export default Vue.extend({
    name: 'OtherData',
    props: {
        editStep: {
            type: Number,
            default: null,
        },
    },
    components: {
        SaveCancel,
    },
    mixins: [form],
    data() {
        return {
            errors: {
                academic_relevance: [],
                objectives: [],
                access_requirements: [],
                job_opportunities: [],
                knowledge_fields: [],
                knowledge_branch: [],
                overall_methodology: [],
                admission_criteria: [],
                learning_results: []
            },
            rules: {
                academic_relevance: [],
                objectives: [],
                access_requirements: [],
                job_opportunities: [],
                knowledge_fields: [],
                knowledge_branch: [],
                overall_methodology: [],
                admission_criteria: [],
                learning_results: []
            },
            localState: {
                academic_relevance: null,
                objectives: null,
                access_requirements: null,
                job_opportunities: null,
                overall_methodology: null,
                knowledge_fields: [],
                knowledge_branch: [],
                admission_criteria: null,
                learning_results: null
            },
            pendingChanges: false,
            tabActive: 0,
        };
    },
    computed: {
        ...mapGetters({
            formError: 'master/getError',
            globalState: 'master/getData',
            knowledgeFields: 'master/getKnowledgeFields',
            knowledgeBranches: 'master/getKnowledgeBranches',
        }),
    },
    methods: {
        ...mapActions('master', ['update', 'clearError']),
        parseLocalStateAfter() {
            this.localState.knowledge_fields = [
                this.localState.knowledge_field,
            ];
        },
        parseGlobalStateAfter() {
            this.localState.knowledge_field =
                this.localState.knowledge_fields[0];
        },
    },
});
