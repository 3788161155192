// @ts-ignore
import Store from '@/vuex/store';
// @ts-ignore
import Router from '@/router';
import {addSeconds, differenceInMilliseconds, isAfter, subMinutes} from 'date-fns';

// @ts-ignore
import api from '@/api';
import {AUTH} from '@/helpers';
import {LoginResponse} from '../vuex/modules/authentication/types';
import {authentication} from '../services';

export { initSession };

async function setRefreshTimeout() {
    // @ts-ignore
    const tokenExpiryDate = addSeconds(
        new Date(),
        localStorage.getItem('expires_in')
    );
    if (!tokenExpiryDate) {
        // @ts-ignore
        // console.log('No token expiry date. user probably never logged in');
        return Router.push('/login');
    }

    //If the token has expired or will expire in the next 30 minutes
    const minutesBeforeExpiry = subMinutes(
        tokenExpiryDate,
        AUTH.refrehTokenMinutes
    );
    const now = new Date();

    if (isAfter(now, minutesBeforeExpiry)) {
        //If the token has expired or will expire in the next 10 minutes
        // @ts-ignore
        // console.log('Token expired/will expire in the next 1 minutes');
        return Router.push('/login');
    }

    setTimeout(
        refreshTokens,
        differenceInMilliseconds(minutesBeforeExpiry, now)
    );
}

function initSession() {
    return new Promise(resolve => {
        setRefreshTimeout();

        resolve();
    });
}

async function refreshTokens() {
    try {
        const authResponse: LoginResponse = await authentication.refresh();

        setRefreshTimeout();
    } catch (err) {
        Router.push('/login');
        throw err;
    }
}
