
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
// @ts-ignore
import * as _ from 'lodash';
import * as api from '@/api/index';
import * as helpers from '@/helpers/index';
import DeleteDialog from '@/components/common/DeleteDialog.vue';
import SaveCancel from '../../common/SaveCancel.vue';
import {MASTER_KIND} from "@/helpers";

export default Vue.extend({
    name: 'SubjectTeachingGuide',
    components: {
        DeleteDialog,
        SaveCancel
    },
    props: {
        editStep: {
            type: Number,
            default: null
        },
        subjectId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            api: api,
            valid: false,
            errors: {
                name: [],
                teaching_type: [],
                ects: [],
                is_tfm: [],
                is_practical: [],
                teaching_start_date: [],
                teaching_end_date: []
            },
            rules: {
                name: [
                    (v: string) => !!v || this.$i18n.t('El nombre es requerido')
                ],
                teaching_start_date: [
                    //(v: string) => !!v || this.$i18n.t('La fecha es requerida')
                ],
                teaching_end_date: [
                    //(v: string) => !!v || this.$i18n.t('La fecha es requerida')
                ],
                list_of_topics: [
                    (v: string) =>
                        !!v || this.$i18n.t('El temario no puede estar vacío.')
                ]
            },
            localState: {
                name: null,
                master_id: null,
                teaching_start_date: null,
                teaching_end_date: null,
                list_of_topics: null
            },
            pendingChanges: false,
            menuStartDate: false,
            menuEndDate: false,
            showLangAlert: false,
            nextId: 0,
            openItems: [],
            itemToDelete: null,
            showDelete: false,
            tabActive: 0
        };
    },
    computed: {
        ...mapGetters({
            formError: 'master/getError',
            master: 'master/getData',
            teachingTypes: 'master/getTeachingTypes'
        }),
        globalState() {
            return this.$store.getters['master/getSubject'](this.subjectId);
        },
        computedStartDateFormatted() {
            return helpers.formatDate(this.localState.teaching_start_date);
        },
        computedEndDateFormatted() {
            return helpers.formatDate(this.localState.teaching_end_date);
        },
        isContinuousLearning() {
          return (
              this.master.master_kind_id ===
              MASTER_KIND.CONTINUOUS_LEARNING
          );
        },
    },
    created() {
        this.parseGlobalState();
    },
    methods: {
        ...mapActions('master', ['updateSubject', 'clearError']),
        parseGlobalState() {
            // @ts-ignore
            _.merge(this.localState, _.cloneDeep(this.globalState));
        },
        parseLocalState() {
            return this.localState;
        },
        clearLocalState() {
            this.localState = {
                name: null,
                teaching_type: null,
                ects: null,
                is_tfm: null,
                is_practical: null,
                master_id: null,
                teaching_start_date: null,
                teaching_end_date: null,
                list_of_topics: null
            };

            this.clearError();

            // @ts-ignore
            this.$refs.form.resetValidation();
        },
        setModified() {
            this.pendingChanges = true;
        },
        clearModified() {
            this.pendingChanges = false;
        },
        cancelChanges() {
            this.parseGlobalState();
            this.clearModified();
        },
        async save() {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                this.parseLocalState();
                // @ts-ignore
                await this.updateSubject({
                    subject: this.localState,
                    master: this.master
                });
                this.clearModified();
                this.$emit('updated', false);
            }
        }
    },
    watch: {
        globalState(newValue, oldValue) {
            this.parseGlobalState();
        }
    }
});
