
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
import * as helpers from '@/helpers/index';
import {form} from '@/components/mixins';
import SaveCancel from '../common/SaveCancel.vue';
import {IAcademicYear} from '../../interfaces/master/interfaces';

export default Vue.extend({
    name: 'Config',
    components: {
        SaveCancel,
    },
    mixins: [form],
    data() {
        return {
            errors: {
                deadline_start_date_time: [],
                deadline_end_date_time: [],
                period_closed: [],
                deadline_active: [],
            },
            rules: {
                deadline_start_date_time: [],
                deadline_end_date_time: [],
                deadline_active: [],
            },
            dataModel: {
                deadline_start_date_time: null,
                deadline_end_date_time: null,
                period_closed: null,
                deadline_active: null,
            },
            menuDateStart: false,
            menuDateEnd: false,
            localState: {
                id: null,
                deadline_start_date_time: null,
                deadline_end_date_time: null,
                period_closed: null,
                deadline_active: null,
            },
            headers: [
                {
                    text: this.$i18n.t('Curso Académico'),
                    align: 'left',
                    sortable: false,
                    value: 'name',
                    width: '150px',
                },
                {
                    text: this.$i18n.t('Desde'),
                    value: 'start_date',
                    align: 'center',
                    sortable: true,
                },
                {
                    text: this.$i18n.t('Hasta'),
                    value: 'end_date',
                    align: 'center',
                },
                {
                    text: this.$i18n.t('Formación Contínua'),
                    value: 'cl_closed',
                    align: 'center',
                },
                {
                    text: this.$i18n.t('Títulos Propios'),
                    value: 'od_closed',
                    align: 'center',
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            formError: 'config/getError',
            globalState: 'config/get',
            academicYears: 'master/getAcademicYears',
        }),
        computedStartDateFormatted() {
            return helpers.formatDate(this.localState.deadline_start_date_time);
        },
        computedEndDateFormatted() {
            return helpers.formatDate(this.localState.deadline_end_date_time);
        },
        getButtonColor() {
            if (this.localState.period_closed) {
                return 'success';
            } else {
                return 'error';
            }
        },
    },
    methods: {
        ...mapActions('config', [
            'update',
            'clearError',
            'activateDeadLine',
            'deActivateDeadLine',
            'updateAcademicYear',
        ]),
        getItemLabel(value: boolean) {
            return value ? this.$i18n.t('Cerrado') : this.$i18n.t('Abierto');
        },
        getItemColor(value: boolean) {
            return value ? 'error' : 'success';
        },
        async updateItem(item: IAcademicYear) {
            await this.updateAcademicYear(item);
        },
        clearStartDate() {
            Vue.set(this.localState, 'deadline_start_date_time', null);
            this.setModified();
        },
        clearEndDate() {
            Vue.set(this.localState, 'deadline_end_date_time', null);
            this.setModified();
        },
        openPeriod() {
            Vue.set(this.localState, 'period_closed', false);
            this.save();
        },
        closePeriod() {
            Vue.set(this.localState, 'period_closed', true);
            this.save();
        },
        forceNumber(evt: any) {
            if (!helpers.isKeyNumber(evt)) {
                evt.preventDefault();
            }
        },
        clearLocalState() {
            this.localState = {
                id: null,
                deadline_start_date_time: null,
                deadline_end_date_time: null,
                period_closed: null,
                deadline_active: null,
            };

            this.clearError();

            // @ts-ignore
            this.$refs.form.resetValidation();
        },
        parseGlobalStateAfter() {},
        parseLocalStateAfter() {},
    },
});
