
import Vue from 'vue';
import SubjectList from './SubjectList.vue';
import SummaryGuide from './SummaryGuide.vue';

export default Vue.extend({
    name: 'MainGuide',
    props: {
        editStep: {
            type: Number,
            default: null
        }
    },
    components: {
        SubjectList,
        SummaryGuide
    },
    data() {
        return {
            tabActive: 0
        };
    }
});
