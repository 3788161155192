import {TenantState} from "@/vuex/modules/tenant/types";
import {Module} from "vuex";
import {RootState} from "@/vuex/types";
import {getters} from "@/vuex/modules/tenant/getters";
import {actions} from "@/vuex/modules/tenant/actions";
import {mutations} from "@/vuex/modules/tenant/mutations";

export const state: TenantState = {
    data: {
        tenants: [],
        current_tenant: {},
    },
    error: undefined,
};

const namespaced: boolean = true;

export const tenant: Module<TenantState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
