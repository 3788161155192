// @ts-ignore
import axios, {AxiosPromise} from 'axios';
// @ts-ignore
import URI from 'urijs';
import {ITeacher} from '../interfaces/teacher/interfaces/models/ITeacher';

export const teacher = {
    /**
     * Create a master with minimal data
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async getTeachers(filter: string) {
        const url = new URI(`${process.env.VUE_APP_API_URL}teachers/`);

        if (filter) {
            url.addQuery({ filter });
        }

        return axios.get(url.valueOf()) as Promise<any>;
    },
    async getAllTeachersWithSamePersonId(source_id_persona: string) {
        const url = new URI(`${process.env.VUE_APP_API_URL}teachers/`);

        url.addQuery({source_id_persona});
        return axios.get(url.valueOf()) as Promise<any>;
    },
    /**
     * Create a master with minimal data
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async getTeachersUV(filter: string) {
        const url = new URI(`${process.env.VUE_APP_API_URL}teachers/`);

        if (filter) {
            url.addQuery({ filter });
        }

        url.addQuery({ teacher_type: 'uv' });
        return axios.get(url.valueOf()) as Promise<any>;
    },
    /**
     * Create a master with minimal data
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async getTeachersExternal(filter: string) {
        const url = new URI(`${process.env.VUE_APP_API_URL}teachers/`);

        if (filter) {
            url.addQuery({ filter });
        }

        url.addQuery({ teacher_type: 'ext' });
        return axios.get(url.valueOf()) as Promise<any>;
    },
    /**
     * Get a list of universities
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async getUniversities(filter: string) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}teachers/universities`
        );

        if (filter) {
            url.addQuery({ filter });
        }

        return axios.get(url.valueOf()) as Promise<any>;
    },
    /**
     * Gets a relations of all teachers with his subjects in an academic year sumarizying hours
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async getSubjects(query: Object, teacherId: number) {
        const url = new URI(`${process.env.VUE_APP_API_URL}teachers/${teacherId}/subjects`);

        if (query != null) {
            for (const [key, value] of Object.entries(query)) {
                url.addQuery({ [key]: value });
            }
        }

        return axios.get(url.valueOf()) as Promise<any>;
    },
    /**
     * Create a teacher with minimal data
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async create(teacher: ITeacher) {
        const url = new URI(`${process.env.VUE_APP_API_URL}teachers`);

        return axios.post(url.valueOf(), teacher) as Promise<any>;
    },
    /**
     * Updates teacher with complete data
     * @returns {Promise<AxiosPromise<any>[]>}
     */
    async update(teacher: ITeacher) {
        const url = new URI(
            `${process.env.VUE_APP_API_URL}teachers/${teacher.id}`
        );

        return axios.put(url.valueOf(), teacher) as Promise<any>;
    }
};
