/*
 *  Copyright 2017-2018 Enrique Araque Vilches
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 *   Unless required by applicable law or agreed to in writing, software
 *   distributed under the License is distributed on an "AS IS" BASIS,
 *   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *   See the License for the specific language governing permissions and
 *   limitations under the License.
 */
export default {
    error: {
        msg_error: "S'ha produït un error",
        msg_description: 'Contacta amb el suport o obri una incidència'
    },
    'Menú Inicio': 'Menú Inici',
    'Iniciar sesión': 'Iniciar sessió',
    'Cerrar sesión': 'Tancar sessió',
    'Manual de usuario': 'Manual d\'usuari',
    'Títulos Propios UV': 'Títols Propis UV',
    Menu: 'Menú',
    Borrar: 'Esborrar',
    Confirmar: 'Confirmar',
    'Estás seguro/a de eliminar la referencia al título vinculado(padre)?': "Estàs segur/a d'eliminar la referència al títol vinculat(pare)?",
    'Estás seguro/a?': 'Estàs segur/a?',
    Cancel: 'Cancel·lar',
    Aceptar: 'Acceptar',
    'El item será borrado permanentemente. Quieres continuar':
        "L'ítem serà esborrat permanentment. Vols continuar",
    'Nueva tasa': 'Nova tasa',
    'Modificar tasa': 'Modificar tasa',
    'Ha ocurrido un error en el servidor':
        'Ha ocorregut una errada al servidor',
    'Grupo o público al que va dirigida la tasa':
        'Grup o públic al qual va adreçada la tasa',
    'Importe de la matrícula': 'Import de la matrícula',
    'Mínimo de estudiantes': "Mínim d'estudiantat",
    Guardar: 'Guardar',
    'No puede ser mayor que el número máximo de estudiantes del título':
        "No pot ser major que el nombre màxim d'estudiants del títol",
    'Tasas del título': 'Tases del títol',
    'Guarda los cambios para poder modificar las tasas del título':
        'Guarda els canvis per poder modificar les tases del títol',
    'Eliminar tasa': 'Eliminar',
    'Se eliminará la tasa completa, lo que afectará al presupuesto.':
        "S'eliminarà la tasa completa, la qual cosa afectarà al pressupost",
    Público: 'Públic',
    'Mín. Estudiantes': 'Mín. estudiants',
    'Matrícula ': 'Matrícula ',
    'Total ': 'Total ',
    'Elimina el fichero': 'Elimina el fitxer',
    'Descarga el fichero': 'Descarrega el fitxer',
    'Haz clik para adjuntar un nuevo fichero':
        'Fes click per adjuntar un nou fitxer',
    Salir: 'Eixir',
    'Cancelar cambios': 'Cancel·lar canvis',
    'Escribe para buscar una organización':
        'Escriu per cercar una organització',
    'El dato es requerido': 'Dada requerida',
    'Se ha producido un error consultando el servidor':
        "S'ha produït una errada consultant el servidor",
    nuevo: 'nou',
    'Escribe para buscar por nombre, apellidos o NIF':
        'Per cercar, escriu nom, cognoms o NIF',
    'Eliminar ': 'Eliminar',
    'Crea títulos propios': 'Crea títols propis',
    'Crear Título': 'Crear Títol',
    'Crear Título Vinculado': 'Crear Títol Vinculat',
    'Gestiona Títulos Propios': 'Gestiona Títols Propis',
    'Revisar / Modificar títulos propios presentados': 'Revisar / Modificar Títols Propis presentats',
    'Inicia sesión': 'Inicia sessió',
    'Error al iniciar sesión': 'Errada en iniciar sessió',
    'Nombre de usuario UV o Correo electrónico UV':
        "Nom d'usuari UV o correu electrònic UV",
    'Puedes introducir tu nombre de usuario uv o el correo electrónico':
        "Pots introduir el teu nom d'usuari UV o el correu electrònic",
    Contraseña: 'Contrasenya',
    'Contraseña requerida': 'Contrasenya requerida',
    'E-mail requerido': 'E-mail requerit',
    'Informe del órgano responsable (departament, instituto, ...)':
        "Informe de l'òrgan responsable (departament, institut,...",
    'Curriculum de profesorado externo': 'Culliculum de professorat extern',
    'En un único fichero zip si son varios':
        'En un únic fitxer zip si son varis',
    'Otra documentación relevante': 'Altra documentació rellevant',
    'Observaciones adicionales': 'Observacions addicionals',
    '¡El fichero no puede exceder de 10MB!':
        'El fitxer no pot superar els 10MB!',
    'Órgano proponente del curso': 'Òrgan proposant del curs',
    'Tipo de órgano': "Tipus d'òrgan",
    Órgano: 'Òrgan',
    Calendario: 'Cal·lendari',
    Edición: 'Edició',
    'Máximo de estudiantes': "Màxim d'estudiants",
    'Inicio / Fin de las clases': 'Inici/final de les classes',
    'Campo autocalculado': 'Camp auto-calculat',
    'Fecha Fin Preinscripción': 'Data final de preinscripció',
    'Mímino 15 días antes del inicio del curso':
        "Mínim 15 dies abans de l'inici del curs",
    'Entidades colaboradoras': 'Entitats col·laboradores',
    'Localización - Horarios': 'Localització - Horaris',
    'Recuerda que debes crear el plan de estudios con el mismo idioma elegido para el título propio.':
        "Recorda que deus crear el plà d'estudis amb el mateix idioma elegit per al títol propi",
    'Modalidad de impartición': "Modalitat d'impartició",
    Idioma: 'Idioma',
    'Lugar de impartición': "Lloc d'impartició",
    'Días de impartición y horarios': "Dies d'impartició i horaris",
    'El tipo de órgano es requerido': "El tipus d'òrgan és un camp requerit",
    'El órgano es requerido': "L'òrgan és un camp requerit",
    'La fecha es requerida': 'La data és un camp requerit',
    'No puede ser menor que el número mínimo de estudiantes':
        "No pot ser menor que el nombre mínim d'estudiants",
    'El presupuesto del título vinculado se gestiona desde el título "padre"':
        'El pressupost del títol vinculat es gestiona des del títol"pare"',
    'Total Prespuesto': 'Total Pressupost',
    'Total Ingresos': 'Total Ingressos',
    'Total Gastos': 'Total Despeses',
    Superavit: 'Superavit',
    Déficit: 'Dèficit',
    'Desglose Ingresos': 'Relació ingressos',
    'Número mínimo de estudiantes': "Nombre mínim d'estudiants",
    Patrocinios: 'Patrocinis',
    'Ingresos por hijos': 'Ingressos per fills',
    'Salarios Dirección ': 'Salaris Direcció',
    Salario: 'Salari',
    'Desglose de gastos': 'Relació de despeses',
    'Canon universitario': 'Cànon universitari',
    'Porcentaje Gestión Universitaria': 'Percentatge Gestió Universitària',
    'Puedes especificar uno distinto del general del':
        'Pots especificar un diferent del general del',
    'Gestión universitaria': 'Gestió universitària',
    'Fondo de becas': 'Fons de beques',
    Profesorado: 'Professorat',
    'Dirección (hasta ': 'Direcció (fins ',
    'Material docente': 'Material docent',
    'Viajes y dietas': 'Viatges i dietes',
    Otros: 'Altres',
    Seguros: 'Segurs',
    'Publicidad y promoción': 'Publicitat i promoció',
    'Actividades complementarias': 'Activitats complementàries',
    'Medios e instalaciones': 'Mitjans i instal·lacions',
    Título: 'Títol',
    'Máximo ${this.constants.OTHER_AMOUNT_MAX_PERCENT}% del total de ingresos':
        "Màxim ${this.constants.OTHER_AMOUNT_MAX_PERCENT}% del total d'ingressos",
    'La suma de salarios excede el máximo permitido':
        'La suma dels salaris excedeix el màxim permés',
    Asociar: 'Associar',
    'al título propio': 'al títol propi',
    'Añadir Director o Directora al título': 'Afegir Director o Directora al títol',
    'Escribe nombre, apellidos o NIF para elegir un Director UV':
        'Escriu nom, cognoms o NIF per elegir un/a Director/a UV',
    'Escribe nombre, apellidos o NIF para elegir un Director o Directora Externo/a':
        'Escriu nom, cognoms o NIF per elegir un/a Director/a extern',
    'Director/Directora UV': 'Director/a UV',
    'Director/Directora Externo': 'Director/a Extern/a',
    'Curso académico para el título': 'Curs acadèmic per al títol',
    'Duración en cursos académicos del título':
        'Duració en cursos acadèmics del títol',
    'No se recomienda superar los 60 ECTS por curso académico':
        'No es recomana superar els 60 ECTS per curs acadèmic',
    'Título vinculado': 'Títol vinculat',
    'Nombre para el título propio': 'Nom per al títol propi',
    'Profesor/a UV solicitante del título propio (UV)':
        'Professor/a UV sol·licitant del títol propi (UV)',
    'Directores o Directoras Universitat de València':
        'Directors o directores de la Universitat de València',
    'Añadir director o directora': 'Afegir director o directora',
    'Directores o Directoras Externos/as (si los hubiera)': 'Directors o Directores externs',
    'Añadir director o directora externo/a': 'Afegir Director o Directora extern/a',
    'Se borrará al profesor de la asignatura':
        "S'esborrará al professor de l'assignatura",
    'El año académico es requerido': "Camp 'any acadèmic' requerit",
    'La duración es requerida': 'La duració és requerida',
    'El título es requerido': 'El títol és requerit',
    'El director responsable de la uv es requerido':
        'El director o directora de la UV responsable és requerit',
    '${this.masterState.master_state.name':
        '${this.masterState.master_state.name',
    'Detalle resumen profesorado uv': 'Detall resum professorat UV',
    'Detalle resumen profesorado externo': 'Detall resum professorat extern',
    Dirección: 'Direcció',
    'Datos básicos': 'Dades bàsiques',
    'Otros datos': 'Altres dades',
    'Plan de estudios': "Plà d'estudis",
    Claustro: 'Claustre',
    Presupuesto: 'Pressupost',
    Anexos: 'Annexos',
    Presentar: 'Presentar',
    'El título propio no existe': 'El títol propi no existeix',
    'Ocurrió un error ': 'Ha ocorregut una errada',
    'Listado de títulos presentados': 'Llistat de títols presentats',
    Buscar: 'Cercar',
    'Se produjo un error realizando la operación.':
        "S'ha produït una errada realitzant la operació",
    'Presentar Título': 'Presentar títol',
    'Archivar Título': 'Arxivar títol',
    'Aprobar Título': 'Aprovar títol',
    'Presenta el título para su revisión por los técnicos':
        'Presenta el títol per a la seua revisió pels tècnics',
    'Al ser aprobado, no se permiten modificaciones': 'En ser aprovat, no es permeten modificacions',
    'Marcar el título como inactivo.': 'Marcar el títol com a inactiu.',
    'Marcar el título como borrador.': 'Marcar el títol com a esborrany.',
    'Marcar como Revisado': "Marcar com 'Revisat'",
    'Deja el título como "correcto" y no permite su posterior edición por parte del director':
        'Deixa el títol com "correcte" i no permet la posterior edició per part del director o directora',
    'Al marcar un título como borrador, este volverá a ser editable por el usuario y deberá pasar por el flujo normal de aprobación. Quieres continuar?': "En marcar un títol com a esborrador, aquest tornarà a ser editable per l'usuari i haurà de passar pel flux normal d'aprovació. Vols continuar?",
    'Pedir subsanación': 'Demanar subsanació',
    'Pide al director que realice modificaciones sobre el título.':
        'Demana al director o directora que realitze modificacions sobre el títol',
    'Alta en Sigue': 'Alta en Sigue',
    Memoria: 'Memòria',
    'La búsqueda de ': 'La cerca de ',
    ' no produjo ningún resultado': 'no produeix cam resultat',
    'Alta de título en SIGUE': 'Alta de títol en SIGUE',
    'Vas a traspasar el título al sistema SIGUE. Una vez traspasado el título no se podrá modificar. Quieres continuar?':
        'Vas a traspassar el títol al sistema SIGUE. Una vegada traspassat el títol no es podrà modificar. Vols continuar?',
    Si: 'Si',
    Cancelar: 'Cancel·lar',
    'Presentar título': 'Presentar títol',
    'Archivar título': 'Archive degree',
    'Aprobar título': 'Archive degree',
    'Al presentarlo, el título será revisado por los técnicos y dejará de ser editable para el director. Quieres continuar?':
        'Una vegada presentat, el títol serà revisat pels tècnics i deixarà de ser editable pel director o directora. Vols continuar?',
    'Marcar título como revisado': 'Marcar títol com revisat',
    'Al marcar un título como revisado, se dara como correcta la información asociada pudiendo presentar el título.  Quieres continuar?':
        'Al marcar un títol com a revisat es donarà per correcta la informació associada i es podrà presentar. Vols continuar?',
    'Pedir al director subsanar errores':
        "Demanar al director o directora subsanament d'errades",
    'Al pedir subsanación, el título pasará de nuevo a estado borrador y el director deberá volver a presentarlo. Quieres continuar?':
        'Al demanar una subsanació, el títol passarà a estat esborrany i la direcció deurà tornar a presentar-lo. Vols continuar?',
    'Título padre': 'Títol pare',
    Director: 'Director o Directora',
    Centro: 'Centre',
    ECTS: 'ECTS',
    Tipo: 'Tipus',
    'F. Fin Preinscripción': 'D. Final de preinscripció',
    Estado: 'Estat',
    'En Sigue': 'En SIGUE',
    'Relevancia académica': 'Rellevància acadèmica',
    'Objetivos del curso': 'Objectius del curs',
    'Requisitos de acceso y perfil de ingreso': "Requisits d'accés i perfil d'ingrés",
    'Salidas profesionales y/o perfil de egreso': 'Eixides professionals i/o perfil d\'ingrés',
    'Metodología general': 'Metodología general',
    'Ámbitos de conocimiento': 'Àmbits de coneixement',
    'Información para publicidad': 'Informació per a publicitat',
    'Información para publicidad: esta información aparecerá en la web': 'Informació per a publicitat: aquesta informació apareixerà en la web',
    'Rama de conocimiento': 'Branca de coneixement',
    'Escribe aquí el interés y la relevancia académica científica y profesional del curso':
        "Escriga ací l'interès i la rellevància acadèmica, científica i professional del curs",
    'Escribe aquí los objetivos del curso':
        'Escriga ací els objectius del curs',
    'Escribe aquí el perfil de estudiantes y requisitos de acceso':
        "Escriga ací el perfil de lestudiantat i els requisits d'accès",
    'Escribe aquí las salidas profesionales del curso':
        'Escriga ací les eixides professionals del curs',
    'Escribe aquí la metodología general del curso':
        'Escriga ací la metodología general del curs',
    'No se puede presentar el título.  Algún paso no ha sido completado correctamente.':
        'No es pots presentar el títol. Algun pas no ha sigut completat',
    'Presentar el título': 'Presentar el títol',
    'El título está ': 'El títol està',
    'Detalle - Asignaturas': 'Detall - Assignatures',
    'Resumen de horas impartidas y ECTS': "Resum d'hores impartides i ECTS",
    'Actualiza la lista de asignaturas del título vinculado':
        "Actualitza la llista d'assignatures del títol vinculat",
    'Actualizar asignaturas': 'Actualitzar assignatures',
    Nombre: 'Nom',
    'Tipo docencia': 'Tipus de docència',
    Prácticas: 'Pràctiques',
    'Crear asignatura': 'Crear una assignatura',
    'Modificar asignatura': 'Modificar una assignatura',
    'Nombre de la asignatura': "Nom de l'assignatura",
    'Año del título en el que se imparte la asignatura':
        "Any del títol en el qual s'imparteix l'assignatura",
    'Tipo de docencia': 'Tipus de docència',
    'Es trabajo fín de master': 'És treball final de màster',
    'El master ya tiene una asignatura fin de master.':
        'El màster ja te una assignatura final de màster',
    'Es asignatura de pŕacticas (curriculares)': 'És assignatura de pràctiques',
    'El nombre es requerido': 'El nom és requerit',
    'El tipo de docencia es requerido': 'El tipus de docència és requerit',
    'El número de ECTS es requerido': 'El nombre de ECTS és requerit',
    'El número mímino de ECTS es ': 'El nombre mínim  de ECTS és',
    'El número mímino de ECTS para el TFM es ':
        'El nombre mínim de ECTS per al TFM és',
    'Sólo se admite medio ECTS': "Sols s'admitexi mig ECTS",
    'El campo es requerido': 'El camp és requerit',
    'Detalle plan de estudios': "Detall plà d'estudis",
    'Añadir nueva asignatura': 'Afegir nova assignatura',
    'Actualiza asignaturas del título vinculado':
        'Actualitza assignatures del títol vinculat',
    'Eliminar asignatura': 'Eliminar assignatura',
    'Eliminar título': 'Eliminar Títol',
    'Guía docente': 'Guia docent',
    Profesores: 'Professorat',
    'Total Año ': 'Total Any',
    Total: 'Total',
    'Se eliminará la asignatura completa, con toda la información de profesores y plan de estudios.':
        "S'eliminarà l'assignatura completa. amb tota la informació de professorat i plà d'estudis.",
    Año: 'Any',
    'Horas min.': 'Hores mín.',
    'Horas max': 'Hores màx.',
    'Horas asignadas': 'Hores assignades',
    'Detalle profesorado asignatura: ': 'Detall professorat assignatura: ',
    'Volver a la lista de asignaturas': "Tornar a la llista d'assignatures",
    'Asociar profesor': 'Associar professorat',
    'Hs. Míminas': 'Hs. Mínimes',
    'Hs. Máximas': 'Hs. Máximes',
    'Total Asignadas: ': 'Total Assignades:',
    'Modificar datos': 'Modificar dades',
    'Eliminar asociación con profesor': "Eliminar l'associació amb professorat",
    Profesor: 'Professor o professora',
    Origen: 'Origen',
    Responsable: 'Responsable',
    'Docencia teórica': 'Docència teòrica',
    Horas: 'Hores',
    '€/hora': '€/hora',
    'Docencia práctica': 'Docència pràctica',
    'Tutorías obligatorias': 'Tutories obligatòries',
    'Guía docente de la Asignatura:': "Guia docent de l'assignatura",
    'Resumen de la asignatura': "Resumen de l'assignatura",
    'Resultado del aprendizaje de la asignatura':
        "Resultat de l'aprenentatge de l'assignatura",
    'Metodología del aprendizaje': "Metodologia de l'aprenentatge",
    'Sistemas de evaluación de la asignatura':
        "Sistemes d'avaluació de l'assignatura",
    'Bibliografía de la asignatura': "Bibliografía de l'assignatura",
    'Descriptores del temario de la asignatura':
        "Descriptors del temari de l'assignatura",
    'Inicio de la docencia': 'Inici de la docència',
    'Finalización de la docencia': 'Finalització de la docència',
    'Escribe aquí el resumen de la asignatura':
        "Escriga ací el resul de l'assignatura",
    'Escribe aquí el resultado del aprendizaje':
        "Escriga ací el resultat de l'aprenentatge",
    'Escribe aquí la metodología de aprendizaje de la asignatura':
        "Escriga ací la metodologia d'aprenentatge de l'assignatura",
    'Escribe aquí los sistemas de evaluación de la asignatura':
        "Escriga ací els sistemes d'avaluació de l'assignatura",
    'Escribe aquí la biblografía de la asignatura':
        "Escriga ací la bibliografia de l'assignatura",
    'Escribe aquí los descriptores del temario de la asignatura':
        "Escriga ací els descriptors del temari de l'assignatura",
    'El temario no puede estar vacío.': 'El temari no pot estar buit',
    'El título es un ': 'El títol és un',
    'El título no tiene asignado ningún tipo de titulación':
        'El títol no té assignat cap típus de titulació',
    ' Año ': 'Any',
    'Nuevo Profesor': 'Nou professor o professora',
    'Modificar profesor': 'Modificar professor o professora',
    Apellidos: 'Cognoms',
    NIF: 'NIF',
    'NIF sin guiones, sólo números y letra':
        'NIF sense guins, sols nombres i lletra',
    'E-mail': 'E-mail',
    'Fecha de nacimiento': 'Data de naixement',
    Teléfono: 'Telèfon',
    'Es un profesor de otra universidad distinta a la UV':
        "És un professor o professora d'altra universitat distinta a la UV",
    'Es un profesor que trabaja en una empresa':
        'És un professor o professora que treballa a una empresa',
    'Es persona física': 'És persona física',
    'Nuevo Profesor UV': 'Nou professor o professora UV',
    'Modificar profesor UV': 'Modificar professor o professora UV',
    Departamento: 'Departament',
    'Categoría profesional': 'Categoria professional',
    'Área de conocimiento': 'Àrea de coneixemenet',
    'Asociar Profesor a asignatura': 'Associar professorat a assignatura',
    'Modificar Relación Profesor - Asignatura':
        'Modificar relació professorat - assignatura',
    'Nuevo Profesor Externo': 'Nou professor/a extern',
    'Modificar datos profesor externo': 'Modificar dades professorat extern',
    'Nuevo P.D.I/P.A.S UV': 'Nou P.D.I/P.A.S. UV',
    'Modificar datos P.D.I/P.A.S UV': 'Modificar dades P.D.I/P.A.S UV',
    'Elige un profesor UV': 'Elegix un professor o una professora UV',
    'Elige un profesor externo a la UV':
        'Elegix un professor o una professora externa a la UV',
    'Es responsable': 'És responsable',
    'TTodas las asignaturas tienen un profesor responsable que es la persona que firmará el acta. Caso de no ser profesor uv, el acta la firmará el director':
        "Totes les assignatures tenen un/a professor/a responsable que és la persona que signarà l'acta. Si no és de la UV, l'acta l'ha de signar el director o directora",
    'Número de horas': "Nombre d'hores",
    '€ / hora': '€ / hora',
    'El profesor ya está asociado a la asignatura':
        "El/la professor/a ja està associat a l'assignatura",
    'Sólo se admiten medias horas': "Sols s'admeten miges hores",
    'Sólo se admiten mitades (0,5)': "Sols s'admeten miges (0,5)",
    'Superado el máximo permitido de': 'Superat el màxim permès de',
    'Coste Total profesorado': 'Cost total professorat',
    'Horas Docencia ': 'Hores docència',
    '% horas docencia profesorado ': '% hores docència professorat',
    'Coste Total': 'Cost total',
    'Crear título propio vinculado': 'Crear títol propi vinculat',
    "Elige el título propio 'padre'": "Elegix títol propi 'pare'",
    "Nombre para el nuevo título propio vinculado ('hijo')":
        "Nom per al nou títol propi vinculat ('fill')",
    'El nombre debe ir precedido del tipo de título (Master de Formación Permanente en..., Experto universitario en...). Ejemplos:':
        "El nom ha d'anar precedit del tipus de títol (Màster de Formació Permanet en ..., Expert universitari en...) Exemples:",
    'El nombre debe ir precedido del tipo de título (Microcredencial en..., Certificado Universitario en...). Ejemplos:': "El nom ha d'anar precedit del tipus de títol (Microcredencial en ..., Expert universitari en...) Exemples:",
    'Diploma de Especialización': "Diploma d'especialització",
    'Derecho de la economía digital': 'Dret de la economia digital',
    'Certificado Universitario ': 'Certificat de formació contínua',
    'Documentos digitales y archivos': 'Documents digitals i arxius',
    'Experto Universitario': 'Expert Universitari',
    'en "Startup Management"': 'en"Startup Management"',
    'No puede ser mayor que el número máximo de estudiantes':
        "No pot ser major que el nombre màxim d'estudiants",
    'Crear título propio': 'Crear títol propi',
    'Crear título': 'Crear títol',
    'Crear título - Formación Contínua': 'Crear títol - Formació Contínua',
    'Crear Título de Formación Contínua': 'Crear Títol de Formació Contínua',
    'Curso académico de inicio para el título':
        "Curs acadèmic d'inici per al títol",
    'Master de formación permanente en ': 'Màster de formació permanet  en',
    "Microcredential ": "Microcredencial",
    'Terapia Manual: Enfoque- Neuro-Ortopédico Clínico':
        'Teràpia Manual: Enfocament Neuro-Ortoèdic Clínic',
    'Fichero vacío': 'Fitxer buit',
    'master unknown': 'màster unknown',
    'Asignaturas obligatorias': 'Assignatures obligatòries',
    'Prácticas Externas': 'Pràctiques Externes',
    'Trabajo Final de Máster (TFM)': 'Treball Final de Màster (TFM)',
    Español: 'Castellà',
    Valencià: 'Valenciá',
    Draft: 'Esborrany',
    Revised: 'Revisat',
    Published: 'Presentat',
    Approved: 'Aprovat',
    Archived: 'Arxivat',
    'published with errors': 'Publicat amb errades',
    Correcting: 'Esmena',
    Corrected: 'Esmenat',
    'Master': 'Màster de Formació Permanent',
    'University Expert': 'Expert Universitari',
    'Specialization Diploma': "Diploma d'Especialització",
    'University Certificate': 'Certificat Universtari Formació Contínua',
    Center: 'Centre',
    Department: 'Departament',
    'Research Institute': "Institut d'Investigació",
    'Art and Humanities': 'Arts i Humanitats',
    Science: 'Ciències',
    'Health Science': 'Ciències de la Salut',
    'Social and Legal Science': 'Ciències Socials i jurídiques',
    'Engineering and Architecture': 'Enginyeria i Arquitectura',
    'Business Management': 'Direccció i Gestió empresarial',
    'Social and Legal': 'Jurídic i Social',
    Humanities: 'Humanitats',
    'Education Science': "Ciències de l'educació",
    Psychology: 'Psicologia',
    'Environmental Health and Security': 'Seguretat, Salut i Mediambient',
    Health: 'Salut',
    'Science and Technology': 'Ciència i tecnologia',
    'Physical Activity and Sport': 'Activitat física i esport',
    Spanish: 'Castellà',
    English: 'Anglès',
    'Online master': 'No presencial (on-line)',
    'Face To Face': 'Presencial',
    'Semi Face To Face': 'Semi-presencial',
    'Código UV del título': 'Codi UV del títol',
    'Otro órgano proponente del curso (OPCIONAL)':
        'Altre òrgan proposant del curs (OPCIONAL)',
    'Dado que se trata de un profesor UV no funcionario, contemplar en los honorarios del profesor el pago destinado a la seguridad social.': `Atés que es tracta d'un professor UV no funcionari, contemplar en els honoraris del professor el pagament destinat a la seguretat social.`,
    'Un curso académico': 'Un curs acadèmic',
    'Dos cursos académicos': 'Dos cursos acadèmics',
    'Tres cursos académicos': 'Tres cursos acadèmics',
    'Un fichero pdf': 'Un fitxer pdf',
    'Curso 2021/2022': 'Curs 2021/2022',
    'Acuerdo para realización de títulos propios (profesorado UV)':
        'Acord per a realització de títols propis (professorat UV)',
    'No se puede presentar el título. Algún paso no ha sido completado correctamente.':
        'No es pot presentar el títol. Algun pas no ha sigut completat correctament.',
    ' Modificar / Revisar título': 'Modificar / Revisar títol',
    'Se eliminará el título con todos sus datos. Estás seguro/a?':
        "S'eliminarà el títol amb totes les seues dades. Estàs segur/a?",
    'El plazo de presentación ha finalizado.':
        'El termini de presentació ha finalitzat',
    'Agrupar padres e hijos': 'Agrupar pares i fills',
    'El tipo de fichero es obligado': 'El tipus de fitxer és obligat',
    'Modelo 83': 'Model 83',
    'Currículums profesores externos': 'Currículums professors externs',
    'Puedes obtener un fichero zip con todos los ficheros del modelo 83 de todos los títulos de un curso académico pinchando en "Generar Fichero"':
        'Pots obtindre un fitxer zip amb tots els fitxers del model 83 de tots els títols d\'un curs acadèmic punxant en "Generar Fichero"',
    'Filtrar por curso académico': 'Filtrar per curs acadèmic',
    'Importar título de otro curso académico':
        "Importar títol d'un altre curs acadèmic",
    'Curso académico': 'Curs acadèmic',
    "Selecciona un título de la lista y pincha en 'Crear título'":
        "Selecciona un títol de la llista i punxa a 'Crear títol'",
    'Título importado con éxito': 'Títol importat amb èxit',
    'Se creará un nuevo título idéntico al seleccionado para el curso académico actual. Quieres continuar?':
        'Es crearà un nou títol idèntic al seleccionat per al curs acadèmic actual. Estàs segur?',
    'Revisión de cambios': 'Revisió de canvis',
    'Orden': 'Ordre',
    'Requerido': 'Requerit',
    'Abajo': 'A baix',
    'Arriba': 'A dalt',
    'Pincha y arrastra la fila para ordenar': 'Punxa i arrossega la fila per a ordenar',
    'Precio general': 'Preu general',
    'Escribe una contraseña para tu cuenta': 'Escriu una nova contrasenya per al teu compte',
    'Error estableciendo la contraseña': 'Error al canviar la contrasenya',
    'Vuelve a introducir la contraseña': 'Torna a escriure la contrasenya',
    'Establecer contraseña': 'Establir contrasenya',
    'La contraseña se ha establecido correctamente. Ya puedes iniciar sesión en la aplicación Solicitudes para gestionar tus títulos.': 'La contrasenya s\'ha establit correctament. Ja pots iniciar sessió en l\'aplicació Sol·licituds per a gestionar els teus títols.',
    'Solicitud de cambio de contraseña': 'Sol·licitud de canvi de contrasenya',
    'Esta opción sólo es válida para usuarios externos a UV.': 'Aquesta opció només és vàlida per a usuaris externs a UV.',
    'Error al solicitar cambio de contraseña': 'Error en sol·licitar canvi de contrasenya',
    'Correo electrónico de acceso de la aplicación': 'Correu electrònic d\'accés de l\'aplicació',
    'Enviar solicitud de cambio de contraseña': 'Enviar sol·licitud de canvi de contrasenya',
    'He olvidado mi contraseña': 'He oblidat la meua contrasenya',
    'Las contraseñas deben cumplir los siguientes requisitos:': 'Les contrasenyes han de complir els següents requisits:',
    'Debe tener al menos 6 caracteres': 'Ha de tindre almenys 6 caràcters',
    'Debe contener una mayúscula': 'Ha de contindre una majúscula',
    'Debe contener un número': 'Ha de contindre un número',
    'Debe contener un carácter especial [!@#$%*]': 'Ha de contindre un caràcter especial [!@#$%*]',
    'Correo electrónico':'Correu electrònic',
    'Se ha enviado un correo a tu cuenta con el enlace para establecer tu contraseña': 'S\'ha enviat un correu al teu compte amb l\'enllaç per a establir la teua contrasenya',
    'Sincronización con Sigue': 'Sincronització amb Sigue',
    'TEACHER_OTHER_UNIV': 'Professors d\'altres universitats',
    'TEACHER_EXTERNAL': 'Professors Externs',
    'CENTER': 'Centres',
    'TEACHER_UV': 'Professors UV',
    'COMPANY': 'Empreses',
    'DEPARTMENT': 'Departaments',
    'PROFESSIONAL_CATEGORY': 'Categoria Professional',
    'Nuevos': 'Nous',
    'Modificados': 'Modificats',
    'Refrescar': 'Refrescar',
    'Generar ficheros Zip': 'Generar fitxers zip',
    'No existen sincronizaciones.': 'No existeixen sincronitzacions.',
    'Copiar datos desde Sigue': 'Copiar dades des de Sigue',
    'Esta operación tardará un poco. Ten paciencia...': 'Aquesta operació tardarà una mica. Tingues paciència...',
    'Volcar datos a Solicitudes': 'Bolcar dades a Sol·licituds',
    'Una vez comprobados los datos que vas a actualizar (nuevos y modificaciones), quieres volcar esta información a la base de datos de Solicitudes. Esta acción no tiene vuelta atrás. Quieres continuar?': 'Una vegada comprovats les dades que actualitzaràs (nous i modificacions), vols bolcar aquesta informació a la base de dades de Sol·licituds. Aquesta acció no té volta arrere. Vols continuar?',
    'Se copiaron los datos con éxito': 'Es van copiar les dades amb èxit',
    'Sincronización iniciada el ': 'Sincronització iniciada el ',
    'Última sincronización realizada el ': 'Última sincronització realitzada el ',
    'Nueva sincronización (volver a copiar datos desde Sigue)': 'Nova sincronització (tornar a copiar dades des de Sigue)',
    'Pendiente de volcar a solicitudes.': 'Pendent de bolcar a sol·licituds.',
    'Volver a copiar datos desde Sigue': 'Tornar a copiar dades des de Sigue',
    'Toda la información está sincronizada entre Sigue y Solicitudes.': 'Tota la informació està sincronitzada entre Sigue i Sol·licituds.',
    'Generar Fichero': 'Generar fitxer',
    'Tipo de Fichero': 'Tipus de fitxer',
    "Ejemplo: Médico forense": "Exemple: Mèdic forense",
    "Universidad": "Universitat",
    "Si no encuentras la universidad que buscas, puedes enviar un correo solicitando su creación a": "Si no trobes la universitat que cerques, pots enviar un correu sol·licitant la seua creació a",
    "Cargo": "Càrrec",
    "Categoría / Cargo": "Categoria / Càrrec",
    "Cargo en la empresa": "Càrrec en l'empresa",
    "La categoría profesional del director del título no permite presentar este tipo de título.": "La categoria professional del director del títol no permet presentar aquest tipus de títol",
    "El título no se podrá presentar ya que la categoría profesional del director del título (": "El títol no es podrà presentar ja que la categoria professional del director del títol (",
    ") no permite crear títulos de tipo": ") no permet crear títols de tipus",
    'No se puede establecer el tipo de título a partir de sus ECTS. Revisa las asignaturas para que a partir de la suma de ECTS pueda establecerse el tipo de título.': 'No es pot establir el tipus de títol a partir dels seus ECTS. Revisa les assignatures perquè a partir de la suma de ECTS puga establir el tipus de títol.',
    'No se puede publicar el título. ': 'No es pot publicar el títol. ',
    'No todos los directores son profesores.': 'No tots els directors són professors.',
    "Es modular": "És modular",
    "Es módulo del título...": "És mòdul del título...",
    "Es módulo de ": "És mòdul de ",
    "Solo se muestran títulos modulares": "Només es mostren títols modulars",
    "Un título modular necesita al menos dos tarifas. Una \"general\" y otra para el caso de cursarlo por módulos.": "Un títol modular necessita almenys dos tarifes. Una \"general\" i una altra per al cas de cursar-ho per mòduls",
    "Asignaturas optativas a cursar": "Assignatures optatives a cursar",
    "ECTS por asignatura Optativa": "ECTS per assignatura Optativa",
    "Para poder asociar al título asignaturas optativas, se requiere autorización. Ponte en contacto en ": "Per a poder associar al títol assignatures optatives, es requerix autorització. Posa't en contacte en ",
    "Optativa": "Optativa",
    "FORMACIÓN CONTÍNUA": "FORMACIÓ CONTÍNUA",
    "Títulos Propios y Formación Contínua UV": "Títols Propis i Formació Contínua UV",
    "Crear Título Propio": "Crear Títol Propi",
    "Máster de formación permanente, Diploma de especialización, Experto Universitario": "Màster de formació permanent, Diploma d'especialització, Expert Universitari",
    "Microcredencial, Certificado": "Microcredencial, Certificat",
    "El título no tiene ninguna asignatura.": "El títol no té cap assignatura",
    "Configurar asignaturas optativas": "Configurar assignatures optatives",
    "Configurar optativas": "Configurar optatives",
    "Al menos un codirector debe tener la categoría profesional adecuada para presentar el título.": "Almenys un codirector ha de tindre la categoria professional adequada per a presentar el títol.",
    "Para poder marcar una asignatura como Optativa, primero configura las asignaturas optativas en la pantalla anterior.": "Per a poder marcar una assignatura com a Optativa, primer configura les assignatures optatives en la pantalla anterior. ",
    "Declaración responsable de la dirección sobre claustro": "Declaració responsable de la direcció sobre el claustre",
    "Puedes descargar la plantilla del documento \"Declaración responsable de la dirección sobre claustro\" ya generada con los datos del título, pinchando en el botón \"Generar Modelo\".": "Pots descarregar la plantilla del document \"Declaració responsable de la direcció sobre claustre\" ja generada amb les dades del títol, punxant en el botó 'Generar Model'.",
    "Una vez generado, deberás firmar el documento con tu firma digital y subirlo a esta plataforma.": "Una vegada generat, hauràs de signar el document amb la teua signatura digital i pujar-lo a aquesta plataforma.",
    "Para firmar un documento con tu firma digital, puedes utilizar varias aplicaciones:": "Per a signar un document amb la teua signatura digital, pots utilitzar diverses aplicacions:",
    "Siguiendo estas instrucciones parar firmar.": "Seguint aquestes instruccions per a signar.",
    "Siguiendo los pasos solicitados al arrancar la aplicación.": "Seguint els passos sol·licitats en arrancar l'aplicació.",
    "Convertir a formación contínua": "Convertir a formació contínua",
    "Escribe aquí para filtrar": "Escriu ací per a filtrar",
    "Elige uno o varios estados": "Tria un o més estats",
    "Títulos": "Títols",
    "DEFAULT": "Títol propi",
    "CONTINOUS_LEARNING": "Formació contínua",
    "La suma de salarios excede el máximo permitido": "La suma de salaris excedeix el màxim permés",
    "Servicios E-learning Adeit": "Serveis elearning Adeit",
    "Profesores inactivos": "Professors inactius",
    "Inactivo": "Inactiu",
    "El profesor o profesora está inactivo o inactiva actualmente. Elige de la lista el mismo profesor o profesora, pero con la información actualizada.": "El professor o professora està inactiu o inactiva actualment. Tria de la llista el mateix professor o professora, però amb la informació actualitzada.",
    "El profesor o profesora está inactivo o inactiva": "El professor o professora està inactiu o inactiva actualment",
    "Elige un profesor o una profesora de la lista": "Tria un professor o professora de la llista",
    "Elige el mismo profesor o profesora, pero con la información actualizada": "Tria el mateix professor o professora, , però amb la informació actualitzada ",
    "Algún director o directora del título no se encuentra activo actualmente. Elimina al director o directora marcado como 'inactivo' y vuélvelo a añadir con sus datos actualizados. En caso de que no exista el director o directora póngase en contacto con el soporte técnico.": "Algun director o directora del títol no es troba actiu actualment. Elimina al director o directora marcat com a \"inactiu\" i torna'l a afegir amb les seues dades actualitzades. En cas que no existisca el director o directora pose's en contacte amb el suport tècnic.",
    "Copiar un título propio del curso académico anterior": "Copiar un títol propi del curs acadèmic anterior",
    "Copiar un título de formación contínua del curso académico anterior": "Copiar un títol de formació contínua del curs acadèmic anterior",
    "Marcar título como archivado": "Marcar títol com arxivat",
    "Marcar título como borrador.": "Marcar títol com a esborrany.",
    'Marcar como Borrador': 'Marcar com a Esborrany',
    "Marcar título como aprobado": "Marcar títol com aprovado",
    "Al archivar un título, este quedará como inactivo, no pudiendo realizar modificaciones sobre él. Quieres continuar?": "En arxivar un títol, aquest quedará com a inactiu, no podent realitzar modificacions sobre ell. Vols continuar?",
    "Aprobar un título supone que todos los datos has sido revisados y aprobados como definitivos. No se permitirán modificaciones posteriores. Quieres continuar?": "Aprovar un títol suposa que totes les dades has sigut revisats i aprovats com a definitius. No es permetran modificacions posteriors. Vols continuar?",
    "Profesorado - Horas Curso Académico": "Professorat - Hores Curs Acadèmic",
    'Detalle de asignaturas(en rojo no suman al estar vinculadas a otro título)': "Detall d'assignatures(en roig no sumen en estar vinculades a un altre títol)",
    "Generar todas mas memorias(pdf)": "Generar totes les memòries(pdf)",
    "Nível MECES/EQF": "Nívell MECES/EQF",
    "Criterios de admisión": "Criteris d'admissió",
    "Escribe aquí los criterios de admisión": "Escriu ací els criteris d'admissió",
    "Resultados del proceso de formación y aprendizaje": "Resultats del procés de formació i aprenentatge",
    "Para cumplir con las exigencias del RD-L 2/2023, de 16 de marzo (Disposición adicional quincuagésima segunda), en el caso de los cursos que piden la realización de prácticas en empresas, se deberá incluir el apartado correspondiente al presupuesto en la memoria de solicitudes los gastos correspondientes a la inclusión de los estudiantes en el sistema de la Seguridad Social, siempre que la empresa en cuestión no disponga de una bolsa de trabajo destinada a tal efecto.": "Per tal de complir amb les exigències del RD-L 2/2023, de 16 de març\n" +
        "(Disposició addicional cinquantena segona), en el cas dels cursos que\n" +
        "demanen la realització de pràctiques en empreses, es deurà incloure\n" +
        "l’apartat corresponent al pressupost en la memòria de sol·licituds les\n" +
        "despeses corresponents a la inclusió dels estudiants en el sistema de la\n" +
        "Seguretat Social, sempre que la empresa en qüestió no dispose d’una\n" +
        "borsa de treball destinada a tal efecte."
}
