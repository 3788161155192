
import Vue from 'vue';
import Teachers from './Teachers.vue';
import TeachersTotalHours from './TeachersTotalHours.vue';
import TeachersInactive from '@/components/back-office/TeachersInactive.vue';

export default Vue.extend({
    name: 'TeachersTab',
    components: {
        Teachers,
        TeachersTotalHours,
        TeachersInactive,
    },
    data() {
        return {
            currentTab: null,
        };
    },
});
