
import Vue from 'vue';
import HomeMastersUv from '@/components/HomeMastersUv.vue';
import Component from 'vue-class-component';

@Component({
    components: {
        HomeMastersUv
    }
})
export default class Home extends Vue {}
