var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-list-item',{key:_vm.teacher.id,attrs:{"disabled":_vm.isCurrentMasterDisabled}},[_c('v-list-item-avatar',{attrs:{"color":_vm.getColor(),"size":"36"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.getInitials(_vm.teacher)))])]),(_vm.teacher.teacher_type_code === 'uv')?_c('v-list-item-content',[_c('v-list-item-title',[(_vm.teacher.source_id == null)?_c('v-chip',{attrs:{"x-small":"","color":_vm.teacher.teacher_type_code === 'uv' ? 'green' : 'red',"text-color":"white"}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("mdi-star")]),_vm._v(" "+_vm._s(_vm.$t('nuevo'))+" ")],1):_vm._e(),(!_vm.teacher.active)?_c('v-chip',{staticClass:"mr-4",attrs:{"x-small":"","color":"orange","text-color":"white"}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("mdi-star")]),_vm._v(" "+_vm._s(_vm.$t('Inactivo'))+" ")],1):_vm._e(),(_vm.teacher.email != null)?_c('span',{class:_vm.teacher.active ? '' : 'text-decoration-line-through'},[_vm._v(" "+_vm._s(`${_vm.teacher.surname}, ${_vm.teacher.name} (${_vm.teacher.email})`)+" ")]):_c('span',[_c('strong',{staticClass:"red--text text--lighten-1",class:_vm.teacher.active ? '' : 'text-decoration-line-through'},[_vm._v(_vm._s(`${_vm.teacher.surname}, ${_vm.teacher.name}`)+" - ("+_vm._s(_vm.$t('sin email'))+") ")])])],1),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(
                `${_vm.teacher.professional_category || ' '} - ${
                    _vm.teacher.department ? _vm.teacher.department.name : '-'
                }`
            )}}),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(`${_vm.teacher.center ? _vm.teacher.center.name : ' - '}`))])],1):_c('v-list-item-content',[_c('v-list-item-title',[(_vm.teacher.source_id == null)?_c('v-chip',{attrs:{"x-small":"","color":_vm.teacher.teacher_type_code === 'ext' ? 'red' : 'green',"text-color":"white"}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("mdi-star")]),_vm._v(" "+_vm._s(_vm.$t('nuevo'))+" ")],1):_vm._e(),(!_vm.teacher.active)?_c('v-chip',{staticClass:"mr-4",attrs:{"x-small":"","color":"orange","text-color":"white"}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("mdi-star")]),_vm._v(" "+_vm._s(_vm.$t('Inactivo'))+" ")],1):_vm._e(),(_vm.teacher.email != null)?_c('span',{class:_vm.teacher.active ? '' : 'text-decoration-line-through'},[_vm._v(" "+_vm._s(`${_vm.teacher.surname}, ${_vm.teacher.name} (${_vm.teacher.email})`)+" ")]):_c('span',[_c('strong',{staticClass:"red--text text--lighten-1",class:_vm.teacher.active ? '' : 'text-decoration-line-through'},[_vm._v(_vm._s(`${_vm.teacher.surname}, ${_vm.teacher.name}`)+" - ("+_vm._s(_vm.$t('sin email'))+") ")])])],1),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.getExternalTeacherSubtitle(_vm.teacher))+" ")])],1),(
            _vm.deleteIcon &&
                !_vm.teacherIdsNoDelete.includes(_vm.teacher.id.toString())
        )?_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.isCurrentMasterDisabled,"icon":""},on:{"click":function($event){return _vm.$emit('delete', _vm.teacher)}}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("delete")])],1)]}}],null,false,3237895491)},[_c('span',[_vm._v(_vm._s(_vm.$t('Eliminar ')))])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }