// @ts-ignore
import axios, {AxiosPromise} from 'axios';
// @ts-ignore
import URI from 'urijs';

export const sync = {
    async getTableSyncLog(table: string, operation: string, options: object) {
        const url = new URI(`${process.env.VUE_APP_API_URL}sync/log/${table}/${operation}`);

        for( const [key, value] of Object.entries(options)) {
            url.addQuery({ [key]: value });
        }

        return axios.get(url.valueOf()) as Promise<any>;
    },
    async getSyncSummary() {
        const url = new URI(`${process.env.VUE_APP_API_URL}sync/summary`);

        return axios.get(url.valueOf()) as Promise<any>;
    },
    async copyFromSigue() {
        const url = new URI(`${process.env.VUE_APP_API_URL}sync/copy-from-sigue`);

        return axios.post(url.valueOf()) as Promise<any>;
    },
    async persistsChangesToProduction() {
        const url = new URI(`${process.env.VUE_APP_API_URL}sync/persist-changes-to-production`);

        return axios.post(url.valueOf()) as Promise<any>;
    }
};
