/*
 *  Copyright 2017-2018 Enrique Araque Vilches
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */

// @ts-ignore
import * as api from '@/api/index';
// @ts-ignore
import * as mutation_types from '@/vuex/mutation_types';
import {ActionTree} from 'vuex';
import {RootState} from '../../types';
import {AuthenticationState, LoginResponse} from './types';
// @ts-ignore
import {authentication} from '@/services';
import {initSession} from '../../../helpers/session';

export const actions: ActionTree<AuthenticationState, RootState> = {
    login: async function(
        { commit, dispatch },
        { login, password }
    ): Promise<any> {
        try {
            const appUser: LoginResponse = await authentication.login(
                login, // export all api modules
                password
            );

            await dispatch('setAppUser', { appUser });

            await initSession();
        } catch (e) {
            commit(mutation_types.LOGIN_ERROR, e.response);
            throw e;
        }
    },
    refresh: async function(
        { commit, dispatch },
    ): Promise<any> {
        try {
            const appUser: LoginResponse = await authentication.refresh();

            await dispatch('setAppUser', { appUser });

            await initSession();
        } catch (e) {
            commit(mutation_types.LOGIN_ERROR, e.response);
            throw e;
        }
    },
    setAppUser: async function({ commit }, { appUser }): Promise<any> {
        try {
            commit(mutation_types.LOGIN, appUser);
        } catch (e) {
            commit(mutation_types.LOGIN_ERROR, e);
            throw e;
        }
    },
    logout: async function({ commit, dispatch }): Promise<any> {
        try {
            authentication.logout(); // clear localStorage
            await dispatch('setAppUser', { appUser: {} });
        } catch (e) {
            commit(mutation_types.LOGIN_ERROR, e);
            throw e;
        }
    },
    resetPassword: async function({ commit }, { password, newPasswordToken }) {
        try {
            console.log('reset_password')

            await api.authentication.resetPassword(password,newPasswordToken);
        } catch (e) {
            commit(mutation_types.LOGIN_ERROR, e.response);
            throw e;
        }
    }
};
