/*
 *  Copyright 2017-2018 Enrique Araque Vilches
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
import {GetterTree} from 'vuex';
import {RootState} from '../../types';
import {MasterState} from './types';
import {IMaster} from '../../../interfaces/master/interfaces/models/IMaster';
import {IAcademicYear} from '../../../interfaces/master/interfaces/models/IAcademicYear';
import {IProposalOrganizationType} from '../../../interfaces/master/interfaces/models/IProposalOrganizationType';
import {ICenter} from '../../../interfaces/teacher/interfaces/models/ICenter';
import {IDepartment} from '../../../interfaces/teacher/interfaces/models/IDepartment';
import {ITeachingType} from '../../../interfaces/master/interfaces/models/ITeachingType';
import {IKnowledgeBranch} from '../../../interfaces/master/interfaces/models/IKnowledgeBranch';
import {IKnowledgeField} from '../../../interfaces/master/interfaces/models/IKnowledgeField';
import {ISubject} from '../../../interfaces/subject/interfaces/models/ISubject';
import {ITeacher} from '../../../interfaces/teacher/interfaces/models/ITeacher';
// @ts-ignore
import i18n from '@/lang';
// @ts-ignore
import * as helpers from '@/helpers';
import {IMasterType} from '../../../interfaces/master/interfaces/models/IMasterType';
import {IMasterState} from '../../../interfaces/master/interfaces/models/IMasterState';
import {IFee} from '../../../interfaces/master/interfaces/models/IFee';
import {MASTER_KIND} from '../../../helpers';

export const getters: GetterTree<MasterState, RootState> = {
    getData: (state: MasterState): IMaster => state.data,
    getAcademicYears: (state: MasterState): IAcademicYear[] => {
        // @ts-ignore
        if (state.enumerates != null) {
            const ordered = state.enumerates.academic_years.sort(
                (a: IAcademicYear, b: IAcademicYear) =>
                    b.name.localeCompare(a.name)
            );
            return helpers.translateFieldArray(ordered, 'name');
        } else {
            return [];
        }
    },
    getOpenedAcademicYearsOwnDegrees: (state: MasterState, getters): IAcademicYear[] => {
        return getters.getAcademicYears.filter((academicYear: IAcademicYear) => !academicYear.od_closed)
    },
    getOpenedAcademicYearsContinuousLearningDegrees: (state: MasterState, getters): IAcademicYear[] => {
        return getters.getAcademicYears.filter((academicYear: IAcademicYear) => !academicYear.cl_closed)
    },
    getCurrentAcademicYear: (state: MasterState, getters): IAcademicYear => {
        return getters.getAcademicYears[0];
    },
    getPreviousAcademicYear: (state: MasterState, getters): IAcademicYear => {
        return getters.getAcademicYears[1];
    },
    getProposalOrganizationTypes: (
        state: MasterState
    ): IProposalOrganizationType[] => {
        if (state.enumerates == null) {
            return [];
        }

        return helpers.translateFieldArray(
            state.enumerates.proposal_organization_types,
            'name'
        );
    },
    getCenters: (state: MasterState): ICenter[] =>
        state.enumerates ? state.enumerates.centers : [],
    getResearchInstitutes: (state: MasterState): ICenter[] =>
        state.enumerates ? state.enumerates.research_institutes : [],
    getDepartments: (state: MasterState): IDepartment[] =>
        state.enumerates ? state.enumerates.departments : [],
    getTeachingTypes: (state: MasterState): ITeachingType[] => {
        if (state.enumerates == null) {
            return [];
        }

        return helpers.translateFieldArray(
            state.enumerates.teaching_types,
            'name'
        );
    },
    getKnowledgeBranches: (state: MasterState): IKnowledgeBranch[] => {
        if (state.enumerates == null) {
            return [];
        }
        return helpers.translateFieldArray(
            state.enumerates.knowledge_branches,
            'name'
        );
    },
    getKnowledgeFields: (state: MasterState): IKnowledgeField[] => {
        if (state.enumerates == null) {
            return [];
        }
        return helpers.translateFieldArray(
            state.enumerates.knowledge_fields,
            'name'
        );
    },
    getLangs: (state: MasterState): IKnowledgeField[] => {
        if (state.enumerates == null) {
            return [];
        }
        return helpers.translateFieldArray(state.enumerates.langs, 'name');
    },
    getMasterTypes: (state: MasterState): IMasterType[] => {
        if (state.enumerates == null) {
            return [];
        }

        return helpers.translateFieldArray(
            state.enumerates.master_types,
            'name'
        );
    },
    getMasterTypesAsObject: (state: MasterState): object => {
        if (state.enumerates == null) {
            return [];
        }

        const arr = helpers.translateFieldArray(
            state.enumerates.master_types,
            'name'
        );

        return helpers.arrayToObject(arr, 'code', 'name');
    },
    getMasterStates: (state: MasterState): IMasterState[] => {
        if (state.enumerates == null) {
            return [];
        }
        return helpers.translateFieldArray(
            state.enumerates.master_states,
            'name'
        );
    },
    getMasterStatesObject: (state: MasterState): IMasterState[] => {
        if (state.enumerates == null) {
            return [];
        }

        return helpers.arrayToObject(
            state.enumerates.master_states,
            'code',
            'code'
        );
    },
    getProfessionalCategories: (state: MasterState): ProfessionalCategory[] => {
        if (state.enumerates == null) {
            return [];
        }
        return helpers.translateFieldArray(
            state.enumerates.professional_categories,
            'name'
        );
    },
    getTeacherKnowledgeAreas: (state: MasterState): TeacherKnowledgeArea[] => {
        if (state.enumerates == null) {
            return [];
        }
        return helpers.translateFieldArray(
            state.enumerates.teacher_knowledge_areas,
            'name'
        );
    },
    getConstants: (state: MasterState): object =>
        state.enumerates ? state.enumerates.constants : {},
    getAcademicYearsDuration: (state: MasterState): object =>
        state.enumerates ? state.enumerates.academic_years_duration : {},
    getExpenseSSCategories: (state: MasterState): object =>
        state.enumerates ? state.enumerates.expense_ss_categories : {},
    getSubjects: (state: MasterState): ISubject[] => {
        return state.data.subjects.sort((a, b) => {
            // @ts-ignore
            return a.order - b.order;
        });
    },
    getSubject(state: MasterState) {
        return (subjectId: number) => {
            return state.data.subjects.find(
                (subject: ISubject) => Number(subject.id) === Number(subjectId)
            );
        };
    },
    getFees: (state: MasterState): IFee[] => state.data.fees,
    getTeacherSubject(state: MasterState) {
        return (teacherId: number, subjectId: number) => {
            const subject = state.data.subjects.find(
                (subject: ISubject) => Number(subject.id) === Number(subjectId)
            );
            const teacher = subject!.teachers.find(
                (teacher: ITeacher) => Number(teacher.id) === Number(teacherId)
            );

            if (teacher != null) {
                return teacher.TeacherSubject;
            }

            return null;
        };
    },
    getSummaryGuide(state: MasterState) {
        if (!state.data.subjects) {
            return [];
        }

        /* eslint-disable-next-line */
        let mandatory = {
            title: i18n.t('Asignaturas obligatorias'),
            ects: 0,
            hours: 0,
            years: {}
        };
        /* eslint-disable-next-line */
        let optional = {
            title: i18n.t('Asignaturas optativas'),
            ects: 0,
            hours: 0,
            years: {}
        };

        /* eslint-disable-next-line */
        let practical = {
            title: i18n.t('Prácticas Externas'),
            ects: 0,
            hours: 0,
            years: {}
        };

        /* eslint-disable-next-line */
        let tfm = {
            title: i18n.t('Trabajo Final de Máster (TFM)'),
            ects: 0,
            hours: 0,
            years: {}
        };

        /* eslint-disable-next-line */
        let total = {
            title: i18n.t('Total'),
            ects: 0,
            hours: 0,
            years: {}
        };

        // array from 1 to academic years
        const academic_years = Array.from(
            Array(state.data.academic_years_duration).keys()
        ).map(a => a + 1);

        // @ts-ignore
        academic_years.map(d => {
            // @ts-ignore
            mandatory.years[d] = { ects: 0, hours: 0 };
            // @ts-ignore
            optional.years[d] = { ects: 0, hours: 0 };
            // @ts-ignore
            practical.years[d] = { ects: 0, hours: 0 };
            // @ts-ignore
            tfm.years[d] = { ects: 0, hours: 0 };
            // @ts-ignore
            total.years[d] = { ects: 0, hours: 0 };
        });

        let year;
        let yearOptional; //year of one of an optional subject

        state.data.subjects.forEach((subject: ISubject) => {
            year =
                subject.academic_year_offset != null
                    ? subject.academic_year_offset.toString()
                    : 1;

            if (subject.is_optional) {
                // do not compute ECTS by each optional subject, only hours
                optional.ects =
                    state.data.optional_subjects_ects *
                    state.data.min_optional_subjects;
                // @ts-ignore
                optional.years[year].ects = optional.ects;

                // @ts-ignore
                optional.hours += subject.hours_assigned;
                // @ts-ignore
                optional.years[year].hours += subject.hours_assigned;
                yearOptional = year;
            } else {
                if (subject.is_practical) {
                    practical.ects += subject.ects;
                    practical.hours += subject.hours_assigned;
                    // @ts-ignore
                    practical.years[year].ects += subject.ects;
                    // @ts-ignore
                    practical.years[year].hours += subject.hours_assigned;
                } else {
                    if (subject.is_tfm) {
                        tfm.ects += subject.ects;
                        tfm.hours += subject.hours_assigned;
                        // @ts-ignore
                        tfm.years[year].ects += subject.ects;
                        // @ts-ignore
                        tfm.years[year].hours += subject.hours_assigned;
                    } else {
                        mandatory.ects += subject.ects;
                        mandatory.hours += subject.hours_assigned;
                        // @ts-ignore
                        mandatory.years[year].ects += subject.ects;
                        // @ts-ignore
                        mandatory.years[year].hours += subject.hours_assigned;
                    }
                }

                // @ts-ignore
                total.years[year]['ects'] += subject.ects;
            }

            // @ts-ignore
            total.years[year]['hours'] += subject.hours_assigned;
        });

        // accumulate fixed totals from optional subjects by year
        // @ts-ignore
        if (yearOptional != null) {
            // @ts-ignore
            total.years[yearOptional]['ects'] += Number(optional.ects);
        }

        total.ects = practical.ects + tfm.ects + mandatory.ects + optional.ects;
        total.hours =
            practical.hours + tfm.hours + mandatory.hours + optional.hours;

        let ret = [mandatory, optional, practical, tfm, total];

        if (state.data.master_kind_id === MASTER_KIND.CONTINUOUS_LEARNING) {
            ret = [mandatory, practical, total];
        }

        return ret;
    },
    getTeachersReview(state: MasterState): object[] {
        /* eslint-disable-next-line */
        let teachers: any = {};

        if (!state.data.subjects) {
            return [];
        }
        state.data.subjects.forEach((subject: ISubject) => {
            subject.teachers.forEach((teacher: ITeacher) => {
                if (teachers.hasOwnProperty(teacher.id)) {
                    teachers[teacher.id].theoretical_teaching_hours +=
                        teacher.TeacherSubject.theoretical_teaching_hours || 0;
                    teachers[teacher.id].tutorial_teaching_hours +=
                        teacher.TeacherSubject.tutorial_teaching_hours || 0;
                    teachers[teacher.id].practical_teaching_hours +=
                        teacher.TeacherSubject.practical_teaching_hours || 0;
                    teachers[teacher.id].total_price +=
                        teacher.TeacherSubject.theoretical_teaching_hours *
                            teacher.TeacherSubject.theoretical_teaching_price +
                        teacher.TeacherSubject.practical_teaching_hours *
                            teacher.TeacherSubject.practical_teaching_price +
                        teacher.TeacherSubject.tutorial_teaching_hours *
                            teacher.TeacherSubject.tutorial_teaching_price;
                } else {
                    teachers[teacher.id] = {
                        name: teacher.name + ' ' + teacher.surname,
                        teacher_type_code: teacher.teacher_type_code,
                        source_id: teacher.source_id
                    };
                    teachers[teacher.id].theoretical_teaching_hours =
                        teacher.TeacherSubject.theoretical_teaching_hours || 0;
                    teachers[teacher.id].tutorial_teaching_hours =
                        teacher.TeacherSubject.tutorial_teaching_hours || 0;
                    teachers[teacher.id].practical_teaching_hours =
                        teacher.TeacherSubject.practical_teaching_hours || 0;
                    teachers[teacher.id].total_price =
                        teacher.TeacherSubject.theoretical_teaching_hours *
                            teacher.TeacherSubject.theoretical_teaching_price +
                        teacher.TeacherSubject.practical_teaching_hours *
                            teacher.TeacherSubject.practical_teaching_price +
                        teacher.TeacherSubject.tutorial_teaching_hours *
                            teacher.TeacherSubject.tutorial_teaching_price;
                }
            });
        });

        return helpers.objectToArrayOrderedByProperties(teachers, ['name']);
    },
    getNewTeacher: (state: MasterState): ITeacher => state.newTeacher,
    getError: (state: MasterState): string => state.error,
    getFirstAvailableTeachingDate: (state: MasterState): string => {
        const startAcademicYear = new Date(state.data.academic_year.start_date);

        const addMonths =
            new Date(state.data.academic_year.start_date).getMonth() + 4;

        startAcademicYear.setMonth(addMonths);

        return startAcademicYear.toISOString();
    },
    getDirectors: (state: MasterState): ITeacher[] => {
        return state.data.directors.sort((a, b) => {
            // @ts-ignore
            return (
                a.MasterTeacherDirector.order - b.MasterTeacherDirector.order
            );
        });
    },
    hasPCategoriesRestrictions: (state: MasterState): boolean => {
        return state.data.hasPCategoriesRestrictions;
    },
    directorsAreTeachers(state: MasterState): boolean {
        if (state.data.master_kind_id == MASTER_KIND.CONTINUOUS_LEARNING) {
            return true;
        }

        let directorIsTeacher = false;

        for (const director of state.data.directors) {
            for (const subject of state.data.subjects) {
                if (
                    subject.teachers.some(teacher => teacher.id === director.id)
                ) {
                    directorIsTeacher = true;
                    break;
                }
            }

            if (!directorIsTeacher) {
                return false;
            }

            directorIsTeacher = false;
        }

        return true;
    }
};
