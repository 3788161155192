
import Vue from 'vue';
import SelectTeacher from '@/components/common/SelectTeacher.vue';
import {ITeacher} from '../../interfaces/teacher/interfaces/models/ITeacher';
import * as api from '@/api';
import {mapActions, mapGetters} from 'vuex';
import TeacherEdit from './StudyGuide/TeacherEdit.vue';

export default Vue.extend({
    name: 'DirectorMaster',
    components: {
        SelectTeacher,
        TeacherEdit
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        directorType: {
            type: String,
            default: 'uv'
        },
        directors: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        ...mapGetters({
            formError: 'master/getError',
            newTeacher: 'master/getNewTeacher'
        }),
        getLabel() {
            return this.directorType === 'uv'
                ? this.$i18n.t(
                      'Escribe nombre, apellidos o NIF para elegir un Director UV'
                  )
                : this.$i18n.t(
                      'Escribe nombre, apellidos o NIF para elegir un Director/Directora Externo/a'
                  );
        },
        getDirectorString() {
            return this.directorType === 'uv'
                ? this.$i18n.t('Director/Directora UV')
                : this.$i18n.t('Director/Directora Externo/a');
        },
        getApiFunction() {
            return this.directorType === 'uv'
                ? api.teacher.getTeachersUV
                : api.teacher.getTeachersExternal;
        },
    },
    data() {
        return {
            // @ts-ignore
            pendingChanges: false,
            api: api,
            director: null,
            step: 1
        };
    },
    methods: {
        ...mapActions('master', {
            clearError: 'clearError'
        }),
        exit() {
            this.$emit('exit');
        },
        save() {
            this.$emit('saved', this.director, this.directorType);
        },
        setModified() {
            this.pendingChanges = true;
        },
        disableItemsFunction(item: ITeacher) {
          return item.email == null;
        }
    },
    watch: {
        value(newValue, oldValue) {
            if (newValue) {
                this.director = null;
            }
        },
        newTeacher(newValue, oldValue) {
            if (newValue != null) {
                this.director = newValue;
                this.setModified();
            }
        }
    }
});
