
import 'devextreme/dist/css/dx.light.css';
import {
  DxColumn,
  DxColumnChooser,
  DxColumnFixing,
  DxDataGrid,
  DxExport,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxPaging,
  DxScrolling,
  DxSearchPanel,
  DxSorting,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import DxButton from 'devextreme-vue/button';
import {createStore} from 'devextreme-aspnet-data-nojquery';
import Vue from 'vue';

const teachersData = createStore({
    key: 'id',
    loadUrl: `${process.env.VUE_APP_API_URL}admin/teachers`,
});

export default Vue.extend({
    name: 'Teachers',
    components: {
        DxDataGrid,
        DxExport,
        DxColumn,
        DxColumnChooser,
        DxColumnFixing,
        DxSorting,
        DxFilterRow,
        DxHeaderFilter,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxPaging,
        DxButton,
        DxStateStoring,
    },
    data() {
        return {
            teachersData,
            pageSizes: [10, 25, 50, 100],
            pageSize: 100,
            pageIndex: 0,
            filterStatusValues: ['Activo'],
        };
    },
    computed: {
        grid(): DxDataGrid {
            return this.$refs['grid-teachers'];
        },
    },
    methods: {
        getMinStudents(rowData) {
          if (rowData.fee == null) {
            return '';
          }
          return rowData.fee?.map(f => f.min_students).join(', ');
        },
        getTeacherFullName(rowData) {
            if (rowData.teacher == null) {
                return '';
            }
            return `${rowData?.teacher.name || ''} ${
                rowData?.teacher.surname || ''
            }`;
        },
        getKnowledgeFieldName(rowData) {
          if (rowData.teacher == null) {
            return '';
          }

          return rowData?.master_knowledge_field[0].knowledge_field.name;
        },
        translate(cellInfo) {
            if (cellInfo.value == null) {
                return '';
            }
            return this.$i18n.t(cellInfo.value);
        },
        refreshData() {
            teachersData.load();
        },
    },
});
