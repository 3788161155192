/*
 *  Copyright 2017-2018 Enrique Araque Vilches
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
import { GetterTree } from 'vuex';
import { RootState } from '../../types';
import { ITenant, TenantState } from './types';

export const getters: GetterTree<TenantState, RootState> = {
    getTenants: (state: TenantState): ITenant[] | undefined =>
        state.data.tenants,
    getCurrentTenant: (state: TenantState): ITenant | undefined =>
        state.data.current_tenant,
    sigue_connect_disabled: (state: TenantState): boolean | undefined =>
        state.data.current_tenant?.features_disabled?.includes("sigue_connect"),
    platform_configure_disabled: (state: TenantState): boolean | undefined =>
        state.data.current_tenant?.features_disabled?.includes("platform_configure"),
    zip_files_generate_disabled: (state: TenantState): boolean | undefined =>
        state.data.current_tenant?.features_disabled?.includes("zip_files_generate"),
    getError: (state: TenantState): string => state.error,
};
