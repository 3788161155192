
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
// @ts-ignore
import * as _ from 'lodash';
import * as api from '@/api/index';
import FileField from '@/components/common/FileField.vue';
import {form} from '@/components/mixins';
import {MASTER_KIND, openPdfFromStream} from '../../helpers';

export default Vue.extend({
    name: 'Attachments',
    components: {
        FileField
    },
    mixins: [form],
    props: {
        editStep: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            errors: {
                res: [],
                curr: [],
                oth: [],
                additional_comments: []
            },
            rules: {
                file_responsible_org: [
                    value =>
                        !value ||
                        value.size < 30000000 ||
                        this.$i18n.t('El fichero no puede exceder de 30MB!')
                ],
                file_model_83: [
                    value =>
                        !value ||
                        value.size < 30000000 ||
                        this.$i18n.t('El fichero no puede exceder de 30MB!')
                ],
                additional_comments: []
            },
            masterFileTypes: {
                res: 'file_responsible_org',
                cur: 'file_curriculum_ext_teachers',
                oth: 'file_other_docs',
                m83: 'file_model_83'
            },
            loadingFile: false,
            signeds: {
                file_model_83: false
            }
        };
    },
    computed: {
        ...mapGetters({
            formError: 'master/getError',
            globalState: 'master/getData',
            knowledgeFields: 'master/getKnowledgeFields'
        }),
        isContinuousLearning() {
            return (
                this.globalState.master_kind_id ===
                MASTER_KIND.CONTINUOUS_LEARNING
            );
        },
        showTextField() {
            return this.localState.file_responsible_org != null;
        }
    },
    methods: {
        ...mapActions('master', [
            'update',
            'clearError',
            'uploadFile',
            'deleteFile'
        ]),
        parseLocalStateAfter(): object {
            return this.localState;
        },
        async sendFile(
            masterFileType: string,
            file: File,
            checkSigned: boolean = false
        ) {
            if (checkSigned) {
                this.signeds[masterFileType] = false;
                await this.fileIsSigned(file, masterFileType);
            }

            if (this.$refs[masterFileType].validate()) {
                this.loadingFile = true;

                this.uploadFile({
                    masterId: this.localState.id,
                    masterFileType,
                    file
                })
                    .then(() => {
                        this.loadingFile = false;
                        this.parseGlobalState();
                    })
                    .catch(e => {
                        this.loadingFile = false;
                    });
            }
        },
        dropFile(masterFileType: string) {
            this.loadingFile = true;

            this.deleteFile({
                masterId: this.localState.id,
                masterFileType
            }).then(() => {
                this.loadingFile = false;
                this.parseGlobalState();
            });
        },
        getUrlFile(masterFileType: string) {
            const fileBaseUrl: string = `masters/${this.globalState.id}/file/`;

            return `${process.env.VUE_APP_API_URL}${fileBaseUrl}${masterFileType}`;
        },
        async generateModel83() {
            const file = await api.master.generateModel83(this.globalState.id);
            openPdfFromStream(file.data);
        },
        async fileIsSigned(file, fileType) {
            let signeds = this.signeds;

            return new Promise((resolve, reject) => {
                /*let reader = new FileReader();

                reader.onload = function() {
                    try {
                        const { verified } = verifyPDF(reader.result);
                        signeds[fileType] = verified;

                        resolve(verified);
                    } catch (e) {
                        signeds[fileType] = false;
                        reject;
                    }
                };

                reader.readAsArrayBuffer(file);
                */
                signeds[fileType] = true;
                resolve(true);

            });
        }
    }
});
