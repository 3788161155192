/*
 *  Copyright 2017-2018 Enrique Araque Vilches
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */

// @ts-ignore
import api from '@/api/index';
// @ts-ignore
import * as mutation_types from '@/vuex/mutation_types';
import {ActionTree} from 'vuex';
import {RootState} from '../../types';
import {AppConfig, AppState} from './types';
// @ts-ignore
import {authentication} from '@/services';

export const actions: ActionTree<AppState, RootState> = {
    getConfig: async function({ commit }): Promise<any> {
        try {
            // TODO: Read config from api;
            const configData: AppConfig = {
                SUBJECT_MIN_ECTS: 3,
                SUBJECT_TFM_MIN_ECTS: 6
            };

            commit(mutation_types.LOAD_CONFIG, configData);
        } catch (e) {
            commit(mutation_types.LOAD_ERROR, e);
            throw e;
        }
    }
};
