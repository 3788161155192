
import 'devextreme/dist/css/dx.light.css';
import {
  DxColumn,
  DxColumnChooser,
  DxColumnFixing,
  DxDataGrid,
  DxExport,
  DxFilterRow,
  DxGrouping,
  DxGroupPanel,
  DxHeaderFilter,
  DxPager,
  DxPaging,
  DxScrolling,
  DxSearchPanel,
  DxSorting,
  DxStateStoring,
} from 'devextreme-vue/data-grid';
import DxButton from 'devextreme-vue/button';
import {createStore} from 'devextreme-aspnet-data-nojquery';
import Vue from 'vue';

const teachersInactiveData = createStore({
    key: 'id',
    loadUrl: `${process.env.VUE_APP_API_URL}admin/teachers-inactives`,
});

export default Vue.extend({
    name: 'TeachersInactive',
    components: {
        DxDataGrid,
        DxExport,
        DxColumn,
        DxColumnChooser,
        DxColumnFixing,
        DxSorting,
        DxFilterRow,
        DxHeaderFilter,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxPaging,
        DxButton,
        DxStateStoring,
        DxGroupPanel,
        DxGrouping,
    },
    data() {
        return {
            teachersInactiveData,
            pageSizes: [10, 25, 50, 100],
            pageSize: 100,
            pageIndex: 0,
            filterStatusValues: [],
            autoExpandAll: true,
        };
    },
    computed: {
        grid(): DxDataGrid {
            return this.$refs['grid-teachers-inactive'];
        },
    },
    methods: {
      getMasterTitleWithAcademicYear(rowData) {
        return `${rowData.subject.master_subject[0].master.title} - ${rowData.subject.master_subject[0].master.academic_year.name}`
      },
        goToMaster(e) {
            if (e.rowType === 'data') {
                const masterId = e.data.subject.master_subject[0].master.id;
                const routeData = this.$router.resolve({
                    name: 'Master',
                    params: { masterId: `${masterId}` },
                });
                window.open(routeData.href, '_blank');
            }
        },
        getYearFromOffset(rowData) {
            return `
            ${
                Number(rowData.subject.academic_year.name.split('/')[0]) +
                rowData.subject.academic_year_offset -
                1
            } /
            ${
                Number(rowData.subject.academic_year.name.split('/')[1]) +
                rowData.subject.academic_year_offset -
                1
            }
            `;
        },
        translate(cellInfo) {
            if (cellInfo.value == null) {
                return '';
            }
            return this.$i18n.t(cellInfo.value);
        },
        refreshData() {
            teachersInactiveData.load();
        },
    },
});
