
import Vue from 'vue';
import {ITeacher} from '../../interfaces/teacher/interfaces/models/ITeacher';
// @ts-ignore
import TeacherListItem from './TeacherListItem.vue';

export default Vue.extend({
    name: 'SelectTeacherPreLoaded',
    components: {
        TeacherListItem
    },
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: true
        },
        value: [Array, Object],
        // dont add a close icon to chip if element match any of the array elements.
        preventDeleteIds: {
            type: Array,
            default: () => []
        },
        // disable items with certain criteria
        disableItems: {
          type: Function,
          default: null
        },
        loadItemsFunction: {
          type: Function,
          default: null
        }
    },
    data() {
        return {
            valid: false,
            errors: {
                item: []
            },
            rules: {
                item: [
                    (v: ITeacher) => {
                        if (this.required) {
                            if (
                                (Array.isArray(v) && v.length == 0) ||
                                (!Array.isArray(v) && v == null)
                            ) {
                                return this.$i18n.t('El dato es requerido');
                            }

                            if (v.active == false) {
                              return this.$i18n.t('El profesor o profesora está inactivo o inactiva');
                            }
                            return true;
                        } else {
                            return true;
                        }
                    },
                ]
            },
            isLoading: false,
            search: null,
            hintText: this.$i18n.t(
                'Elige un profesor o una profesora de la lista'
            ),
            loadedItems: []
        };
    },
    computed: {
        items() {
            let items = this.loadedItems;

            if (this.value != null) {
                if (this.multiple) {
                    if (this.value.length > 0) {
                        items = items.concat(this.loadedItems).concat(this.value);
                    }
                } else {
                    items.push(this.value);
                }
            }

            return items;
        }
    },
    methods: {
        remove(item: ITeacher) {
            const index = this.value.findIndex(
                (el: object) => el.id === item.id
            );
            /*eslint-disable-next-line*/
            if (index >= 0) this.value.splice(index, 1);

            this.$emit('input', this.items);
        },
        isPreventDeleted(id: number): boolean {
            return this.preventDeleteIds.length === 0
                ? false
                : this.preventDeleteIds.includes(id);
        },
        loadData() {
          const setError = (err: any) => {
            console.log(err)
            this.isLoading = false;
            this.errors.item.push(
                this.$i18n.t(
                    'Se ha producido un error consultando el servidor'
                )
            );
          };

          // Items have already been requested
          if (this.isLoading) return;

          this.isLoading = true;

          try {
            // Lazily load input items
            this.loadItemsFunction()
                .then((res: any) => {
                  if (this.disableItems) {
                    res.data.map(a => a.disabled = this.disableItems(a));
                  }

                  this.loadedItems = res.data;
                  this.isLoading = false;
                })
                .catch((err: any) => {
                  setError(err);
                })
                .finally(() => (this.isLoading = false));
          } catch (err) {
            setError(err);
          }
        }
    },
    watch: {
      async value(newValue, oldValue) {
        if (newValue) {
          this.loadData();
        }
      }
    }
});
