
import Vue from 'vue';
import Master from '@/components/master/Master.vue';
import Component from 'vue-class-component';

@Component({
    components: {
        Master
    }
})
export default class MasterView extends Vue {}
