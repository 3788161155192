import {Module} from 'vuex';
import {RootState} from '../../types';
import {actions} from './actions';
import {getters} from './getters';
import {mutations} from './mutations';
import {AppConfig, AppState} from './types';

export const state: AppState = {
    data: {
        config: {} as AppConfig
    },
    error: undefined
};

const namespaced: boolean = true;

export const app: Module<AppState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
};
