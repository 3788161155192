import {Module} from 'vuex';
import {RootState} from '../../types';
import {actions} from './actions';
import {getters} from './getters';
import {mutations} from './mutations';
import {MasterState} from './types';
import {IMaster} from '../../../interfaces/master/interfaces/models/IMaster';
import {ITeacher} from '../../../interfaces/teacher/interfaces/models/ITeacher';

export const state: MasterState = {
    data: {} as IMaster,
    enumerates: {
        academic_years: [],
        proposal_organization_types: [],
        centers: [],
        research_institutes: [],
        departments: [],
        teaching_types: [],
        knowledge_branches: [],
        knowledge_fields: [],
        langs: [],
        constants: [],
        master_types: [],
        master_states: []
    },
    newTeacher: {} as ITeacher,
    error: undefined
};

const namespaced: boolean = true;

export const master: Module<MasterState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
};
