import {AppUser, Role} from '../../vuex/modules/authentication/types';
import {isEmpty, ROLES} from '../../helpers';
import {IMaster} from '../../interfaces/master/interfaces/models/IMaster';

export const authentication = {
    computed: {
        // this property is not reactive
        localStorageLoggedIn(): boolean {
            return localStorage.getItem('app_user') != null;
        },
        loggedIn(): boolean {
            return this.currentUser != null;
        },
        localStorageUser(): string | null {
            return localStorage.getItem('app_user');
        },
        currentMaster(): IMaster {
            // @ts-ignore
            return this.$store.getters['master/getData'];
        },
        currentUser(): AppUser {
            // @ts-ignore
            return this.$store.getters['authentication/getAppUser'];
        },
        academicYearsPerms(): boolean {
            // @ts-ignore
            return this.$store.getters['config/getAcademicYearsPerms'];
        },
        createMasterAllowed(): boolean {
            // If Create Own Degrees for current User at least in one academic year
            // then return true, thus user could create an own degree in it.
            // @ts-ignore
            if (this.academicYearsPerms.length > 0) {
                return this.academicYearsPerms?.some(perm => perm.od_create_allowed)
            } else {
                return false;
            }
        },
        createMasterContinuousLearningAllowed(): boolean {
            // If Create Continuous Learning Degrees for current User at least in one academic year
            // then return true, thus user could create an own degree in it.
            // @ts-ignore
            if (this.academicYearsPerms.length > 0) {
                return this.academicYearsPerms?.some(perm => perm.cl_create_allowed)
            } else {
            return false;
            }
        },
        imRoleTeacher(): boolean {
            // @ts-ignore
            return this.currentUser?.roles.some(
                (role: Role) => role.name === ROLES.teacher
            );
        },
        imRoleAdmin(): boolean {
            // @ts-ignore
            return this.currentUser?.roles.some(
                (role: Role) => role.name === ROLES.admin
            );
        },
        imRoleTechnic(): boolean {
            // @ts-ignore
            return this.currentUser?.roles.some(
                (role: Role) => role.name === ROLES.technic
            );
        },
        isCurrentMasterDisabled(): boolean {
            // @ts-ignore
            return (
                this.imRoleTeacher &&
                !isEmpty(this.currentMaster) &&
                !this.currentMaster?.isEditableByDirector
            );
        }
    },
    methods: {
        logout() {
            // @ts-ignore
            this.$store.dispatch('authentication/logout');
        }
    }
};
